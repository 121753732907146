import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import {
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useCookies } from "react-cookie";
import axiosInstance from "axiosInstance";
import AccountDialog from "components/AccountDialog";
import SearchDropdown from "components/inputfields/SearchDropdown";
import { MdDelete } from "react-icons/md";
import TabButton from "./TabButton";
import websitelogo from "../../../assets/icons/websitelogo.svg";
import { verifyToken } from "components/Functions/Functions";
import BaseButton from "components/ReusableComponents/BaseButton";
import moment from "moment";

const eventTypes = [
  { title: "Reset password", detail: "" },
  {
    title: "Invitation",
    detail: "On tenant, vendor and staff-member creation",
  },
  {
    title: "Property assign",
    detail: "Assign staff-member to property",
  },
  { title: "Lease creation", detail: "" },
  { title: "Lease end Reminder", detail: "" },
  { title: "Work-order creation", detail: "" },
  { title: "Work-order updation", detail: "" },
  { title: "Payment receipt", detail: "" },
  { title: "Payment refund", detail: "" },
  { title: "LateFee reminder", detail: "" },
  { title: "Applicant application", detail: "Application detail" },
  { title: "Express Payment", detail: "" },
];

const Settings = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();

  const [cookies, setCoockie, removeCookie] = useCookies();
  const getFile = process.env.REACT_APP_IMAGE_GET_URL;
  const postFile = process.env.REACT_APP_IMAGE_POST_URL;

  const [accessType, setAccessType] = useState(null);
  let [loader, setLoader] = React.useState(false);

  useEffect(() => {
    const handleCheckToken = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      if (data) {
        setAccessType(data);
      }
    };

    handleCheckToken();
  }, []);

  const handleSelectCategory = (e, itemIndex) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
  };

  const [newLogo, setNewLogo] = useState("");
  useEffect(() => {
    const updateColors = () => {
      if (accessType && accessType?.themes) {
        setNewLogo(accessType?.themes?.logo);
      }
    };
    updateColors();
  }, [accessType]);

  const [selectedOption, setSelectedOption] = useState("surcharge");
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  //Selected option Surcharge
  const [surcharge, setsurcharge] = useState(null);
  const [surcharge_id, setSurchargeId] = useState(null);
  const [achOption, setAchOption] = useState(null);

  const surchargeFormik = useFormik({
    initialValues: {
      surcharge_percent: "",
      surcharge_percent_debit: "",
      surcharge_percent_ACH: "",
    },
    validationSchema: yup.object({
      surcharge_percent: yup.number().required("Required"),
      surcharge_account: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/surcharge/surcharge/getadmin/${Admin_Id}`)
      .then((response) => {
        const Data = response.data?.data[0];
        setsurcharge(Data);
        setSurchargeId(Data.surcharge_id);
        setSelectedSurchargAccount(Data.surcharge_account);
        surchargeFormik.setValues({
          surcharge_percent: Data.surcharge_percent || "",
          surcharge_percent_debit: Data.surcharge_percent_debit || "",
          surcharge_percent_ACH: Data.surcharge_percent_ACH || "",
          surcharge_flat_ACH: Data.surcharge_flat_ACH || "",
          surcharge_account: Data.surcharge_account,
        });
        if (Data.surcharge_percent_ACH && Data.surcharge_flat_ACH) {
          setAchOption(3);
        } else if (Data.surcharge_percent_ACH) {
          setAchOption(1);
        } else if (Data.surcharge_flat_ACH) {
          setAchOption(2);
        }
      })
      .catch((error) => {
        console.error("Error fetching property type data:", error);
      });
  }, [accessType, selectedOption]);

   const handleToggle = async ( currentStatus, itemIndex) => {
    try {

      setEventsData((prevData) => {
        const updatedEventsData = prevData.map((item, index) =>
          index === itemIndex ? { ...item, is_enabled: currentStatus } : item
        );
  
        return updatedEventsData;
      });
   
    } catch (error) {
      console.error('Error updating mail preference:', error);
    }
  };

  async function handleSubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        admin_id: accessType?.admin_id,
        surcharge_percent: surchargeFormik.values.surcharge_percent,
        surcharge_percent_debit: surchargeFormik.values.surcharge_percent_debit,
        surcharge_percent_ACH:
          surchargeFormik.values.surcharge_percent_ACH || "",
        surcharge_flat_ACH: surchargeFormik.values.surcharge_flat_ACH || "",
        surcharge_account: surchargeFormik.values.surcharge_account,
      };

      if (!surcharge_id) {
        const res = await axiosInstance.post(`/surcharge/surcharge`, object);
        if (res.data.statusCode === 200) {
          toast.success("Surcharge Added", {
            position: "top-center",
            autoClose: 800,
            // onClose: () => navigate(`/${admin}/surcharge`),
          });
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/surcharge/surcharge/${surcharge_id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Surcharge Updated", {
            position: "top-center",
            autoClose: 800,
            // onClose: () => navigate(`/${admin}/surcharge`),
          });
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  //Selected option Latefee Charge
  const [lateFee, setLateFee] = useState(null);
  const [latefee_id, setLateFeeId] = useState(null);

  const latefeeFormik = useFormik({
    initialValues: {
      duration: "",
      late_fee: "",
    },
    validationSchema: yup.object({
      duration: yup.number().required("Required"),
      late_fee: yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      handleLateFeeSubmit(values);
    },
  });

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/latefee/latefee/${Admin_Id}`)
      .then((response) => {
        const Data = response.data.data;
        setLateFee(Data);
        setLateFeeId(Data?.latefee_id);
        latefeeFormik.setValues({
          duration: Data.duration || "",
          late_fee: Data.late_fee || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching late fee data:", error);
      });
  }, [accessType, selectedOption]);

  async function handleLateFeeSubmit(values) {
    setSubmitLoader(true);
    try {
      const object = {
        admin_id: accessType?.admin_id,
        duration: latefeeFormik.values.duration,
        late_fee: latefeeFormik.values.late_fee,
      };

      if (!latefee_id) {
        const res = await axiosInstance.post(`/latefee/latefee`, object);
        if (res.data.statusCode === 200) {
          toast.success("Late Fee Added", {
            position: "top-center",
            autoClose: 800,
            // onClose: () => navigate(`/${admin}/surcharge`),
          });
        } else if (res.data.statusCode === 201) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        const editUrl = `/latefee/latefee/${latefee_id}`;
        const res = await axiosInstance.put(editUrl, object);
        if (res.data.statusCode === 200) {
          toast.success("Late Fee Updated", {
            position: "top-center",
            autoClose: 800,
            // onClose: () => navigate(`/${admin}/surcharge`),
          });
        } else if (res.data.statusCode === 400) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  //Selected option Mail
  const [mailData, setMailData] = useState(null);
  const [isReminderEmail, setIsReminderEmail] = useState(false);
  const [replyToEmail, setreplyToEmail] = useState(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let Admin_Id = accessType?.admin_id;
    axiosInstance
      .get(`/mail_permission/${Admin_Id}`)
      .then((response) => {
        const Data = response.data.data;
        setMailData(Data);
        setreplyToEmail(Data?.reply_to);
        setIsReminderEmail(Data.remindermail);
        setDuration(Data.duration);
      })
      .catch((error) => {
        console.error("Error fetching property type data:", error);
      });
  }, [accessType, selectedOption]);

  const handleSwitchChange = (event) => {
    setIsReminderEmail(event.target.checked);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleSubmitmailpermission = async (event) => {
    event.preventDefault();
    setLoader(true);
    try {
      const values = {
        admin_id: accessType?.admin_id,
        remindermail: isReminderEmail,
        duration: isReminderEmail ? duration : 0,
        replyToEmail: replyToEmail,
      };

      let res;
      if (mailData && mailData.admin_id) {
        res = await axiosInstance.put(
          `/mail_permission/${accessType?.admin_id}`,
          values
        );
      } else {
        res = await axiosInstance.post(`/mail_permission`, values);
      }

      if (res.data.statusCode === 200) {
        toast.success("Email permission updated", {
          position: "top-center",
          autoClose: 1000,
        });
        setMailData(res.data.permissions);
      } else {
        toast.error(res.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message, {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleReset = () => {
    setIsReminderEmail(false);
    setDuration(0);
  };
  const CustomSwitch = withStyles({
    switchBase: {
      color: "#ffffff",
      "&$checked": {
        color: "#152B51",
      },
      "&$checked + $track": {
        backgroundColor: "#152B51",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageItem, setPageItem] = useState(5);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [accountsData, setAccountsData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedSurchargAccount, setSelectedSurchargAccount] = useState("");

  const getaccounts = async () => {
    let Admin_id = accessType?.admin_id;
    axiosInstance
      .get(`/accounts/accounts/${Admin_id}`)
      .then((response) => {
        if (response.data.data) {
          setAccountsData(response.data?.data);
          setTotalPages(Math.ceil(response.data.data.length / pageItem));
        }
      })
      .catch((error) => {
        console.error("Error fetching accounts type data:", error);
      });
  };

  useEffect(() => {
    getaccounts();
  }, [pageItem, accessType, selectedOption]);

  //onchange funtions
  const handleSurchargAccountSelect = async (account) => {
    try {
      if (!account) {
        setSelectedSurchargAccount("");
        surchargeFormik.setFieldValue("surcharge_account", "");
        return;
      }
      setSelectedSurchargAccount(account.account);
      surchargeFormik.setFieldValue("surcharge_account", account.account);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  const paginatedData = accountsData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [openRecurringDialog, setOpenRecurringDialog] = useState(false);
  const [selectChargeDropDown, setSelectChargetDropDown] = useState(false);

  const toggles1 = () => setSelectChargetDropDown(!selectChargeDropDown);

  const AddNewAccountName = async (accountName) => {
    setSelectedAccount(null);
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };

  const handleClose = () => {
    setOpenRecurringDialog(false);
  };

  const handleClickOpenRecurring = () => {
    setOpenRecurringDialog(true);
  };

  const openEditDialog = (account) => {
    setSelectedAccount(account.account_id);
    setAddBankAccountDialogOpen(true);
  };

  const [accountTypeName, setAccountTypeName] = useState("");

  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);

  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };

  const deleteaccount = async (account) => {
    // swal({
    //   title: "Are You Sure?",
    //   text: "",
    //   icon: "warning",
    //   buttons: ["Cancel", "Delete"],
    //   dangerMode: true,
    // }).then(async (willDelete) => {
    //   if (willDelete) {
    //     const res = await axiosInstance.delete(
    //       `/accounts/accounts/${account.account_id}`
    //     );
    //     if (res) {
    //       toast.success("Account deleted successfully", {
    //         position: "top-center",
    //         autoClose: 1000,
    //       });
    //       getaccounts();
    //     } else {
    //       toast.error("Failed to delete account", {
    //         position: "top-center",
    //         autoClose: 1000,
    //       });
    //     }
    //   }
    // });
    swal({
      title: "Are you sure?",
      text: "Please provide a reason for account deletion:",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason-input", // ID for easy access to the input field
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: false,
          visible: true,
          className: "swal-button--confirm",
        },
        cancel: {
          text: "Cancel",
          visible: true,
        },
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const inputValue = document.querySelector("#delete-reason-input").value;

        if (!inputValue.trim()) {
          swal("Error", "Reason for deletion is required!", "error");
          return;
        }

        try {
          const res = await axiosInstance.delete(
            `/accounts/accounts/${account.account_id}`,
            {
              data: { reason: inputValue }, // Send the input reason to the API
            }
          );

          if (res) {
            toast.success("Account deleted successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            swal.close(); // Close the SweetAlert after successful deletion
            getaccounts(); // Refresh the accounts list after deletion
          } else {
            toast.error("Failed to delete account", {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          toast.error(error.message, {
            position: "top-center",
          });
          console.error("Error:", error.message);
        }
      }
    });

    // Disable "Delete" button initially
    const confirmButton = document.querySelector(".swal-button--confirm");
    confirmButton.disabled = true;

    // Add event listener to the input field to enable/disable the button
    const inputField = document.querySelector("#delete-reason-input");
    inputField.oninput = () => {
      confirmButton.disabled = inputField.value.trim() === "";
    };
  };

  const [colors, setColors] = useState();
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");

  const defaultColors = {
    "--title-text-color": "#495160",
    "--text-blue-color": "#152b51",
    "--text-white-color": "#ffffff",
    "--bg-blue-color": "#152b51",
    "--bg-white-color": "#ffffff",
    "--table-text-color": "#525f7f",
    "--rent-roll-color": "#5a86d5",
    "--high-light-color": "#007bff",
  };

  const getCSSVariables = () => {
    const cssVariables = {};
    const computedStyles = getComputedStyle(document.documentElement);

    const variableNames = [
      "--title-text-color",
      "--text-blue-color",
      "--text-white-color",
      "--bg-blue-color",
      "--bg-white-color",
      "--table-text-color",
      "--rent-roll-color",
      "--high-light-color",
    ];

    variableNames.forEach((varName) => {
      cssVariables[varName] = computedStyles.getPropertyValue(varName).trim();
    });

    setColors(cssVariables);
  };

  useEffect(() => {
    getCSSVariables();
  }, [accessType]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const blobUrl = URL.createObjectURL(file);

      setImage(blobUrl);
    }
  };

  const handleColorChange = (event, cssVar) => {
    const newColor = event.target.value;

    setColors((prevColors) => ({
      ...prevColors,
      [cssVar]: newColor,
    }));

    document.documentElement.style.setProperty(cssVar, newColor);
  };

  const setTheme = async (colors) => {
    try {
      let logo = "";
      if (file) {
        const imageFile = new FormData();
        imageFile.append("files", file);
        const res = await axiosInstance.post(
          `${postFile}/images/upload`,
          imageFile
        );
        if (res && res.data && res.data.files && res.data.files.length > 0) {
          logo = res.data.files[0].filename;
        }
      }

      const themeRes = await axiosInstance.post(`/themes`, {
        colors,
        admin_id: accessType?.admin_id,
        logo: `${getFile}/${logo}`,
      });

      if (themeRes.data.statusCode === 200) {
        toast.success("Theme colors updated!", {
          position: "top-center",
          autoClose: 800,
        });
        setColors(themeRes.data.data.colors);
      } else {
        console.log("Something Wrong!");
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  const [selectedFormat, setSelectedFormat] = useState("MM/DD/YYYY");
  const [customInputValue, setCustomInputValue] = useState("DD/MMM/YYYY");

  useEffect(() => {
    if (accessType && accessType?.themes?.format) {
      const format = accessType.themes.format;
      if (format === "YYYY-MM-DD" || format === "MM/DD/YYYY" || format === "YYYY-MMM-DD") {
        setSelectedFormat(format);
      } else {
        setSelectedFormat("custom");
        setCustomInputValue(format);
      }
    }
  }, [accessType]);
  
  const handleFormatChange = (event) => {
    setSelectedFormat(event.target.value);
  };

  const handleCustomDateChange = (event) => {
    const inputValue = event.target.value;
    // Only allow alphabets, dash (-), and slash (/)
    const filteredValue = inputValue.replace(/[^a-zA-Z/-]/g, "");
    setCustomInputValue(filteredValue.toUpperCase());
  };

  const setDateFormat = async (format) => {
    try {
      const response = await axiosInstance.post(`/themes/date-format`, {
        format,
        admin_id: accessType?.admin_id,
      });

      if (response.data.statusCode === 200) {
        toast.success("Date format updated!", {
          position: "top-center",
          autoClose: 800,
        });
        // setColors(response.data.data.colors);
      } else {
        console.log("Something Wrong!");
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  const [eventsData, setEventsData] = useState([]);

  const fetchEventData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/templates/settings/${accessType?.admin_id}`
        );
        if (response.data.statusCode === 200) {
          setEventsData(response.data.templates);
        } else {
          setEventsData([]);
        }
      } catch (error) {
        console.error("Error: ", error);
        setEventsData([]);
      }
    }
  };

  useEffect(() => {
    fetchEventData();
  }, [accessType?.admin_id]);

  const handleSelectChange = (event, itemIndex) => {
    const selectedTemplateName = event.target.value;

    setEventsData((prevData) =>
      prevData.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            templates: item.templates.map((template) => ({
              ...template,
              is_active: template.name === selectedTemplateName,
            })),
            selectedTemplateId: item.templates.find(
              (template) => template.name === selectedTemplateName
            )?.template_id,
          };
        }
        return item;
      })
    );
  };

  const saveChanges = async () => {
    try {
      let enabledArray = {};
      for (const item of eventsData) {
        if (item.selectedTemplateId) {
          await axiosInstance.put(
            `/templates/settings/${item.selectedTemplateId}`,
            {
              is_active: true,
            }
          );
        }
        enabledArray[item.type] = item.is_enabled;
      }

      
      const response = await axiosInstance.put(`/mail_preferences`, {
        admin_id: accessType?.admin_id,
        enabledArray: enabledArray
      });

      if (response.status === 200) {
        toast.success("Changes saved!", {
          position: "top-center",
          autoClose: 1000,
        });
      }
      fetchEventData();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedEntry, setSelectedEntry] = useState("Select here");
  const [selectedVendor, setSelectedVendor] = useState("Select here");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [selecteduser, setSelecteduser] = useState("Select here");
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [staffData, setstaffData] = useState([]);
  const [allVendors, setAllVendors] = useState([]);

  const setVendorsName = () => {
    axiosInstance
      .get(`/vendor/vendors/${accessType?.admin_id}`)
      .then((res) => {
        setAllVendors(res.data?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchStaffData = () => {
    axiosInstance
      .get(`/staffmember/staff_member/${accessType?.admin_id}`)
      .then((response) => {
        const data = response.data;
        if (data?.statusCode === 200) {
          setstaffData(data?.data);
        } else {
          console.error("Error:", data?.message);
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const fetchWorkDefaults = async () => {
    try {
      const response = await axiosInstance.get(
        `/work-order/work-defaults/${accessType?.admin_id}`
      );
      if (response.data.statusCode === 200) {
        const data = response.data.data;
        setSelectedCategory(data.workDefaults.category);
        setSelectedEntry(data.workDefaults.entry_allowed ? "Yes" : "No");
        setSelecteduser(data.staffMember.staffmember_name);
        setSelectedStaffId(data.staffMember.staffmember_id);
        setSelectedVendor(data.vendor.vendor_name);
        setSelectedVendorId(data.vendor.vendor_id);
      } else {
        console.error("Error:", response.data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    setVendorsName();
    fetchStaffData();
    fetchWorkDefaults();
  }, [accessType?.admin_id]);

  const handleStaffSelect = async (staff) => {
    setSelecteduser(staff.staffmember_name);
    setSelectedStaffId(staff.staffmember_id);
  };

  const handleVenodrSelect = async (vendor) => {
    setSelectedVendor(vendor.vendor_name);
    setSelectedVendorId(vendor.vendor_id);
  };

  const saveWorkDefaults = async () => {
    try {
      const object = {
        admin_id: accessType?.admin_id,
        category: selectedCategory,
        entry_allowed: selectedEntry === "Yes" ? true : false,
        staffmember_id: selectedStaffId,
        vendor_id: selectedVendorId,
      };
      const response = await axiosInstance.post(
        `/work-order/work-defaults`,
        object
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 800,
        });
      } else {
        console.log("Something Wrong!");
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };

  return (
    <>
      <Container
        className="stylecontainer "
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Setting
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row className="mb-3">
          <Col>
            <Card
              style={{
                border: "1px solid #324567",
                boxShadow: "0px 4px 4px 0px #00000040",
                height: "100%",
              }}
            >
              <CardBody>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingpageecard"
                  >
                    <div style={{ border: "1px solid #ccc", height: "660px" }}>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        <TabButton
                          label="Surcharge"
                          name="surcharge"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Late Fee Charge"
                          name="latefee"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Mail Service"
                          name="mailservice"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Accounts"
                          name="payment-accounts"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Theme"
                          name="theme"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Date Format"
                          name="date"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Templates"
                          name="template"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                        <TabButton
                          label="Work Orders"
                          name="workOrder"
                          onClick={handleOptionClick}
                          selectedOption={selectedOption}
                        />
                      </ul>
                    </div>
                  </Col>

                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingdisplaynone"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        aria-label="Platform"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="surcharge"
                          onClick={() => handleOptionClick("surcharge")}
                          style={{
                            width: "50%",
                            backgroundColor:
                              selectedOption === "surcharge"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "surcharge"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px",

                            fontFamily: "Poppins",
                          }}
                        >
                          Surcharge
                        </ToggleButton>
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="latefee"
                          onClick={() => handleOptionClick("latefee")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "latefee"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "latefee" ? "#fff" : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Late Fee Charge
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingdisplaynone"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        aria-label="Platform"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="mailservice"
                          onClick={() => handleOptionClick("mailservice")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "mailservice"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "mailservice"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Mail Service
                        </ToggleButton>
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="payment-accounts"
                          onClick={() => handleOptionClick("payment-accounts")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "payment-accounts"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "payment-accounts"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Manage Accounts
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingdisplaynone"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        aria-label="Platform"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="theme"
                          onClick={() => handleOptionClick("theme")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "theme"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "theme" ? "#fff" : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Manage Theme
                        </ToggleButton>

                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="date"
                          onClick={() => handleOptionClick("date")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "date" ? "#152B51" : "inherit",
                            color:
                              selectedOption === "date" ? "#fff" : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Manage Date
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    className="settingdisplaynone"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ToggleButtonGroup
                        aria-label="Platform"
                        style={{ width: "100%", display: "flex" }}
                      >
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="template"
                          onClick={() => handleOptionClick("template")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "template"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "template"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Manage Template
                        </ToggleButton>
                        <ToggleButton
                          className="fontstylerentr fontoftogglegroupbtn"
                          value="template"
                          onClick={() => handleOptionClick("workOrder")}
                          style={{
                            width: "50%",

                            backgroundColor:
                              selectedOption === "workOrder"
                                ? "#152B51"
                                : "inherit",
                            color:
                              selectedOption === "workOrder"
                                ? "#fff"
                                : "#152B51",
                            cursor: "pointer",
                            fontWeight: "600",
                            padding: "20px ",
                            fontFamily: "Poppins",
                          }}
                        >
                          Manage WorkOrder
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Col>

                  {selectedOption === "payment-accounts" ? (
                    <Col lg="9">
                      <div>
                        <CardBody>
                          {selectedOption === "payment-accounts" && (
                            <div>
                              <Row>
                                <h1
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "22px",
                                  }}
                                >
                                  Manage Accounts
                                </h1>
                              </Row>
                              <Row>
                                <Col
                                  xs="12"
                                  lg="12"
                                  sm="12"
                                  className="text-right"
                                >
                                  <Button
                                    className="tenantnewbtn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                    onClick={handleClickOpenRecurring}
                                    size="small"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      boxShadow: "0px 4px 4px 0px #00000040",
                                      borderRadius: "4px",
                                      border: "0",
                                    }}
                                  >
                                    Add Account
                                  </Button>
                                </Col>
                              </Row>

                              <Dialog
                                open={openRecurringDialog}
                                onClose={handleClose}
                              >
                                <DialogTitle
                                  className="labelfontstyle textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Select Account Type
                                </DialogTitle>

                                <div
                                  className="pl-lg-3 modalpaddingrent"
                                  style={{
                                    height: "190px",
                                    width: "380px",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginLeft: "4%",
                                      marginRight: "4%",
                                    }}
                                  >
                                    <br />
                                    <div className="formInput">
                                      <label
                                        className="form-control-label titleecolor fontstylerentr fontfamilysty"
                                        htmlFor="input-address"
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Select Account Type
                                      </label>
                                      <br />
                                      <Dropdown
                                        className="dropdownfontsyle"
                                        isOpen={selectChargeDropDown}
                                        toggle={toggles1}
                                      >
                                        <DropdownToggle
                                          className="dropdownfontsyle titleecolor"
                                          caret
                                          style={{
                                            width: "100%",
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040",
                                            border: "1px solid #ced4da",
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          Select
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdownfontsyle"
                                          style={{ width: "100%" }}
                                          name="rent_cycle"
                                        >
                                          <DropdownItem
                                            className="dropdownfontsyle"
                                            onClick={() => {
                                              AddNewAccountName(
                                                "Liability Account"
                                              );
                                              handleClose();
                                            }}
                                          >
                                            Liability Account
                                          </DropdownItem>
                                          <DropdownItem
                                            className="dropdownfontsyle"
                                            onClick={() => {
                                              AddNewAccountName(
                                                "Recurring Charge"
                                              );
                                              handleClose();
                                            }}
                                          >
                                            Recurring Charge
                                          </DropdownItem>
                                          <DropdownItem
                                            className="dropdownfontsyle"
                                            onClick={() => {
                                              AddNewAccountName(
                                                "One Time Charge"
                                              );
                                              handleClose();
                                            }}
                                          >
                                            One Time Charge
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-end">
                                      <Button
                                        className="fontstylerentr mt-4 background-colorsty bgtextwhite fontfamilysty"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleClose}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Dialog>
                              <AccountDialog
                                addBankAccountDialogOpen={
                                  addBankAccountDialogOpen
                                }
                                setAddBankAccountDialogOpen={
                                  setAddBankAccountDialogOpen
                                }
                                accountTypeName={accountTypeName}
                                adminId={accessType?.admin_id}
                                fetchAccounts={getaccounts}
                              />

                              <Table
                                className="align-items-center table-flush mt-3"
                                responsive
                                style={{ borderCollapse: "collapse" }}
                              >
                                <thead
                                  className=""
                                  style={{
                                    height: "45px",
                                    fontSize: "14px",
                                    fontFamily: "poppins",
                                    fontWeight: "600",
                                    boxShadow: " 0px 4px 4px 0px #00000040",
                                  }}
                                >
                                  <tr
                                    style={{
                                      border: "2px solid rgba(50, 69, 103, 1)",
                                    }}
                                  >
                                    <th
                                      className="tablefontstyle textcolorblue"
                                      scope="col"
                                      style={{
                                        borderTopLeftRadius: "15px",
                                      }}
                                    >
                                      Account
                                    </th>

                                    <th
                                      className="tablefontstyle textcolorblue"
                                      scope="col"
                                      style={{
                                        borderTopLeftRadius: "15px",
                                      }}
                                    >
                                      Type
                                    </th>

                                    <th
                                      className="tablefontstyle textcolorblue"
                                      scope="col"
                                    >
                                      Charge Type
                                    </th>

                                    <th
                                      className="tablefontstyle textcolorblue"
                                      scope="col"
                                    >
                                      Fund Type
                                    </th>
                                    <th
                                      className="tablefontstyle textcolorblue"
                                      scope="col"
                                      style={{
                                        borderTopRightRadius: "15px",
                                      }}
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {paginatedData.length === 0 ? (
                                  <tbody>
                                    <tr className="text-center">
                                      <td
                                        colSpan="8"
                                        style={{ fontSize: "15px" }}
                                      >
                                        No Accounts Added
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr
                                      style={{
                                        border: "none",
                                      }}
                                    >
                                      <td colSpan="9"></td>
                                    </tr>
                                    {paginatedData.map((account) => (
                                      <tr
                                        key={account.account_id}
                                        style={{
                                          cursor: "pointer",
                                          border:
                                            "0.5px solid rgba(50, 69, 103, 1)",
                                          fontSize: "12px",
                                          height: "40px",
                                          fontFamily: "poppins",
                                          fontWeight: "600",
                                        }}
                                      >
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.account}
                                        </td>
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.account_type}
                                        </td>
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.charge_type}
                                        </td>
                                        <td className="bordertopintd tablebodyfont tabletextcolor">
                                          {account.fund_type}
                                        </td>

                                        <td className="bordertopintd">
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                            }}
                                          >
                                            <IconButton
                                              className="tabletextcolor"
                                              type="button"
                                              style={{
                                                padding: "7px",
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                deleteaccount(account);
                                              }}
                                            >
                                              <MdDelete />
                                            </IconButton>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                )}
                              </Table>
                              {paginatedData.length > 0 ? (
                                <Row>
                                  <Col className="text-right mt-3 mb-3">
                                    <Dropdown
                                      isOpen={leasedropdownOpen}
                                      toggle={toggle2}
                                    >
                                      <DropdownToggle
                                        className="paginationbtnstyle"
                                        caret
                                      >
                                        {pageItem}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => {
                                            setPageItem(5);
                                            setCurrentPage(1);
                                          }}
                                        >
                                          5
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            setPageItem(25);
                                            setCurrentPage(1);
                                          }}
                                        >
                                          25
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            setPageItem(50);
                                            setCurrentPage(1);
                                          }}
                                        >
                                          50
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            setPageItem(100);
                                            setCurrentPage(1);
                                          }}
                                        >
                                          100
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                    <Button
                                      className="p-0"
                                      style={{ backgroundColor: "#d0d0d0" }}
                                      onClick={() =>
                                        handlePageChange(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-caret-left"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                                      </svg>
                                    </Button>
                                    <span>
                                      Page {currentPage} of {totalPages}
                                    </span>{" "}
                                    <Button
                                      className="p-0"
                                      style={{ backgroundColor: "#d0d0d0" }}
                                      onClick={() =>
                                        handlePageChange(currentPage + 1)
                                      }
                                      disabled={currentPage === totalPages}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-caret-right"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                      </svg>
                                    </Button>
                                  </Col>
                                </Row>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </CardBody>
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div>
                        <CardBody>
                          {selectedOption === "surcharge" && (
                            <div>
                              <Row>
                                <h1
                                  className="labelfontstyle textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "22px",
                                  }}
                                >
                                  Surcharge
                                </h1>
                              </Row>
                              <Form onSubmit={surchargeFormik.handleSubmit}>
                                <div className="mt-3">
                                  <Row>
                                    <span
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                      }}
                                    >
                                      You can set default surcharge percentage
                                      from here
                                    </span>
                                  </Row>
                                  <Row className="mt-3">
                                    <Col lg="4">
                                      <FormGroup>
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Credit Card Surcharge Percent
                                        </label>
                                        <br />
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "70%",
                                          }}
                                        >
                                          <Input
                                            style={{
                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040 ",
                                              borderRadius: "6px",
                                            }}
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-protype"
                                            placeholder="Surcharge %"
                                            type="number"
                                            name="surcharge_percent"
                                            onBlur={surchargeFormik.handleBlur}
                                            onChange={
                                              surchargeFormik.handleChange
                                            }
                                            value={
                                              surchargeFormik.values
                                                .surcharge_percent
                                            }
                                            required
                                          />
                                          <span
                                            className="fontstylerentmodal"
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                            }}
                                          >
                                            %
                                          </span>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                      <FormGroup className="">
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Debit Card Surcharge Percent
                                        </label>
                                        <br />
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "70%",
                                          }}
                                        >
                                          <Input
                                            style={{
                                              boxShadow:
                                                " 0px 4px 4px 0px #00000040 ",
                                              borderRadius: "6px",
                                            }}
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-protype"
                                            placeholder="Surcharge %"
                                            type="number"
                                            name="surcharge_percent_debit"
                                            onBlur={surchargeFormik.handleBlur}
                                            onChange={
                                              surchargeFormik.handleChange
                                            }
                                            value={
                                              surchargeFormik.values
                                                .surcharge_percent_debit
                                            }
                                          />
                                          <span
                                            className="fontstylerentmodal"
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                            }}
                                          >
                                            %
                                          </span>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="mt-3">
                                    <span
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                      }}
                                    >
                                      You can set default ACH percentage or ACH
                                      flat fee or both from here
                                    </span>
                                  </Row>

                                  <Row className="mt-3">
                                    <Col lg="5">
                                      <FormGroup check>
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <Input
                                            type="radio"
                                            id="checked"
                                            name="achOption"
                                            checked={achOption === 1}
                                            onChange={() => setAchOption(1)}
                                          />{" "}
                                          Add ACH surcharge percentage
                                        </label>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="mt-1">
                                    <Col lg="5">
                                      <FormGroup check>
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <Input
                                            type="radio"
                                            id="checked"
                                            name="achOption"
                                            checked={achOption === 2}
                                            onChange={() => setAchOption(2)}
                                          />{" "}
                                          Add ACH flat fee
                                        </label>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row className="mt-1">
                                    <Col lg="6">
                                      <FormGroup check>
                                        <label
                                          className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <Input
                                            type="radio"
                                            id="checked"
                                            name="achOption"
                                            checked={achOption === 3}
                                            onChange={() => setAchOption(3)}
                                          />{" "}
                                          Add both ACH surcharge percentage and
                                          flat fee
                                        </label>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  {achOption === 1 && (
                                    <Row className="mt-3">
                                      <Col lg="4">
                                        <FormGroup>
                                          <label
                                            className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                            htmlFor="input-property"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            ACH Surcharge Percent
                                          </label>
                                          <br />
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "70%",
                                            }}
                                          >
                                            <Input
                                              style={{
                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                              className="form-control-alternative fontstylerentmodal"
                                              id="input-protype"
                                              placeholder="Surcharge %"
                                              type="number"
                                              name="surcharge_percent_ACH"
                                              onBlur={
                                                surchargeFormik.handleBlur
                                              }
                                              onChange={
                                                surchargeFormik.handleChange
                                              }
                                              value={
                                                surchargeFormik.values
                                                  .surcharge_percent_ACH
                                              }
                                              required
                                            />
                                            <span
                                              className="fontstylerentmodal"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                              }}
                                            >
                                              %
                                            </span>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  )}

                                  {achOption === 2 && (
                                    <Row className="mt-3">
                                      <Col lg="4">
                                        <FormGroup>
                                          <label
                                            className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                            htmlFor="input-property"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            ACH Flat Fee
                                          </label>
                                          <br />
                                          <Input
                                            style={{ width: "70%" }}
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-protype"
                                            placeholder="Flat Fee"
                                            type="number"
                                            name="surcharge_flat_ACH"
                                            onBlur={surchargeFormik.handleBlur}
                                            onChange={
                                              surchargeFormik.handleChange
                                            }
                                            value={
                                              surchargeFormik.values
                                                .surcharge_flat_ACH
                                            }
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  )}

                                  {achOption === 3 && (
                                    <>
                                      <Row className="mt-3">
                                        <Col lg="4">
                                          <FormGroup>
                                            <label
                                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                              htmlFor="input-property"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            >
                                              ACH Surcharge Percent
                                            </label>
                                            <br />
                                            <div
                                              style={{
                                                position: "relative",
                                                width: "70%",
                                              }}
                                            >
                                              <Input
                                                style={{
                                                  boxShadow:
                                                    " 0px 4px 4px 0px #00000040 ",
                                                  borderRadius: "6px",
                                                }}
                                                className="form-control-alternative fontstylerentmodal"
                                                id="input-protype"
                                                placeholder="Surcharge %"
                                                type="number"
                                                name="surcharge_percent_ACH"
                                                onBlur={
                                                  surchargeFormik.handleBlur
                                                }
                                                onChange={
                                                  surchargeFormik.handleChange
                                                }
                                                value={
                                                  surchargeFormik.values
                                                    .surcharge_percent_ACH
                                                }
                                                required
                                              />
                                              <span
                                                className="fontstylerentmodal"
                                                style={{
                                                  position: "absolute",
                                                  right: "10px",
                                                  top: "50%",
                                                  transform: "translateY(-50%)",
                                                }}
                                              >
                                                %
                                              </span>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                          <FormGroup className="mt-2">
                                            <label
                                              className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                              htmlFor="input-property"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            >
                                              ACH Flat Fee
                                            </label>
                                            <br />
                                            <Input
                                              style={{
                                                width: "70%",

                                                boxShadow:
                                                  " 0px 4px 4px 0px #00000040 ",
                                                borderRadius: "6px",
                                              }}
                                              className="form-control-alternative fontstylerentmodal"
                                              id="input-protype"
                                              placeholder="Flat Fee"
                                              type="number"
                                              name="surcharge_flat_ACH"
                                              onBlur={
                                                surchargeFormik.handleBlur
                                              }
                                              onChange={
                                                surchargeFormik.handleChange
                                              }
                                              value={
                                                surchargeFormik.values
                                                  .surcharge_flat_ACH
                                              }
                                              required
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </>
                                  )}

                                  <Row className="mt-3">
                                    <Col lg="4" md="4" sm="6" xl="4">
                                      <label
                                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        Account to receive surcharges
                                      </label>
                                      <FormGroup className="mt-2">
                                        <SearchDropdown
                                          options={accountsData}
                                          value={
                                            accountsData.find(
                                              (p) =>
                                                p.account ===
                                                selectedSurchargAccount
                                            ) || null
                                          }
                                          onChange={(event, value) =>
                                            handleSurchargAccountSelect(value)
                                          }
                                          placeholder="Select Account"
                                          getOptionLabel={(option) =>
                                            option.account || ""
                                          }
                                          filterOptions={(options, state) => {
                                            return options.filter((option) =>
                                              option.account
                                                .toLowerCase()
                                                .includes(
                                                  state.inputValue.toLowerCase()
                                                )
                                            );
                                          }}
                                        />
                                        {surchargeFormik.errors &&
                                        surchargeFormik.errors?.account_id &&
                                        surchargeFormik.touched &&
                                        surchargeFormik.touched?.account_id ? (
                                          <div
                                            className="requiredstylefont"
                                            style={{ color: "red" }}
                                          >
                                            {surchargeFormik.errors.account_id}
                                          </div>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>

                                <Row className="mt-3 my-row">
                                  {submitLoader ? (
                                    <Button
                                      type="submit"
                                      className="btn btn-primary fontstylerentr background-colorsty bgtextwhite"
                                      disabled
                                    >
                                      Loading...
                                    </Button>
                                  ) : (
                                    <Button
                                      type="submit"
                                      className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {surcharge_id ? "Update" : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                    onClick={surchargeFormik.resetForm}
                                    style={{
                                      border: "1px solid #152B51",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Row>
                              </Form>
                            </div>
                          )}
                          {selectedOption === "latefee" && (
                            <div>
                              <Row>
                                <h1
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "22px",
                                  }}
                                >
                                  Late Fee Charge
                                </h1>
                              </Row>
                              <Form onSubmit={latefeeFormik.handleSubmit}>
                                <div className="mt-3">
                                  <Row>
                                    <span
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                      }}
                                    >
                                      You can set default Late fee charge from
                                      here
                                    </span>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Percentage
                                        </label>
                                        <br />
                                        <Input
                                          style={{
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-protype"
                                          placeholder="Late Fee Charge"
                                          type="number"
                                          name="late_fee"
                                          onBlur={latefeeFormik.handleBlur}
                                          onChange={latefeeFormik.handleChange}
                                          value={latefeeFormik.values.late_fee}
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row className="mt-3">
                                    <Col lg="6">
                                      <FormGroup>
                                        <label
                                          className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                          htmlFor="input-property"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          Number Of Grace Period Days
                                        </label>
                                        <br />
                                        <Input
                                          style={{
                                            boxShadow:
                                              " 0px 4px 4px 0px #00000040 ",
                                            borderRadius: "6px",
                                          }}
                                          className="form-control-alternative fontstylerentmodal"
                                          id="input-protype"
                                          placeholder="Late Fee Duration"
                                          type="number"
                                          name="duration"
                                          onBlur={latefeeFormik.handleBlur}
                                          onChange={latefeeFormik.handleChange}
                                          value={latefeeFormik.values.duration}
                                          required
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <br />
                                </div>
                                <Row className="mt-3 my-row">
                                  {submitLoader ? (
                                    <Button
                                      type="submit"
                                      className="btn btn-primary fontstylerentr background-colorsty bgtextwhite"
                                      disabled
                                    >
                                      Loading...
                                    </Button>
                                  ) : (
                                    <Button
                                      type="submit"
                                      className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {latefee_id ? "Update" : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                    onClick={latefeeFormik.resetForm}
                                    style={{
                                      border: "1px solid #152B51",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Row>
                              </Form>
                            </div>
                          )}
                          {selectedOption === "mailservice" && (
                            <div>
                              <Row>
                                <h1
                                  className="fontstylerentr textcolorblue fontfamilysty"
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "22px",
                                  }}
                                >
                                  Mail Service
                                </h1>
                              </Row>
                              <Form onSubmit={handleSubmitmailpermission}>
                                <Row>
                                  <FormGroup>
                                    <FormGroup>
                                      <label
                                        className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                        htmlFor="input-property"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        Add Your Reply to Address
                                      </label>
                                      <br />
                                      <Input
                                        style={{
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040 ",
                                          borderRadius: "6px",
                                          marginBottom: "25px",
                                        }}
                                        className="form-control-alternative fontstylerentmodal"
                                        id="input-protype"
                                        placeholder="Enter your mail"
                                        type="email"
                                        name="email"
                                        onChange={(event) =>
                                          setreplyToEmail(event.target.value)
                                        }
                                        value={replyToEmail}
                                      />
                                    </FormGroup>
                                    <FormControlLabel
                                      control={
                                        <CustomSwitch
                                          checked={isReminderEmail}
                                          onChange={handleSwitchChange}
                                        />
                                      }
                                      label="Rent due reminder email"
                                    />
                                  </FormGroup>
                                </Row>

                                {isReminderEmail && (
                                  <>
                                    <Row>
                                      <span
                                        className="fontstylerentr titleecolor fontfamilysty"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        You can set a duration for send reminder
                                        email before rent due date to tenant
                                      </span>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                            className="form-control-label fontstylerentr textcolorblue fontfamilysty"
                                            htmlFor="input-property"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "16px",
                                            }}
                                          >
                                            Duration
                                          </label>
                                          <br />
                                          <Input
                                            style={{
                                              boxShadow:
                                                "0px 4px 4px 0px #00000040",
                                              borderRadius: "6px",
                                            }}
                                            className="form-control-alternative fontstylerentmodal"
                                            id="input-protype"
                                            placeholder="reminder mail Duration"
                                            type="text"
                                            name="duration"
                                            value={duration}
                                            onChange={(e) => {
                                              if (
                                                /^\d*\.?\d*$/.test(
                                                  e.target.value
                                                )
                                              ) {
                                                handleDurationChange(e);
                                              }
                                            }}
                                            required
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                <Row className="mt-3 my-row">
                                  {submitLoader ? (
                                    <Button
                                      type="submit"
                                      className="btn btn-primary fontstylerentr background-colorsty bgtextwhite"
                                      disabled
                                    >
                                      Loading...
                                    </Button>
                                  ) : (
                                    <Button
                                      type="submit"
                                      className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {mailData ? "Update" : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                                    onClick={handleReset}
                                    style={{
                                      border: "1px solid #152B51",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </Row>
                              </Form>
                            </div>
                          )}
                          {/* {selectedOption === "payment-accounts" && (
                          <div>
                            <Row>
                              <h1
                                className="fontstylerentr textcolorblue fontfamilysty"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "22px",
                                }}
                              >
                                Manage Accounts
                              </h1>
                            </Row>
                            <Row>
                              <Col
                                xs="12"
                                lg="12"
                                sm="12"
                                className="text-right"
                              >
                                <Button
                                  className="tenantnewbtn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                  onClick={handleClickOpenRecurring}
                                  size="small"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "4px",
                                    border: "0",
                                  }}
                                >
                                  Add Account
                                </Button>
                              </Col>
                            </Row>

                            <Dialog
                              open={openRecurringDialog}
                              onClose={handleClose}
                            >
                              <DialogTitle
                                className="labelfontstyle textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                              >
                                Select Account Type
                              </DialogTitle>

                              <div
                                className="pl-lg-3 modalpaddingrent"
                                style={{
                                  height: "190px",
                                  width: "380px",
                                }}
                              >
                                <div
                                  style={{
                                    marginLeft: "4%",
                                    marginRight: "4%",
                                  }}
                                >
                                  <br />
                                  <div className="formInput">
                                    <label
                                      className="form-control-label titleecolor fontstylerentr fontfamilysty"
                                      htmlFor="input-address"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Select Account Type
                                    </label>
                                    <br />
                                    <Dropdown
                                      className="dropdownfontsyle"
                                      isOpen={selectChargeDropDown}
                                      toggle={toggles1}
                                    >
                                      <DropdownToggle
                                        className="dropdownfontsyle titleecolor"
                                        caret
                                        style={{
                                          width: "100%",
                                          boxShadow:
                                            " 0px 4px 4px 0px #00000040",
                                          border: "1px solid #ced4da",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        Select
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdownfontsyle"
                                        style={{ width: "100%" }}
                                        name="rent_cycle"
                                      >
                                        <DropdownItem
                                          className="dropdownfontsyle"
                                          onClick={() => {
                                            AddNewAccountName(
                                              "Liability Account"
                                            );
                                            handleClose();
                                          }}
                                        >
                                          Liability Account
                                        </DropdownItem>
                                        <DropdownItem
                                          className="dropdownfontsyle"
                                          onClick={() => {
                                            AddNewAccountName(
                                              "Recurring Charge"
                                            );
                                            handleClose();
                                          }}
                                        >
                                          Recurring Charge
                                        </DropdownItem>
                                        <DropdownItem
                                          className="dropdownfontsyle"
                                          onClick={() => {
                                            AddNewAccountName(
                                              "One Time Charge"
                                            );
                                            handleClose();
                                          }}
                                        >
                                          One Time Charge
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                  <div className="d-flex justify-content-end align-items-end">
                                    <Button
                                      className="fontstylerentr mt-4 background-colorsty bgtextwhite fontfamilysty"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Dialog>
                            <AccountDialog
                              addBankAccountDialogOpen={
                                addBankAccountDialogOpen
                              }
                              setAddBankAccountDialogOpen={
                                setAddBankAccountDialogOpen
                              }
                              accountTypeName={accountTypeName}
                              adminId={accessType?.admin_id}
                              fetchAccounts={getaccounts}
                            />

                            <Table
                              className="align-items-center table-flush mt-3"
                              responsive
                              style={{ borderCollapse: "collapse" }}
                            >
                              <thead
                                className=""
                                style={{
                                  height: "45px",
                                  fontSize: "14px",
                                  fontFamily: "poppins",
                                  fontWeight: "600",
                                  boxShadow: " 0px 4px 4px 0px #00000040",
                                }}
                              >
                                <tr
                                  style={{
                                    border: "2px solid rgba(50, 69, 103, 1)",
                                  }}
                                >
                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                    style={{
                                      borderTopLeftRadius: "15px",
                                    }}
                                  >
                                    Account
                                  </th>

                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                    style={{
                                      borderTopLeftRadius: "15px",
                                    }}
                                  >
                                    Type
                                  </th>

                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                  >
                                    Charge Type
                                  </th>

                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                  >
                                    Fund Type
                                  </th>
                                  <th
                                    className="tablefontstyle textcolorblue"
                                    scope="col"
                                    style={{
                                      borderTopRightRadius: "15px",
                                    }}
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              {paginatedData.length === 0 ? (
                                <tbody>
                                  <tr className="text-center">
                                    <td
                                      colSpan="8"
                                      style={{ fontSize: "15px" }}
                                    >
                                      No Accounts Added
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr
                                    style={{
                                      border: "none",
                                    }}
                                  >
                                    <td colSpan="9"></td>
                                  </tr>
                                  {paginatedData.map((account) => (
                                    <tr
                                      key={account.account_id}
                                      style={{
                                        cursor: "pointer",
                                        border:
                                          "0.5px solid rgba(50, 69, 103, 1)",
                                        fontSize: "12px",
                                        height: "40px",
                                        fontFamily: "poppins",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <td className="bordertopintd tablebodyfont tabletextcolor">
                                        {account.account}
                                      </td>
                                      <td className="bordertopintd tablebodyfont tabletextcolor">
                                        {account.account_type}
                                      </td>
                                      <td className="bordertopintd tablebodyfont tabletextcolor">
                                        {account.charge_type}
                                      </td>
                                      <td className="bordertopintd tablebodyfont tabletextcolor">
                                        {account.fund_type}
                                      </td>

                                      <td className="bordertopintd">
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "5px",
                                          }}
                                        >
                                          <IconButton
                                            className="tabletextcolor"
                                            type="button"
                                            style={{
                                              padding: "7px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              deleteaccount(account);
                                            }}
                                          >
                                            <MdDelete />
                                          </IconButton>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </Table>
                            {paginatedData.length > 0 ? (
                              <Row>
                                <Col className="text-right mt-3 mb-3">
                                  <Dropdown
                                    isOpen={leasedropdownOpen}
                                    toggle={toggle2}
                                  >
                                    <DropdownToggle
                                      className="paginationbtnstyle"
                                      caret
                                    >
                                      {pageItem}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(5);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        5
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(25);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        25
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(50);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(100);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                  <Button
                                    className="p-0"
                                    style={{ backgroundColor: "#d0d0d0" }}
                                    onClick={() =>
                                      handlePageChange(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-caret-left"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                                    </svg>
                                  </Button>
                                  <span>
                                    Page {currentPage} of {totalPages}
                                  </span>{" "}
                                  <Button
                                    className="p-0"
                                    style={{ backgroundColor: "#d0d0d0" }}
                                    onClick={() =>
                                      handlePageChange(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-caret-right"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                    </svg>
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <></>
                            )}
                          </div>
                        )} */}
                          {selectedOption === "theme" && (
                            <div>
                              <Row>
                                <h1
                                  className="fontstylerentr"
                                  style={{
                                    color: "#152B51",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    fontSize: "22px",
                                  }}
                                >
                                  Manage Theme
                                </h1>
                              </Row>
                              <div className="d-md-flex d-block">
                                <div className="settings-menu w-100">
                                  <h2>Customize Colors</h2>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Title Text Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--title-text-color"]}
                                      onChange={(e) =>
                                        handleColorChange(
                                          e,
                                          "--title-text-color"
                                        )
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Text Blue Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--text-blue-color"]}
                                      onChange={(e) =>
                                        handleColorChange(
                                          e,
                                          "--text-blue-color"
                                        )
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Text White Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--text-white-color"]}
                                      onChange={(e) =>
                                        handleColorChange(
                                          e,
                                          "--text-white-color"
                                        )
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Background Blue Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--bg-blue-color"]}
                                      onChange={(e) =>
                                        handleColorChange(e, "--bg-blue-color")
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Background White Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--bg-white-color"]}
                                      onChange={(e) =>
                                        handleColorChange(e, "--bg-white-color")
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Table Text Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--table-text-color"]}
                                      onChange={(e) =>
                                        handleColorChange(
                                          e,
                                          "--table-text-color"
                                        )
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Rent Roll Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--rent-roll-color"]}
                                      onChange={(e) =>
                                        handleColorChange(
                                          e,
                                          "--rent-roll-color"
                                        )
                                      }
                                    />
                                  </div>

                                  <div>
                                    <label style={{ width: "300px" }}>
                                      Highlight Color
                                    </label>
                                    <input
                                      type="color"
                                      value={colors["--high-light-color"]}
                                      onChange={(e) =>
                                        handleColorChange(
                                          e,
                                          "--high-light-color"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                {/* <div className="w-100">
                                <h2>Customize Company Logo</h2>
                                <div className="d-md-flex d-block">
                                  <img
                                    src={image || websitelogo}
                                    width="200px"
                                    alt="logo"
                                  />
                                  <Button
                                    type="button"
                                    className="fontstylerentr fontfamilysty textcolorblue backgroundwhitesty"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      margin: "0 15px",
                                      height: "40px",
                                    }}
                                    onClick={() => {
                                      document
                                        ?.getElementById("customize-logo")
                                        ?.click();
                                    }}
                                  >
                                    Upload
                                  </Button>

                                  <TextField
                                    id="customize-logo"
                                    name="customize-logo"
                                    type="file"
                                    inputProps={{
                                      accept: "image/*",
                                      multiple: false,
                                    }}
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                              </div> */}
                              </div>
                              <div className="mt-3">
                                <div style={{ display: "flex", gap: "10px" }}>
                                  <BaseButton
                                    id="payButton"
                                    type="submit"
                                    disabled={false}
                                    label="Save"
                                    onClick={() => setTheme(colors)}
                                  />
                                  <BaseButton
                                    onClick={() => {
                                      setColors(defaultColors);
                                      for (const key in defaultColors) {
                                        if (
                                          Object.prototype.hasOwnProperty.call(
                                            defaultColors,
                                            key
                                          )
                                        ) {
                                          const element = defaultColors[key];
                                          document.documentElement.style.setProperty(
                                            key,
                                            element
                                          );
                                        }
                                      }
                                      setTheme(defaultColors);
                                    }}
                                    variant="outline"
                                    label="Reset"
                                    type="button"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedOption === "date" && (
                            <div>
                              <h1 className="d-flex justify-content-start textcolorblue">
                                Select Date Format
                              </h1>
                              <div className="settings-menu">
                                <div>
                                  <label>
                                    <Input
                                      type="radio"
                                      id="checked"
                                      name="dateFormat"
                                      value="MM/DD/YYYY"
                                      checked={selectedFormat === "MM/DD/YYYY"}
                                      onChange={handleFormatChange}
                                    />
                                    MM/DD/YYYY
                                  </label>
                                  <Input
                                    className="mb-3"
                                    type="text"
                                    style={{ width: "200px" }}
                                    value={moment(new Date()).format(
                                      "MM/DD/YYYY"
                                    )}
                                    disabled={selectedFormat !== "MM/DD/YYYY"}
                                    readOnly
                                  />
                                </div>

                                <div>
                                  <label>
                                    <Input
                                      type="radio"
                                      id="checked"
                                      name="dateFormat"
                                      value="YYYY-MM-DD"
                                      checked={selectedFormat === "YYYY-MM-DD"}
                                      onChange={handleFormatChange}
                                    />
                                    YYYY-MM-DD
                                  </label>
                                  <Input
                                    className="mb-3"
                                    type="text"
                                    style={{ width: "200px" }}
                                    value={moment(new Date()).format(
                                      "YYYY-MM-DD"
                                    )}
                                    disabled={selectedFormat !== "YYYY-MM-DD"}
                                    readOnly
                                  />
                                </div>

                                <div>
                                  <label>
                                    <Input
                                      type="radio"
                                      id="checked"
                                      name="dateFormat"
                                      value="YYYY-MMM-DD"
                                      checked={selectedFormat === "YYYY-MMM-DD"}
                                      onChange={handleFormatChange}
                                    />
                                    YYYY-MMM-DD
                                  </label>
                                  <Input
                                    className="mb-3"
                                    type="text"
                                    style={{ width: "200px" }}
                                    value={moment(new Date()).format(
                                      "YYYY-MMM-DD"
                                    )}
                                    disabled={selectedFormat !== "YYYY-MMM-DD"}
                                    readOnly
                                  />
                                </div>

                                <div>
                                  <label>
                                    <Input
                                      type="radio"
                                      id="checked"
                                      name="dateFormat"
                                      value="custom"
                                      checked={selectedFormat === "custom"}
                                      onChange={handleFormatChange}
                                    />
                                    Custom (DD/MMM/YYYY)
                                  </label>
                                  <Input
                                    type="text"
                                    style={{ width: "200px" }}
                                    value={customInputValue}
                                    disabled={selectedFormat !== "custom"}
                                    onChange={handleCustomDateChange}
                                  />
                                </div>

                                {selectedFormat === "custom" &&
                                customInputValue?.length > 0 ? (
                                  <div style={{ marginTop: "20px" }}>
                                    <h3>Formatted Date:</h3>
                                    <p>
                                      <>
                                        {moment(new Date()).format(
                                          customInputValue
                                        )}{" "}
                                      </>
                                    </p>
                                  </div>
                                ) : (
                                  " "
                                )}

                                <div
                                  style={{ display: "flex", gap: "10px" }}
                                  className="mt-3"
                                >
                                  <BaseButton
                                    id="payButton"
                                    type="submit"
                                    disabled={false}
                                    label="Save"
                                    onClick={() =>
                                      setDateFormat(
                                        selectedFormat === "custom"
                                          ? customInputValue
                                          : selectedFormat
                                      )
                                    }
                                  />
                                  <BaseButton
                                    onClick={() => {
                                      setDateFormat("YYYY-MM-DD");
                                    }}
                                    variant="outline"
                                    label="Reset"
                                    type="button"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {selectedOption === "template" && (
                            <>
                              <h1 className="d-flex justify-content-start textcolorblue">
                                Manage Templates
                              </h1>
                              <div className="settings-menu mt-3">
                                {eventsData?.length > 0 &&
                                  eventsData.map((item, itemIndex) => (
                                    <FormGroup
                                      className="d-flex align-items-center mt-1"
                                      key={itemIndex}
                                    >
                                      <FormControlLabel
                                        key={itemIndex}
                                        control={
                                          <CustomSwitch
                                            key={itemIndex}
                                            checked={item.is_enabled ? item.is_enabled : false} // Set checked state from preference
                                            onChange={(e) => handleToggle(e.target.checked, itemIndex)} // Pass preferenceIndex
                                          />
                                        }
                                      />
                                      <label
                                        className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                        htmlFor="email-type"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "16px",
                                          width: "300px",
                                        }}
                                      >
                                        {item?.type}
                                      </label>

                                      <Select
                                        labelId="user-select-label"
                                        id="user-select"
                                        value={
                                          item?.templates.find(
                                            (elem) => elem.is_active
                                          )?.name || ""
                                        }
                                        onChange={(e) =>
                                          handleSelectChange(e, itemIndex)
                                        }
                                        displayEmpty
                                        renderValue={(selected) =>
                                          selected || "Select Template"
                                        }
                                        disabled={!item.is_enabled}
                                      >
                                        {item?.templates?.length > 0 ? (
                                          item.templates.map((elem, index) => (
                                            <MenuItem
                                              key={index}
                                              value={elem.name}
                                            >
                                              <ListItemText
                                                primary={elem?.name || ""}
                                              />
                                            </MenuItem>
                                          ))
                                        ) : (
                                          <MenuItem value="">
                                            <ListItemText primary="Template not found" />
                                          </MenuItem>
                                        )}
                                      </Select>
                                    </FormGroup>
                                  ))}
                                <div>
                                  <BaseButton
                                    onClick={saveChanges}
                                    label="Save"
                                  >
                                    Save
                                  </BaseButton>
                                </div>
                              </div>
                            </>
                          )}
                          {selectedOption === "workOrder" && (
                            <>
                              <h1 className="d-flex justify-content-start textcolorblue">
                                Manage WorkOrder
                              </h1>
                              {/* category */}
                              <Row className="mb-3">
                                <Col>
                                  <FormGroup className="mb-2 mb-md-0 fontstylerentr">
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="category-select"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        width: "300px",
                                      }}
                                    >
                                      Category
                                    </label>
                                    <br />
                                    <Select
                                      labelId="category-select-label"
                                      id="category-select"
                                      value={selectedCategory || ""}
                                      onChange={(e) => handleSelectCategory(e)}
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected || "Select a Category"
                                      }
                                      style={{
                                        width: "250px",
                                        boxShadow: "0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {[
                                        "Complaint",
                                        "Contribution Request",
                                        "Feedback/Suggestion",
                                        "General Inquiry",
                                        "Maintenance Request",
                                        "Other",
                                      ].map((category, index) => (
                                        <MenuItem key={index} value={category}>
                                          <ListItemText primary={category} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* vendor */}
                              <Row className="mb-3">
                                <Col>
                                  <FormGroup className="mb-2 mb-md-0 fontstylerentr">
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="vendor-select"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Vendor
                                    </label>
                                    <br />
                                    <Select
                                      id="vendor-select"
                                      value={selectedVendor || ""}
                                      onChange={(e) =>
                                        handleVenodrSelect(e.target.value)
                                      }
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected || "Select a Vendor"
                                      }
                                      style={{
                                        width: "250px",
                                        boxShadow: "0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      {allVendors?.length > 0 ? (
                                        allVendors.map((vendor, index) => (
                                          <MenuItem key={index} value={vendor}>
                                            <ListItemText
                                              primary={vendor.vendor_name}
                                            />
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem value="">
                                          <ListItemText primary="No vendors available" />
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* entry allow */}
                              <Row className="mb-3">
                                <Col>
                                  <FormGroup className="mb-2 mb-md-0 fontstylerentr">
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="entry-allowed-select"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Entry Allowed
                                    </label>
                                    <br />
                                    <Select
                                      id="entry-allowed-select"
                                      value={selectedEntry || ""}
                                      onChange={(e) =>
                                        setSelectedEntry(e.target.value)
                                      }
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected || "Select"
                                      }
                                      style={{
                                        width: "250px",
                                        boxShadow: "0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                    >
                                      <MenuItem value="Yes">Yes</MenuItem>
                                      <MenuItem value="No">No</MenuItem>
                                    </Select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {/* assign to */}
                              <Row className="mb-3">
                                <Col>
                                  <FormGroup className="mb-2 mb-md-0 fontstylerentr">
                                    <label
                                      className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                      htmlFor="assigned-to-select"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Assigned To
                                    </label>
                                    <br />
                                    <Select
                                      id="assigned-to-select"
                                      value={selecteduser || ""}
                                      onChange={(e) =>
                                        handleStaffSelect(e.target.value)
                                      }
                                      displayEmpty
                                      renderValue={(selected) =>
                                        selected || "Select"
                                      }
                                      style={{
                                        width: "250px",
                                        boxShadow: "0px 4px 4px 0px #00000040",
                                        border: "1px solid #ced4da",
                                        backgroundColor: "transparent",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxHeight: "200px",
                                            overflowY: "auto",
                                          },
                                        },
                                      }}
                                    >
                                      {staffData.map((user) => (
                                        <MenuItem key={user._id} value={user}>
                                          {user.staffmember_name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div>
                                <BaseButton
                                  onClick={saveWorkDefaults}
                                  label="Save"
                                >
                                  Save
                                </BaseButton>
                              </div>
                            </>
                          )}
                        </CardBody>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Settings;
