import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { useCookies } from "react-cookie";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./tenantstyle.css";
import axiosInstance from "axiosInstance";
import { Checkbox } from "@mui/material";
import { verifyToken } from "components/Functions/Functions";
import { Info } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

const Leaseing = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const { tenant_id, admin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isFromDetail, setIsFromDetail] = useState(false);
  const [accessType, setAccessType] = useState(null);
  const [isEnable, setIsEnable] = useState(false);
  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      if (
        !admin &&
        !accessData?.permissions?.tenant_add &&
        !accessData?.permissions?.tenant_edit
      ) {
        navigate(admin ? `/${admin}/index` : "/staff/StaffdashBoard");
      }
    };

    handleCheckToken();
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      setIsFromDetail(true);
    }
  }, [location.state]);

  const passwordValidation = yup
    .string()
    .min(12, "Password must be at least 12 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .notOneOf(
      [/1234/, /abcd/, /qwerty/, /password/, /\d{4}/, /[a-z]{4}/],
      'Password cannot contain sequential patterns like "1234", "abcd", etc.'
    )
    .required("Password is required");

  //display
  const [showPassword, setShowPassword] = useState(false);
  //loaders
  const [loader, setLoader] = useState(false);
  const [override, setOverride] = useState(null);
  const tenantFormik = useFormik({
    initialValues: {
      tenant_id: "",
      tenant_firstName: "",
      tenant_lastName: "",
      tenant_phoneNumber: "",
      tenant_alternativeNumber: "",
      tenant_email: "",
      tenant_alternativeEmail: "",
      tenant_password: "",
      tenant_birthDate: "",
      taxPayer_id: "",
      comments: "",
      override_fee: "",
      enable_override_fee: "",
      emergency_contact: {
        name: "",
        relation: "",
        email: "",
        phoneNumber: "",
      },
    },
    validationSchema: yup.object({
      tenant_firstName: yup.string().required("Please enter first name"),
      tenant_lastName: yup.string().required("Please enter last name"),
      tenant_phoneNumber: yup
        .string()
        .required("Please enter phone number")
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        ),
      tenant_alternativeNumber: yup
        .string()
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          "Phone number must be in the format (xxx) xxx-xxxx"
        )
        .test(
          "not-same-as-phone",
          "Work number cannot be the same as the phone number",
          function (value) {
            const primaryNumber = this.parent.tenant_phoneNumber;
            if (!value && !primaryNumber) {
              return true;
            }
            return value !== primaryNumber;
          }
        ),
      tenant_email: yup
        .string()
        .required("Required")
        .email("Invalid email address")
        .required("Email is required"),
      tenant_alternativeEmail: yup.string().email("Invalid email address")
      .test(
        "not-same-as-email",
        "Alternative email cannot be the same as the primary email",
        function (value) {
          const primaryEmail = this.parent.tenant_email;
          if (!value && !primaryEmail) {
            return true;
          }
          return value !== primaryEmail;
        }
      ),
      tenant_password: passwordValidation,
      emergency_contact: yup.object().shape({
        name: yup.string(),
        relation: yup.string(),
        email: yup.string().email("Invalid email address")
        .test(
          "not-same-as-tenant-email",
          "Emergency email cannot be the same as the tenant's email",
          function (value) {
            const primaryEmail = this.options.context.tenant_email;
            if (!value && !primaryEmail) {
              return true;
            }
            return value !== primaryEmail;
          }
        ),
        phoneNumber: yup
          .string()
          .matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            "Phone number must be in the format (xxx) xxx-xxxx"
          )
          .test(
            "not-same-as-tenant-phone",
            "Emergency phone number cannot be the same as the tenant's phone number",
            function (value) {
              const primaryNumber = this.options.context.tenant_phoneNumber;
              if (!value && !primaryNumber) {
                return true;
              }
              return value !== primaryNumber;
            }
          )
          
      }),
    }),
    onSubmit: (values) => {
      if (tenant_id) {
        editTenant();
      } else {
        addTenant(values);
      }
    },
  });

  const addTenant = async () => {
    const object = {
      ...tenantFormik.values,
      enable_override_fee: isEnable,
      admin_id: accessType?.admin_id,
      company_name: admin,
    };

    try {
      setLoader(true);
      const res = await axiosInstance.post(`/tenant/tenants`, object);
      if (res.data.statusCode === 200) {
        toast.success("Tenant Added successfully!", {
          position: "top-center",
          autoClose: 900,
          onClose: () =>
            navigate(
              admin ? `/${admin}/TenantsTable` : "/staff/stafftenanttable"
            ),
        });
      } else {
        toast.error(res.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
      console.error("Error:", error.message);
    }
    setLoader(false);
  };

  const editTenant = async () => {
    const object = {
      ...tenantFormik.values,
      enable_override_fee: isEnable,
      admin_id: accessType?.admin_id,
      company_name: admin,
    };

    try {
      setLoader(true);
      const res = await axiosInstance.put(
        `/tenant/tenants/${tenant_id}`,
        object
      );
      if (res.data.statusCode === 200) {
        toast.success("Tenant Updated Successfully!", {
          position: "top-center",
          autoClose: 900,
          onClose: () =>
            isFromDetail
              ? navigate(
                  admin
                    ? `/${admin}/tenantdetail/${location.state.id}`
                    : `/staff/stafftenantdetail/${location.state.id}`
                )
              : navigate(
                  admin ? `/${admin}/TenantsTable` : "/staff/stafftenanttable"
                ),
        });
      } else {
        toast.warning(res.data.message, {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
      });
      console.error("Error:", error.message);
    }
    setLoader(false);
  };

  const fetchTenantData = async () => {
    if (tenant_id) {
      try {
        const res = await axiosInstance.get(`/tenant/get_tenant/${tenant_id}`);
        if (res.data.statusCode === 200) {
          tenantFormik.setValues(res.data.data);
          setIsEnable(res.data.data.enable_override_fee);
          setOverride(res.data.data.override_fee);
        } else if (res.data.statusCode === 201) {
          tenantFormik.resetForm();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  const handleCloseButtonClick = () => {
    if (isFromDetail) {
      navigate(
        admin
          ? `/${admin}/tenantdetail/${location.state.id}`
          : `/staff/stafftenantdetail/${location.state.id}`
      );
    } else {
      navigate(admin ? `/${admin}/TenantsTable` : "/staff/stafftenanttable");
    }
  };

  useEffect(() => {
    if (tenant_id) {
      fetchTenantData();
    }
  }, [tenant_id]);

  const handleChange = (e) => {
    setIsEnable(e.target.checked);
    if (e.target.checked) {
      tenantFormik.setFieldValue("override_fee", override);
    } else {
      tenantFormik.setFieldValue("override_fee", null);
    }
  };

  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  const generateRandomPassword = () => {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialChars = '!@#$%^&*(),.?":{}|<>';

    const allChars = uppercase + lowercase + numbers + specialChars;

    let password = "";
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Fill the rest of the password length with random characters
    for (let i = password.length; i < 12; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the password to ensure randomness
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    // Validate the generated password
    try {
      passwordValidation.validateSync(password);
      return password;
    } catch (error) {
      // If validation fails, try again recursively
      return generateRandomPassword();
    }
  };

  const handleShufflePassword = () => {
    const password = generateRandomPassword();
    tenantFormik.setFieldValue("tenant_password", password);
  };

  const preventEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3 headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {tenant_id ? "Edit Tenant" : "Add Tenant"}
            </h2>
          </CardHeader>
        </Col>
        <Form>
          <Row className="mt-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className=" mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                {/* 6002 changing the design of the page all inputs  */}

                <CardBody>
                  <box className="">
                    <Row>
                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_firstName"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            First Name *
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              // width: "80%",
                            }}
                            id="tenant_firstName"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter first name"
                            name="tenant_firstName"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                tenantFormik.setFieldValue(
                                  "tenant_firstName",
                                  e.target.value
                                );
                              }
                            }}
                            value={tenantFormik.values.tenant_firstName}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched.tenant_firstName &&
                          tenantFormik.errors.tenant_firstName ? (
                            <box
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_firstName}
                            </box>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_lastName"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Last Name *
                          </label>
                          <br />
                          <Input
                            id="tenant_lastName"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            autoComplete="off"
                            placeholder="Enter last name"
                            style={{
                              marginRight: "10px",
                              flex: 1,
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "80%",
                            }}
                            name="tenant_lastName"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                tenantFormik.setFieldValue(
                                  "tenant_lastName",
                                  e.target.value
                                );
                              }
                            }}
                            value={tenantFormik.values.tenant_lastName}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched.tenant_lastName &&
                          tenantFormik.errors.tenant_lastName ? (
                            <box
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_lastName}
                            </box>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_phoneNumber"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Phone Number*
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="tenant_phoneNumber"
                            type="text"
                            name="tenant_phoneNumber"
                            placeholder="Enter phone number"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (
                                tenantFormik.values.tenant_phoneNumber?.length >
                                e.target.value?.length
                              ) {
                                tenantFormik.setFieldValue(
                                  "tenant_phoneNumber",
                                  e.target.value
                                );
                              } else {
                                const formattedValue = formatPhoneNumber(
                                  e.target.value
                                );
                                tenantFormik.setFieldValue(
                                  "tenant_phoneNumber",
                                  formattedValue
                                );
                              }
                            }}
                            value={tenantFormik.values.tenant_phoneNumber}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched.tenant_phoneNumber &&
                          tenantFormik.errors.tenant_phoneNumber ? (
                            <box
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_phoneNumber}
                            </box>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_alternativeNumber"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Work Number
                          </label>
                          <br />
                          <Input
                            id="tenant_alternativeNumber"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter work number"
                            autoComplete="off"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              // width: "80%",
                            }}
                            name="tenant_alternativeNumber"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (
                                tenantFormik.values.tenant_alternativeNumber
                                  ?.length > e.target.value?.length
                              ) {
                                tenantFormik.setFieldValue(
                                  "tenant_alternativeNumber",
                                  e.target.value
                                );
                              } else {
                                const formattedValue = formatPhoneNumber(
                                  e.target.value
                                );
                                tenantFormik.setFieldValue(
                                  "tenant_alternativeNumber",
                                  formattedValue
                                );
                              }
                            }}
                            value={tenantFormik.values.tenant_alternativeNumber}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched.tenant_alternativeNumber &&
                          tenantFormik.errors.tenant_alternativeNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_alternativeNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </box>
                  <box className="">
                    <Row className="mt-2">
                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_email"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Email *
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                            }}
                            id="tenant_email"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter email"
                            name="tenant_email"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_email}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched.tenant_email &&
                          tenantFormik.errors.tenant_email ? (
                            <box
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_email}
                            </box>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="tenant_alternativeEmail"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Alternative Email
                          </label>
                          <br />
                          <Input
                            id="tenant_alternativeEmail"
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            variant="standard"
                            type="text"
                            placeholder="Enter alternative email"
                            autoComplete="off"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              marginRight: "10px",
                              flex: 1,
                              // width: "80%",
                            }}
                            name="tenant_alternativeEmail"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => tenantFormik.handleChange(e)}
                            value={tenantFormik.values.tenant_alternativeEmail}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched.tenant_alternativeEmail &&
                          tenantFormik.errors.tenant_alternativeEmail ? (
                            <box
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors.tenant_alternativeEmail}
                            </box>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {/* </Row>
                  </box>
                  <box className="">
                    <Row className="mt-2"> */}
                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <box
                            style={{
                              flex: 1,
                              // marginRight: "10px",
                            }}
                          >
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty m-0"
                              htmlFor="tenant_password"
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                Password *
                                <Tooltip
                                  title={
                                    <Box style={{ fontSize: "12px" }}>
                                      <ul
                                        style={{
                                          paddingLeft: "20px",
                                          margin: 0,
                                        }}
                                      >
                                        <li>
                                          At least one uppercase letter (A-Z).
                                        </li>
                                        <li>
                                          At least one lowercase letter (a-z).
                                        </li>
                                        <li>At least one number (0-9).</li>
                                        <li>
                                          At least one special character (e.g.,
                                          @, #, $, etc.).
                                        </li>
                                        <li>
                                          Password must be at least 12
                                          characters long.
                                        </li>
                                        <li>
                                          No continuous alphabetical characters
                                          (e.g., abcd) or continuous numerical
                                          characters (e.g., 1234).
                                        </li>
                                        <li>
                                          Avoid strictly sequential patterns
                                          (e.g., Ak12345678!).
                                        </li>
                                        <li>
                                          Don't use birthdays, names, addresses,
                                          or other personal information.
                                        </li>
                                      </ul>
                                    </Box>
                                  }
                                  placement="bottom"
                                  arrow
                                >
                                  <IconButton>
                                    <Info
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        color: "#152B51",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </span>
                              <i
                                className="fa-solid fa-arrows-rotate"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "20px",
                                }}
                                onClick={handleShufflePassword}
                                title="Shuffle Password"
                              />
                            </label>
                            <box
                              style={{
                                display: "flex",
                                // width: "80%",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <Input
                                id="tenant_password"
                                className="form-control-alternative inputpassword fontstylerentmodal titleecolor"
                                variant="standard"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter password"
                                autoComplete="new-password"
                                style={{
                                  // marginRight: "10px",
                                  flex: 1,
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                name="tenant_password"
                                onBlur={tenantFormik.handleBlur}
                                onChange={tenantFormik.handleChange}
                                value={tenantFormik.values.tenant_password}
                                onKeyDown={preventEnterKey}
                              />
                              <box
                                style={{
                                  position: "absolute",
                                  right: "15px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "10px",
                                }}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon
                                    style={{ color: "#152B51" }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{ color: "#152B51" }}
                                  />
                                )}
                              </box>
                            </box>
                            {tenantFormik.errors &&
                            tenantFormik.errors?.tenant_password &&
                            tenantFormik.touched &&
                            tenantFormik.touched?.tenant_password ? (
                              <box
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {tenantFormik.errors.tenant_password}
                              </box>
                            ) : null}
                          </box>
                        </FormGroup>
                      </Col>
                    </Row>
                  </box>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="mb-4 labelfontstyle fontfamilysty textcolorblue"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Personal Information
                  </h6>
                  <box className="">
                    <Row>
                      <Col lg="2">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd3"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Date of Birth
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "70%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd3"
                            placeholder="3000"
                            type="date"
                            name="tenant_birthDate"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.tenant_birthDate}
                            max={new Date().toISOString().split("T")[0]}
                            onKeyDown={preventEnterKey}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd4"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            TaxPayer ID
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd4"
                            type="text"
                            placeholder="Enter tax payer id"
                            name="taxPayer_id"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.taxPayer_id}
                            onKeyDown={preventEnterKey}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </box>
                  <box className="">
                    <Row className="mt-2">
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-address"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Comments
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              height: "90px",
                              width: "100%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-address"
                            type="textarea"
                            placeholder="Enter comments"
                            name="comments"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.comments}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </box>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="mb-4 labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Emergency Contact
                  </h6>
                  <box className="">
                    <Row>
                      <Col lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd5"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Contact Name
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd5"
                            type="text"
                            name="emergency_contact.name"
                            autoComplete="off"
                            placeholder="Enter contact name"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                tenantFormik.setFieldValue(
                                  "emergency_contact.name",
                                  e.target.value
                                );
                              }
                            }}
                            value={tenantFormik.values.emergency_contact?.name}
                            onKeyDown={preventEnterKey}
                          />
                        </FormGroup>
                      </Col>
                      <Col  lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd6"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Relationship to Tenant
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd6"
                            type="text"
                            name="emergency_contact.relation"
                            placeholder="Enter relationship to tenant"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={
                              tenantFormik.values.emergency_contact?.relation
                            }
                            onKeyDown={preventEnterKey}
                          />
                        </FormGroup>
                      </Col>

                      <Col  lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd7"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            E-Mail
                          </label>
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd7"
                            type="text"
                            name="emergency_contact.email"
                            placeholder="Enter email"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={tenantFormik.handleChange}
                            value={tenantFormik.values.emergency_contact?.email}
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched?.emergency_contact?.email &&
                          tenantFormik.errors?.emergency_contact?.email ? (
                            <box
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors?.emergency_contact?.email}
                            </box>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col  lg="3" md="6" sm="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd8"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Phone Number
                          </label>
                          <Input
                            style={{
                              boxShadow: "0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              // width: "80%",
                            }}
                            className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                            id="input-unitadd8"
                            type="text"
                            name="emergency_contact.phoneNumber"
                            placeholder="Enter phone number"
                            autoComplete="off"
                            onBlur={tenantFormik.handleBlur}
                            onChange={(e) => {
                              if (
                                tenantFormik.values.emergency_contact
                                  ?.phoneNumber?.length > e.target.value?.length
                              ) {
                                tenantFormik.setFieldValue(
                                  "emergency_contact.phoneNumber",
                                  e.target.value
                                );
                              } else {
                                const formattedValue = formatPhoneNumber(
                                  e.target.value
                                );
                                tenantFormik.setFieldValue(
                                  "emergency_contact.phoneNumber",
                                  formattedValue
                                );
                              }
                            }}
                            value={
                              tenantFormik.values.emergency_contact?.phoneNumber
                            }
                            onKeyDown={preventEnterKey}
                          />
                          {tenantFormik.touched?.emergency_contact?.phoneNumber &&
                          tenantFormik.errors?.emergency_contact?.phoneNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {tenantFormik.errors?.emergency_contact?.phoneNumber}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </box>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* 6002 end */}
          <Row className="mt-3 mb-3">
            <Col className="order-xl-1" xl="12">
              <Card
                className="mx-3 cardstyborderradius"
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040",
                  border: "1px solid #324567",
                }}
              >
                <CardBody>
                  <h6
                    className="mb-4 labelfontstyle textcolorblue fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Override Debit Card Fee
                  </h6>
                  <box className="">
                    <Row>
                      <Col lg="4">
                        <FormGroup
                          className="checkboxbillable "
                          style={{ alignItems: "center" }}
                        >
                          <Label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="override-enable"
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            <Checkbox
                              id="override-enable"
                              className="checkboxx"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                                "&.Mui-checked": {
                                  color: "#152B51",
                                },
                              }}
                              checked={isEnable === true}
                              onChange={handleChange}
                            />
                            Enable Debit Card Fee Override
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {isEnable && (
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <box style={{ position: "relative", width: "70%" }}>
                              <Input
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                }}
                                className="form-control-alternative  fontstylerentmodal titleecolor"
                                id="input-unitadd6"
                                type="text"
                                name="override_fee"
                                placeholder="Add fee here"
                                autoComplete="off"
                                onBlur={tenantFormik.handleBlur}
                                onChange={(e) => {
                                  const value = e.target.value;

                                  if (
                                    /^\d*\.?\d*$/.test(value) &&
                                    (value === "" ||
                                      (parseFloat(value) >= 0 &&
                                        parseFloat(value) <= 100))
                                  ) {
                                    tenantFormik.handleChange(e);
                                  }
                                }}
                                value={tenantFormik.values.override_fee}
                                onKeyDown={preventEnterKey}
                              />
                              <span
                                className="fontstylerentmodal"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                %
                              </span>
                            </box>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </box>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <box className=" mt-3 mb-4 btnstyle" style={{ marginLeft: "33px" }}>
              {loader ? (
                <Button
                  type="submit fontstylerentr background-colorsty bgtextwhite"
                  className="btn"
                  style={{
                    cursor: "not-allowed",
                  }}
                  disabled
                >
                  Loading...
                </Button>
              ) : tenant_id ? (
                <Button
                  type="submit"
                  className="btn bottomspacee fontstylerentr background-colorsty fontfamilysty bgtextwhite"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: 0,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    tenantFormik.handleSubmit();
                  }}
                >
                  Update Tenant
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="btn bottomspacee fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: 0,
                  }}
                  disabled={!tenantFormik.isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    tenantFormik.handleSubmit();
                  }}
                >
                  Add Tenant
                </Button>
              )}
              <Button
                onClick={handleCloseButtonClick}
                className="btn fontstylerentr backgroundwhitesty textcolorblue fontfamilysty"
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                  cursor: "pointer",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                Cancel
              </Button>
            </box>
            <box className="ml-3 mt-3 validationmsgmt">
              {!tenantFormik.isValid && (
                <box
                  className="fontstylerentr validationmsgtenant"
                  style={{ color: "red", marginTop: "10px" }}
                >
                  Please fill in all fields correctly.
                </box>
              )}
            </box>
          </Row>
        </Form>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Leaseing;
