import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Button
} from "@mui/material";
import BaseButton from "components/ReusableComponents/BaseButton";
import interceptor from "plugins/axios";
import { useParams, useNavigate  } from "react-router-dom";
import moment from "moment";

const RentalDetails = () => {
  const { id } = useParams();
  const { admin } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError("");
        const response = await interceptor.get(`renter-insurance/policy/${id}`);
        const data = response.data.data;
        data.effective_date = moment(data.effective_date).format("MM-DD-YYYY");
        data.expiration_date = moment(data.expiration_date).format(
          "MM-DD-YYYY"
        );
        console.log(data);
        setData(data);
      } catch (err) {
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlebackclick = () => {
      navigate(-1);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box style={{ padding: "40px 24px" }}>
        <p style={{ textAlign: "center", padding: "80px 20px", color: "red" }}>
          {error}
        </p>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
       <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
      {/* Rental Information Section */}
      <Typography variant="h4" gutterBottom>
        Renter's Insurance Details
      </Typography>
      <Button
        className="mr-4 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
        onClick={() => handlebackclick()}
        size="small"
        style={{
          fontSize: "16px",
          fontWeight: "500",
          boxShadow: "0px 4px 4px 0px #00000040",
          borderRadius: "4px",
          border: "0",
        }}
      >
        Back
      </Button>
      </Box>
      <Card variant="outlined" sx={{ marginBottom: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Insurance Information</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Insurance Company : </strong> {data.insurance_company}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Company Phone Number : </strong>{" "}
                {data.insurance_company_phone_number}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Policy ID: </strong> {data.policy_id},
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Effective Date: </strong> {data.effective_date}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Expiration Date: </strong> {data.expiration_date}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <strong>Liability Coverage: </strong> $
                {data.liability_coverage.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Tenant Information Section */}
      <Typography variant="h5" gutterBottom>
        Tenant Details
      </Typography>
      <Grid container spacing={3}>
        {Array.isArray(data.tenant_details) &&
          data.tenant_details.map((tenant) => (
            <Grid item xs={12} md={6} key={tenant.tenant_id}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">
                    {tenant.tenant_firstName} {tenant.tenant_lastName}
                  </Typography>
                  <Typography>
                    <strong>Email: </strong> {tenant.tenant_email}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>

      {/* Download Button */}
      {data.insurance_policy_document && (
        <Box sx={{ marginTop: 3 }}>
          <p
            style={{
              marginBottom: " 6px",
              fontWeight: "normal",
              color: "#0066d0",
            }}
          >
            {data.insurance_policy_document}
          </p>
          <a
            target="_blank"
            href={`https://saas.cloudrentalmanager.com/api/images/get-file/${data.insurance_policy_document}`}
            rel="noreferrer"
          >
            <BaseButton label="Download Insurance Document" />
          </a>
        </Box>
      )}
    </Box>
  );
};

export default RentalDetails;
