import React, { useState, useEffect } from "react";
import {
  CardHeader,
  Table,
  Container,
  FormGroup,
  Row,
  Button,
  Col,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useCookies } from "react-cookie";
import Header from "components/Headers/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import deleicon from "../../../assets/img/icons/common/delete.svg";
import swal from "sweetalert";

const ScheduledPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { admin } = useParams();
  const [schedulepayment, setSchedulePayment] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [sortedColumn, setSortedColumn] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [hoveredColumn, setHoveredColumn] = useState("date");

  const [accessType, setAccessType] = useState(null);
  const [permissions, setPermissions] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
    };

    handleCheckToken();
  }, []);

  const getRenewalData = async () => {
    if (accessType?.admin_id) {
      setLoader(true);
      try {
        const response = await axiosInstance.get(
          `/payment/upcomingpayment/${accessType?.admin_id}`
        );

        if (response.data.statusCode === 200) {
          setSchedulePayment(response?.data?.data);

          setTotalPages(Math.ceil(response.data.data.length / pageItem));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    getRenewalData();
  }, [accessType, pageItem]);

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (schedulepayment) {
    paginatedData = schedulepayment.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortedColumn(column);
    setSortOrder(isAsc ? "desc" : "asc");
    sortData(column, isAsc ? "desc" : "asc");
  };

  const sortData = (column, order) => {
    let sortedData = [...schedulepayment];
    switch (column) {
      case "rental_address":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? a.rental_address?.localeCompare(b.rental_address)
            : b.rental_address?.localeCompare(a.rental_address)
        );
        break;
      case "date":
        sortedData?.sort((a, b) =>
          order === "asc"
            ? new Date(a?.date) - new Date(b?.date)
            : new Date(b?.date) - new Date(a?.date)
        );
        break;
      default:
        break;
    }
    setSchedulePayment(sortedData || []);
  };

  const filterTenantsBySearch = () => {
    let filteredData = schedulepayment;
    if (searchQuery) {
      const lowerCaseSearchQuery = searchQuery.toLowerCase().toString();

      filteredData = filteredData.filter((payment) => {
        return (
          payment?.rental_address
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          payment?.tenant?.tenant_name
            ?.toLowerCase()
            .includes(lowerCaseSearchQuery) ||
          payment?.date?.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    }
    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterTenantsBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const deleteTenant = (payment) => {
    swal({
      title: "Are you sure?",
      text: `You want to delete this scheduled payment for ${payment.tenant.tenant_name} at ${payment.rental_address} in the amount of $${payment.total_amount} on ${payment.date}?`,
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          Autocomplete: "off",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            if (deleteButton) {
              deleteButton.disabled = reason.trim() === "";
            }
          },
        },
      },
      buttons: {
        confirm: {
          text: "OK",
          closeModal: false,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deleteReasonInput = document.getElementById("delete-reason");
        const deletionReason = deleteReasonInput
          ? deleteReasonInput.value.trim()
          : "";

        if (!deletionReason) {
          toast.error("Reason for deletion is required", {
            position: "top-center",
            autoClose: 2000,
          });
          return;
        }

        const deleteButton = document.querySelector(".swal-button--confirm");
        if (deleteButton) deleteButton.disabled = true;

        try {
          const res = await axiosInstance.delete(
            `/payment/payment/${payment._id}`,
            {
              params: { company_name: admin },
              data: { reason: deletionReason },
            }
          );

          if (res.data.statusCode === 200) {
            toast.success("Ledger Deleted Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            setTimeout(getRenewalData, 500);
            swal.close();
          } else {
            toast.warning(res.data.message || "An error occurred", {
              position: "top-center",
              autoClose: 1000,
            });
            swal.close();
          }
        } catch (error) {
          console.error("Error deleting payment:", error);
          toast.error("Error deleting payment. Please try again.", {
            position: "top-center",
          });
          swal.close();
        }
      } else {
        toast.success("Payment is safe :)", {
          position: "top-center",
          autoClose: 1000,
        });
        swal.close();
      }
    });

    // Safely disable the delete button after the modal is displayed
    const observer = new MutationObserver(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
        observer.disconnect();
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
  };

  return (
    <>
      <Header />
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Scheduled Payments
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />

        <Row>
          <div className="col">
            {loader ? (
              <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="50"
                  visible={loader}
                />
              </div>
            ) : (
              <>
                <Row className="mb-3">
                  <Col xs="6" sm="6">
                    <FormGroup className="searchspace searchstyle">
                      <Input
                        className="filterh"
                        type="text"
                        placeholder="Search here..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                          width: "100%",
                          maxWidth: "200px",
                          minWidth: "200px",
                          fontWeight: "500",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* </CardHeader> */}
                <Table
                  className="align-items-center table-flush"
                  responsive
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead
                    className=""
                    style={{
                      height: "45px",
                      fontSize: "14px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      boxShadow: " 0px 4px 4px 0px #00000040",
                    }}
                  >
                    <tr
                      style={{
                        border: "2px solid rgba(50, 69, 103, 1)",
                      }}
                    >
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          cursor: "pointer",
                          width: "20%",
                        }}
                        onClick={() => handleSort("date")}
                        onMouseEnter={() => setHoveredColumn("date")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Date
                        {hoveredColumn === "date" || sortedColumn === "date" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{
                          cursor: "pointer",
                          width: "30%",
                        }}
                        onClick={() => handleSort("rental_address")}
                        onMouseEnter={() => setHoveredColumn("rental_address")}
                        onMouseLeave={() => setHoveredColumn("")}
                      >
                        Property address
                        {hoveredColumn === "rental_address" ||
                        sortedColumn === "rental_address" ? (
                          sortOrder === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ width: "30%" }}
                      >
                        Tenant Name
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ width: "20%", textAlign: "right" }}
                      >
                        Amount
                      </th>
                      <th
                        className="tablefontstyle textcolorblue"
                        scope="col"
                        style={{ width: "20%", textAlign: "right" }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {schedulepayment?.length === 0 ? (
                    <tbody>
                      <tr className="text-center">
                        <td colSpan="4">No Schedule Payment Found</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr
                        style={{
                          border: "none",
                        }}
                      >
                        <td colSpan="9"></td>
                      </tr>
                      {filterTenantsBySearchAndPage()?.map((payment, index) => (
                        <React.Fragment>
                          <tr
                            key={index}
                            style={{
                              border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {payment?.date}
                            </td>
                            <td
                              className="bordertopintd tablebodyfont tabletextcolor"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                navigate(
                                  admin
                                    ? `/${admin}/rentrolldetail/${payment.lease_id} `
                                    : `/staff/staffrentrolldetail/${payment.lease_id} `,
                                  {
                                    state: {
                                      leas_id: payment.lease_id,
                                      tab: "Financial",
                                    },
                                  }
                                );
                              }}
                            >
                              {payment?.rental_address}{" "}
                              {payment?.rental_unit
                                ? " - " + payment?.rental_unit
                                : null}{" "}
                            </td>
                            <td className="bordertopintd tablebodyfont tabletextcolor">
                              {payment?.tenant?.tenant_name}
                            </td>
                            <td
                              className="bordertopintd tablebodyfont tabletextcolor"
                              style={{ textAlign: "right" }}
                            >
                              {`$${payment?.total_amount?.toFixed(2)}`}
                            </td>
                            <td
                              className="bordertopintd tablebodyfont tabletextcolor"
                              style={{ textAlign: "center" }}
                            >
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  deleteTenant(payment);
                                }}
                              >
                                <img src={deleicon} width={20} height={20} />
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  )}
                </Table>
                {paginatedData?.length > 0 ? (
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {pageItem}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(10);
                              setCurrentPage(1);
                            }}
                          >
                            10
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(25);
                              setCurrentPage(1);
                            }}
                          >
                            25
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(50);
                              setCurrentPage(1);
                            }}
                          >
                            50
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPageItem(100);
                              setCurrentPage(1);
                            }}
                          >
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>{" "}
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Row>
        <br />
        <br />
        <ToastContainer />
      </Container>
    </>
  );
};

export default ScheduledPayment;
