import React, { useEffect, useRef, useState } from "react";
import { renderAsync } from "docx-preview"; 
const { Modal, ModalBody } = require("reactstrap");

const DocumentFileModal = ({ open, setOpen, file, fileType }) => {
	const cdnUrl = process.env.REACT_APP_IMAGE_GET_URL;

	const fileURL =
	  file instanceof File ? URL.createObjectURL(file) : `${cdnUrl}/${file}`;
  
	const [isPDF, setIsPDF] = useState(false);
	const previewContainerRef = useRef(null); 
  
	// Debugging logs for file details
	useEffect(() => {
	  console.log("File:", file);
	  console.log("File URL:", fileURL);
	  console.log("File Type:", fileType);
  
	  if (fileType === "application/pdf" || fileURL?.match(/\.pdf$/i)) {
		setIsPDF(true);
	  } else {
		setIsPDF(false);
	  }
  
	  return () => {
		if (file instanceof File) {
		  URL.revokeObjectURL(fileURL);
		}
	  };
	}, [file, fileURL, fileType]);
  
	useEffect(() => {
	  // Render DOC/DOCX files with docx-preview
	  if (
		fileType === "application/msword" ||
		fileType ===
		  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
		fileURL?.match(/\.(doc|docx)$/i)
	  ) {
		const renderDocument = async () => {
		  try {
			if (file instanceof File) {
			  const arrayBuffer = await file.arrayBuffer();
			  await renderAsync(arrayBuffer, previewContainerRef.current);
			} else {
			  const response = await fetch(fileURL);
			  const arrayBuffer = await response.arrayBuffer();
			  await renderAsync(arrayBuffer, previewContainerRef.current);
			}
		  } catch (error) {
			console.error("Error rendering DOCX file:", error);
		  }
		};
  
		renderDocument();
	  }
	}, [file, fileType, fileURL]);
  
	const renderContent = () => {
	  console.log("Rendering content for fileURL:", fileURL);
  
	  if (!fileURL) {
		console.log("No file URL provided.");
		return <p>No file to display.</p>;
	  }
  
	  // Render an image file
	  if (fileType?.includes("image/") || fileURL?.match(/\.(jpg|jpeg|png)$/i)) {
		console.log("Rendering an image.");
		return <img src={fileURL} width={"100%"} alt="Image" />;
	  }
  
	  // Render a PDF file
	  if (isPDF) {
		console.log("Rendering a PDF.");
		return (
		  <object
			data={fileURL}
			type="application/pdf"
			style={{ width: "100%", height: "80vh" }}
		  >
			<div className="mx-5 my-5">
			  <p className="text-lg font-weight-bolder">
				Unable to display this file type.
			  </p>
			  <a href={fileURL} target="_blank" rel="noopener noreferrer">
				Download file
			  </a>
			</div>
		  </object>
		);
	  }
  
	  // Render a DOC/DOCX file using docx-preview
	  if (
		fileType === "application/msword" ||
		fileType ===
		  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
		fileURL?.match(/\.(doc|docx)$/i)
	  ) {
		console.log("Rendering a DOC/DOCX file.");
		return (
		  <div
			ref={previewContainerRef}
			style={{ width: "100%", height: "80vh" }}
		  />
		);
	  }
  
	  // Render a fallback for unsupported file types
	  console.log("Unable to render this file type.");
	  return (
		<div className="mx-5 my-5">
		  <p className="text-lg font-weight-bolder">
			Unable to display this file type.
		  </p>
		  <a href={fileURL} target="_blank" rel="noopener noreferrer">
			Download file
		  </a>
		</div>
	  );
	};
  
	return (
	  <Modal
		isOpen={open}
		toggle={() => setOpen(!open)}
		style={{
		  maxWidth: "100%",
		  maxHeight: "100%",
		  display: "flex",
		  justifyContent: "center",
		}}
	  >
		<ModalBody className="p-0 m-0">{renderContent()}</ModalBody>
	  </Modal>
	);  
};

export default DocumentFileModal;
