/* eslint-disable jsx-a11y/scope */
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "components/Headers/Header";
import { useNavigate } from "react-router-dom";
import profilei from "../../assets/img/icons/common/profilewhite.svg";
import phone from "../../assets/img/icons/common/phone.svg";
import email from "../../assets/img/icons/common/email.svg";
import { RotatingLines } from "react-loader-spinner";
import {
  Button,
  Card,
  CardHeader,
  FormGroup,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Form,
  Input,
  Label,
  CardBody,
} from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCookies } from "react-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardContent, Grid, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Leaseing.css";
import CreditCardForm from "./CreditCardForm";
import swal from "sweetalert";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Img from "../../assets/img/theme/site-logo.png";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { verifyToken } from "components/Functions/Functions";
import { getTokenizationKey } from "plugins/ApiHandler";
import { addTokenizationScript } from "plugins/helpers";
import RecurringTenants from "./Functions/RecurringTenants";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import deleicon from "../../assets/img/icons/common/delete.svg";
import AccountDialog from "components/AccountDialog";
import FileModal from "./FileModal";
import axios from "axios";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { FaTimes } from "react-icons/fa";
import RenterInsurance from "components/RenterInsurance/RenterInsurance";
import Loader from "components/ReusableComponents/Loader";
import interceptor from "plugins/axios";
import BaseButton from "components/ReusableComponents/BaseButton";
import EmailDialogModal from "components/EmailDialog";
import RentDocument from "components/RentRollDetail/RentDocument";

const RentRollDetail = () => {
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const { lease_id, admin } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [currentPlan, setCurrentPlan] = useState("");
  const [accessType, setAccessType] = useState(null);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [tenantDetails, setTenantDetails] = useState([]);
  const [rentalAddress, setRentalAddress] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [permissions, setPermissions] = useState(null);
  const [newFiles, setNewFiles] = useState([]);
  const [fileModalOpen, setFileModalOpen] = useState(null);
  const [getLeaseDetailsLoading, setGetLeaseDetailsLoading] = useState(false);
  const [getLeaseDetailsError, setGetLeaseDetailsError] = useState("Hello");

  // const [cookies] = useCookies(["token"]);
  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
      setPermissions(accessData?.permissions);
      if (accessData) {
        try {
          const res = await axiosInstance.get(
            `/purchase/plan-purchase/${accessData.admin_id}`
          );
          if (res.data.data && res.data.data.plan_detail) {
            setCurrentPlan(res.data.data.plan_detail.plan_name);
          }
        } catch (error) {
          console.log("Error: ", error);
        } finally {
          setLoader(false);
        }
      }
    };

    handleCheckToken();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  //set lease data
  const [leaseData, setLeaseData] = useState([]);
  const [renewLeaseData, setRenewLeaseData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchLeaseData = async () => {
    if (lease_id) {
      try {
        setGetLeaseDetailsLoading(true);
        setGetLeaseDetailsError("");
        const res = await interceptor.get(`/leases/lease_summary/${lease_id}`);
        setLeaseData(res.data.data);
        setRenewLeaseData({
          ...res.data.data,
          end_date:
            res.data.data.renewLeases?.length > 0
              ? moment(
                  new Date(
                    Math.max(
                      ...res.data.data.renewLeases.map(
                        (item) => new Date(item?.end_date)
                      )
                    )
                  )
                ).format("YYYY-MM-DD")
              : res.data.data?.end_date,
        });
      } catch (error) {
        setGetLeaseDetailsError(error);
      } finally {
        setGetLeaseDetailsLoading(false);
      }
    }
  };

  // {{5757 - start}}
  useEffect(() => {
    if (renewLeaseData?.end_date) {
      if (
        moment(new Date(renewLeaseData?.end_date)).format("YYYY-MM-DD") <=
        moment(new Date()).format("YYYY-MM-DD")
      ) {
        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setMonth(startDate.getMonth() + 1);
        setRenewLeaseData((values) => ({
          ...values,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          lease_amount: renewLeaseData?.amount,
          renewAmount: 0,
        }));
      } else {
        const startDate = new Date(renewLeaseData?.end_date);
        const endDate = new Date(startDate);
        endDate.setMonth(startDate.getMonth() + 1);
        setRenewLeaseData((values) => ({
          ...values,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          lease_amount: renewLeaseData?.amount,
          renewAmount: 0,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseData]);

  const [sendLoader, setSendLoader] = useState(false);
  const handleRenewLease = async () => {
    try {
      setSendLoader(true);
      const date = new Date();
      let uploadedFiles = [];

      if (Array.isArray(newFiles) && newFiles.length > 0) {
        try {
          const form = new FormData();
          newFiles.forEach((file) => {
            if (file instanceof File) {
              form.append("files", file);
            }
          });

          const res = await axios.post(`${imageUrl}/images/upload`, form);

          if (res && res.data && res.data.files && res.data.files.length > 0) {
            uploadedFiles = res.data.files.map((file) => file.filename);
          } else {
            console.error("Unexpected response format:", res);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      } else {
        uploadedFiles = newFiles;
      }

      const validChargeData = chargeData.filter(
        (item) => item.amount && item.account && item.charge_type
      );

      const charges =
        validChargeData.length > 0
          ? {
              charge_id: `${date}`,
              lease_id: leaseData.lease_id,
              admin_id: leaseData.admin_id,
              is_leaseAdded: true,
              type: "Charge",
              total_amount: validChargeData.reduce(
                (acc, item) => Number(acc) + Number(item.amount),
                0
              ),
              entry: validChargeData?.map((item, i) => ({
                ...item,
                memo: item.account,
                entry_id: `${date}-${i}`,
              })),
              createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
              updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
          : {};

      const renewRes = await axiosInstance.post("/leases/renew_lease", {
        ...renewLeaseData,
        isExpired:
          moment(new Date(renewLeaseData?.start_date)).format("YYYY-MM-DD") <=
          moment(new Date()).format("YYYY-MM-DD"),
        charges,
        renew_fileName: uploadedFiles,
      });

      if (renewRes.data.statusCode === 201) {
        fetchLeaseData();
        toast.success(renewRes.data.message, {
          position: "top-center",
          autoclose: 800,
        });
        setShowModal1(!showModal1);
        fetchLeaseData();
        setNewFiles([]);
        fetchfinancialData();
      } else if (renewRes.data.statusCode === 203) {
        toast.error(renewRes.data.message, {
          position: "top-center",
          autoclose: 800,
        });
      }
    } catch (error) {
      console.error("Error: ", error.message);
    } finally {
      setSendLoader(false);
    }
  };
  // {{5757 - end}}

  //set financial data
  const [financialData, setFinancialData] = useState([]);
  const fetchfinancialData = async () => {
    if (lease_id) {
      try {
        const url = `/payment/charges_payments/${lease_id}`;
        const res = await axiosInstance.get(url);
        setFinancialData(res.data.data);
        setTotalPages(Math.ceil(res.data.data.length / pageItem));
        setTotalAmount(res.data.totalBalance);
      } catch (error) {
        console.error("Error: ", error.message);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchfinancialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageItem]);

  const fetchTenantsData = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(`/tenant/leases/${lease_id}`);
        const data = res.data.data;
        setTenantDetails(data);
        setRentalAddress(data[0]?.rental_adress);
        setTenantId(data[0]?.tenant_id);
      } catch (error) {
        console.error("Error: ", error.message);
      }
    }
  };

  // get surcharge data
  const [surchargeData, setSurchargeData] = useState([]);
  const fetchSurchargeData = async () => {
    if (lease_id) {
      try {
        const res = await axiosInstance.get(
          `/surcharge/surcharge/${accessType?.admin_id}`
        );
        setSurchargeData(res.data.data[0]);
      } catch (error) {
        console.error("Error: ", error.message);
      }
    }
  };
  useEffect(() => {
    fetchLeaseData();
    fetchfinancialData();
    fetchTenantsData();
    fetchSurchargeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lease_id, pageItem]);

  const [scriptGenerating, setScriptGenerating] = useState(false);
  const [scriptError, setScriptError] = useState("");

  const getNmiKey = async () => {
    setScriptGenerating(true);
    setScriptError("");

    try {
      const keyResponse = await getTokenizationKey(tenantId);
      await addTokenizationScript(keyResponse.publicKey);
    } catch (error) {
      // console.log(error);
      setScriptError(
        "Failed to load the tokenization script. Make sure you have suitable internet connection."
      );
    } finally {
      setScriptGenerating(false);
    }
  };

  useEffect(() => {
    if (tenantId) getNmiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const deleteTenant = (charge_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this charge!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;

            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            deleteButton.disabled = reason.trim() === "";
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: true,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deletionReason = document.getElementById("delete-reason").value;

        try {
          const res = await axiosInstance.delete(
            `/charge/charge/${charge_id}`,
            {
              data: {
                reason: deletionReason,
              },
            }
          );

          if (res.data.statusCode === 200) {
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
            fetchfinancialData();
          } else {
            toast.warning(res.data.message, {
              position: "top-center",
              autoClose: 1000,
            });
          }
        } catch (error) {
          console.error("Error deleting charge:", error);
          toast.error("Error deleting charge", {
            position: "top-center",
          });
        }
      } else {
        toast.success("Charge is safe :)", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    });

    setTimeout(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
      }
    }, 0);
  };

  const [value, setValue] = useState("Summary");
  const handleChange = (value) => {
    setValue(value);
  };

  useEffect(() => {
    if (location?.state?.tab) {
      setValue(location.state.tab);
    }
  }, [location.state]);

  useEffect(() => {
    if (location?.state?.lease_id) {
      setShowModal1(true);
    }
  }, [location.state]);

  const handlebackclick = () => {
    if (location?.state?.leas_id) {
      navigate(-1);
    } else {
      navigate(admin ? "/" + admin + "/RentRoll" : "/staff/staffrentroll");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [showModal, setShowModal] = useState(false);
  const [moveOutDate, setMoveOutDate] = useState("");
  const [noticeGivenDate, setNoticeGivenDate] = useState("");
  const [clickedObject, setClickedObject] = useState({});
  const handleMoveOutClick = (tenant) => {
    setClickedObject(tenant);
    setMoveOutDate(tenant.end_date);
    const currentDate = new Date().toISOString().split("T")[0];
    setNoticeGivenDate(currentDate);
    setShowModal(true);
  };

  const handleMoveIn = async (tenant) => {
    const confirmation = await swal({
      title: "Are you sure?",
      text: "Do you want to Move-in this Tenant?",
      icon: "warning",
      buttons: ["Confirm", "Cancel"],
      dangerMode: true,
    });

    if (!confirmation) {
      try {
        const response = await axiosInstance.put(
          `/moveout/lease_movein/${tenant.lease_id}`,
          { admin_id: tenant.admin_id, tenant_id: tenant.tenant_id }
        );

        if (response.data.statusCode === 200) {
          toast.success("Tenant Move-in Successfully", {
            position: "top-center",
            autoClose: 500,
          });
          fetchTenantsData();
          fetchLeaseData();
        } else {
          toast.error("An error occurred while Move-in", {
            position: "top-center",
            autoClose: 500,
          });
        }
      } catch (error) {
        toast.error("An unexpected error occurred.", {
          position: "top-center",
          autoClose: 500,
        });
        console.error("Move-in error:", error);
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNoticeGivenDate("");
    setMoveOutDate("");
  };

  const handleMoveout = async (tenant_id) => {
    if (moveOutDate && noticeGivenDate) {
      const updatedApplicant = {
        admin_id: accessType.admin_id,
        tenant_id: tenant_id,
        moveout_notice_given_date: noticeGivenDate,
        moveout_date: moveOutDate,
      };

      await axiosInstance
        .post(`/moveout/lease_moveout/${lease_id}`, updatedApplicant)
        .then((res) => {
          if (res.data.statusCode === 200) {
            toast.success("Move-out Successfully", {
              position: "top-center",
              autoClose: 500,
            });
            handleModalClose();
            fetchTenantsData();
            fetchLeaseData();
          }
        })
        .catch((err) => {
          toast.error("An error occurred while Move-out", {
            position: "top-center",
            autoClose: 500,
          });
          console.error(err);
        });
    } else {
      toast.error("NOTICE GIVEN DATE && MOVE-OUT DATE must be required", {
        position: "top-center",
        autoClose: 500,
      });
    }
  };

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessType]);

  // eslint-disable-next-line no-unused-vars
  const [refund, setRefund] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [showOptions, setShowOptions] = useState(false);
  const [showOptionsId, setShowOptionsId] = useState("");
  const [isRefundOpen, setIsRefundOpen] = useState(false);
  const [ResponseData, setResponseData] = useState("");
  const [showModal1, setShowModal1] = useState(false);

  const handleRenewModalClose = () => {
    setShowModal1(false);
    setNewFiles([]);
  };

  const handleClickOpen = () => {
    setShowModal1(true);
  };

  const [leaseDateDropdownOpen, setLeaseDateDropdownOpen] = useState(false);
  const toggle3 = () => setLeaseDateDropdownOpen((prevState) => !prevState);

  //pdf generate
  const handleOptionClick = (option) => {
    if (
      !financialData ||
      !Array.isArray(financialData) ||
      financialData.length === 0
    ) {
      console.error("No financial data available to download.");
      return;
    }

    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel(financialData);
    } else if (option === "CSV") {
      generateCSV(financialData);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("l");

    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    const titleYPosition = 10 + 10;
    doc.setFontSize(20);
    doc.text(
      "Tenant Statement",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Format leaseData for custom display
    const rentalAddress = leaseData?.rental_adress
      ? leaseData?.rental_adress
      : " ";
    const rentalUnit =
      leaseData?.rental_unit &&
      leaseData?.rental_unit !== undefined &&
      leaseData?.rental_unit !== ""
        ? ` - ${leaseData?.rental_unit}`
        : "";
    const leaseInfo = `${rentalAddress}${rentalUnit}`;

    const subtitleYPosition = titleYPosition + 10;
    doc.setFontSize(12);
    doc.text(
      leaseInfo.trim() || "No Lease Data Available",
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");
    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20;
    const lineHeight = 4;

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight
      );
    });

    doc.setFontSize(12);

    const tableColumns = [
      { header: "Date", dataKey: "date" },
      { header: "Tenant", dataKey: "tenant" },
      { header: "Type", dataKey: "type" },
      { header: "Description", dataKey: "Description" },
      { header: "Amount", dataKey: "Amount" },
      { header: "Balance", dataKey: "balance" },
    ];

    // Reverse the financial data
    const filteredData = financialData.filter((item) => {
      const itemDate = new Date(item.entry[0].date);

      const isWithinDateRange =
        (!fromDate || itemDate >= new Date(fromDate)) &&
        (!toDate || itemDate <= new Date(toDate));

      return isWithinDateRange;
    });

    const reversedFinancialData = [...filteredData].reverse();

    const tableRows = reversedFinancialData.map((generalledger) => {
      return {
        date: generalledger?.entry[0]?.date
          ? moment(generalledger?.entry[0]?.date).format(dateFormat)
          : "-",
        tenant:
          generalledger?.tenantData?.tenant_firstName &&
          generalledger?.tenantData?.tenant_lastName
            ? `${generalledger.tenantData.tenant_firstName} ${generalledger.tenantData.tenant_lastName}`
            : "N/A",
        type: generalledger?.type || "-",

        transaction:
          generalledger.response && generalledger.payment_type
            ? `Manual ${generalledger.type} ${generalledger.response} for ${
                generalledger.payment_type
              }${
                generalledger.check_number
                  ? `\n(#${generalledger.check_number})`
                  : ""
              }${
                generalledger.reference
                  ? `\n(#reference: ${generalledger.reference})`
                  : ""
              }${
                generalledger.externalPostingId
                  ? `\n(Imported Transaction Id #${generalledger.externalPostingId})`
                  : ""
              }`
            : generalledger?.entry.map((item) => item.memo).join(", ") || "-",

        increase:
          generalledger.type === "Charge" || generalledger.type === "Refund"
            ? `$${generalledger.total_amount?.toFixed(2) || "0"}`
            : "-" && generalledger.type === "Payment"
            ? `-$${generalledger.total_amount?.toFixed(2) || "0"}`
            : "-",
        // decrease:

        balance:
          generalledger.balance !== undefined
            ? generalledger.balance >= 0
              ? `$${Number(generalledger.balance || 0).toFixed(2)}`
              : `-$${Number(Math.abs(generalledger.balance) || 0).toFixed(2)}`
            : "0",
      };
    });

    // Render the table
    doc.autoTable({
      startY: subtitleYPosition + 15,
      head: [tableColumns.map((col) => col.header)],
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 13,
        fontStyle: "bold",
      },
      body: tableRows.map((row) => [
        {
          content: row.date,
          styles: { textColor: "#000", fontFamily: "Poppins" },
        },
        {
          content: row.tenant,
          styles: { textColor: "#000", fontFamily: "Poppins" },
        },
        {
          content: row.type,
          styles: { textColor: "#000", fontFamily: "Poppins" },
        },
        {
          content: row.transaction,
          styles: { textColor: "#000", fontFamily: "Poppins" },
        },
        {
          content: row.increase,
          styles: { textColor: "#000", fontFamily: "Poppins", halign: "right" },
        },
        {
          content: row.balance,
          styles: { textColor: "#000", fontFamily: "Poppins", halign: "right" },
        },
      ]),
      theme: "plain",
      styles: {
        fontSize: 10,
        cellPadding: 2,
        textColor: "#000",
        lineColor: "#000",
        lineWidth: 0.3,
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        2: { cellWidth: 35 },
        3: { cellWidth: 105 },
        4: { cellWidth: 30 },
        5: { cellWidth: 30 },
        6: { cellWidth: 30 },
      },
    });

    const lastBalance =
      tableRows.length > 0 ? tableRows[tableRows.length - 1].balance : "$0.00";

    const balanceDueYPosition = doc.autoTable.previous.finalY + 10;

    doc.setFontSize(12);
    doc.text("Balance Due", 15, balanceDueYPosition);
    doc.text(
      lastBalance,
      doc.internal.pageSize.getWidth() - 15 - doc.getTextWidth(lastBalance),
      balanceDueYPosition
    );

    const lineSpacing = 3;
    const lineYPosition1 = balanceDueYPosition + 5;
    const lineYPosition2 = lineYPosition1 + lineSpacing;

    if (lineYPosition1 > 10 && lineYPosition2 > 10) {
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(
        10,
        lineYPosition1,
        doc.internal.pageSize.getWidth() - 10,
        lineYPosition1
      );
      doc.line(
        10,
        lineYPosition2,
        doc.internal.pageSize.getWidth() - 10,
        lineYPosition2
      );
    } else {
      console.error("The lines are positioned outside the page bounds.");
    }

    doc.save("Tenant_statement.pdf");
  };

  const generateExcel = (financialData) => {
    const workbook = XLSX.utils.book_new();

    const filteredData = financialData.filter((item) => {
      const itemDate = new Date(item.entry[0].date);

      const isWithinDateRange =
        (!fromDate || itemDate >= new Date(fromDate)) &&
        (!toDate || itemDate <= new Date(toDate));

      return isWithinDateRange;
    });

    const reversedFinancialData = [...filteredData].reverse();

    const worksheetData = [
      ["Date", "Tenant", "Type", "Description", "Amount", "Balance"],
      ...reversedFinancialData.map((generalledger) => [
        generalledger?.entry[0]?.date
          ? moment(generalledger?.entry[0]?.date).format(dateFormat)
          : "-",
        generalledger?.tenantData?.tenant_firstName &&
        generalledger?.tenantData?.tenant_lastName
          ? `${generalledger.tenantData.tenant_firstName} ${generalledger.tenantData.tenant_lastName}`
          : "N/A",
        generalledger?.type || "-",
        generalledger.response && generalledger.payment_type
          ? `Manual ${generalledger.type} ${generalledger.response} for ${generalledger.payment_type}`
          : generalledger?.entry.map((item) => item.memo).join(", ") || "-",

        generalledger.type === "Charge" || generalledger.type === "Refund"
          ? `$${generalledger.total_amount?.toFixed(2) || "0"}`
          : "-" && generalledger.type === "Payment"
          ? `-$${generalledger.total_amount?.toFixed(2) || "0"}`
          : "-",
        generalledger.balance !== undefined
          ? generalledger.balance >= 0
            ? `$${Number(generalledger.balance || 0).toFixed(2)}`
            : `-$${Number(Math.abs(generalledger.balance) || 0).toFixed(2)}`
          : "0",
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    worksheet["!cols"] = [
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 50 },
      { wch: 15 },
      // { wch: 15 },
      { wch: 15 },
    ];

    const headerStyle = {
      font: { bold: true, color: "000000", sz: "15" },
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      },
      alignment: { horizontal: "left", vertical: "center" },
    };

    const rightAlignStyle = {
      alignment: { horizontal: "right" },
    };

    const headers = ["A1", "B1", "C1", "D1", "E1", "F1"];
    headers.forEach((cell) => {
      worksheet[cell].s = headerStyle;
    });

    for (let i = 2; i <= worksheetData.length; i++) {
      const increaseCell = `E${i}`;
      const balanceCell = `F${i}`;

      if (worksheet[increaseCell]) worksheet[increaseCell].s = rightAlignStyle;
      if (worksheet[balanceCell]) worksheet[balanceCell].s = rightAlignStyle;
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, "Tenant Statement");

    // Save the Excel file
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "Tenant_statement.xlsx"
    );
  };

  const generateCSV = (financialData) => {
    if (!financialData || !Array.isArray(financialData)) {
      console.error("Invalid data for CSV generation");
      return;
    }

    const filteredData = financialData.filter((item) => {
      const itemDate = new Date(item.entry[0].date);

      const isWithinDateRange =
        (!fromDate || itemDate >= new Date(fromDate)) &&
        (!toDate || itemDate <= new Date(toDate));

      return isWithinDateRange;
    });

    const reversedFinancialData = [...filteredData].reverse();

    const csvData = [
      ["Date", "Tenant", "Type", "Description", "Amount", "Balance"],
      ...reversedFinancialData.map((generalledger) => [
        generalledger?.entry[0]?.date
          ? moment(generalledger?.entry[0]?.date).format(dateFormat)
          : "-",
        generalledger?.tenantData?.tenant_firstName &&
        generalledger?.tenantData?.tenant_lastName
          ? `${generalledger.tenantData.tenant_firstName} ${generalledger.tenantData.tenant_lastName}`
          : "N/A",
        generalledger?.type || "-",
        generalledger.response && generalledger.payment_type
          ? `Manual ${generalledger.type} ${generalledger.response} for ${generalledger.payment_type}`
          : generalledger?.entry.map((item) => item.memo).join(", ") || "-",
        generalledger.type === "Charge" || generalledger.type === "Refund"
          ? `$${generalledger.total_amount?.toFixed(2) || "0"}`
          : "-" && generalledger.type === "Payment"
          ? `-$${generalledger.total_amount?.toFixed(2) || "0"}`
          : "-",
        generalledger.balance !== undefined
          ? generalledger.balance >= 0
            ? `$${Number(generalledger.balance || 0).toFixed(2)}`
            : `-$${Number(Math.abs(generalledger.balance) || 0).toFixed(2)}`
          : "0",
      ]),
    ];

    const csvContent = csvData
      .map((row) =>
        row.map((cell) => `"${cell.toString().replace(/"/g, '""')}"`).join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "Tenant_statement.csv");
  };

  const toggleOptions = (id) => {
    setShowOptions(!showOptions);
    setShowOptionsId(id);
  };

  const closeRefund = () => {
    setIsRefundOpen(false);
  };

  const generalledgerFormik = useFormik({
    initialValues: {
      payment_id: "",
      date: "",
      total_amount: "",
      payments_memo: "",
      customer_vault_id: "",
      billing_id: "",
      transaction_id: "",
      surcharge: "",
      payments: [
        {
          entry_id: "",
          account: "",
          amount: "",
          balance: "",
          charge_type: "",
        },
      ],
      payments_attachment: [],
    },
    validationSchema: yup.object({
      date: yup.string().required("Required"),
      total_amount: yup.string().required("Required"),
      payments: yup.array().of(
        yup.object().shape({
          account: yup.string().required("Required"),
          amount: yup
            .number()
            .required("Required")
            .min(1, "Amount must be greater than zero.")
            .test(
              "is-less-than-balance",
              "Amount must be less than or equal to balance",
              function (value) {
                if (value && this.parent.balance) {
                  const balance = this.parent.balance;
                  return value <= balance;
                }
              }
            ),
        })
      ),
    }),
    onSubmit: (values) => {},
  });

  const fetchData = async (id) => {
    if (id) {
      try {
        const response = await axiosInstance.get(`/payment/payment/${id}`);

        if (response.data.statusCode === 200) {
          setResponseData(response.data.data);
          generalledgerFormik.setValues({
            date: response.data.data[0].entry[0].date,
            amount: response.data.data[0].total_amount,
            payment_type: response.data.data[0].payment_type,
            customer_vault_id: response.data.data[0].customer_vault_id,
            billing_id: response.data.data[0].billing_id,
            charges_attachment: response.data.data[0].charges_attachment,

            entry: [
              {
                account: response.data.data[0].account || "",
                amount: response.data.data[0].amount || "",
              },
            ],
          });
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  const handleRefundClick = async () => {
    try {
      setPaymentLoader(true);
      // Assuming 'item' is a prop or state variable
      const { payment_id, payment_type } = ResponseData[0];

      const commonData =
        payment_type === "Card"
          ? {
              admin_id: accessType?.admin_id,
              transactionId: ResponseData[0].transaction_id,
              customer_vault_id: ResponseData[0].customer_vault_id,
              billing_id: ResponseData[0].billing_id,
              amount: generalledgerFormik.values.amount,
              payment_type: ResponseData[0].payment_type,
              total_amount: generalledgerFormik.values.amount,
              tenant_firstName: ResponseData.tenant_data.tenant_firstName,
              tenant_lastName: ResponseData.tenant_data.tenant_lastName,
              tenantName: `${ResponseData?.tenant_firstName} ${ResponseData?.tenant_lastName}`,
              tenant_id: ResponseData[0].tenant_id,
              lease_id: ResponseData[0].lease_id,
              email_name: ResponseData.tenant_data.tenant_email,
              type: ResponseData[0].type,
              entry: ResponseData[0].entry.map((item) => {
                const obj = {
                  amount: item.amount,
                  account: item.account,
                  date: generalledgerFormik.values.date,
                  memo: generalledgerFormik.values.memo,
                };
                return obj;
              }),
            }
          : {
              admin_id: accessType?.admin_id,
              transactionId: ResponseData[0].transaction_id,
              amount: generalledgerFormik.values.amount,
              payment_type: ResponseData[0].payment_type,
              total_amount: generalledgerFormik.values.amount,
              tenant_firstName: ResponseData.tenant_data.tenant_firstName,
              tenant_lastName: ResponseData.tenant_data.tenant_lastName,
              tenantName: `${ResponseData?.tenant_firstName} ${ResponseData?.tenant_lastName}`,
              tenant_id: ResponseData[0].tenant_id,
              lease_id: ResponseData[0].lease_id,
              email_name: ResponseData.tenant_data.tenant_email,
              type: ResponseData[0].type,
              entry: ResponseData[0].entry.map((item) => {
                const obj = {
                  amount: item.amount,
                  account: item.account,
                  date: generalledgerFormik.values.date,
                  memo: generalledgerFormik.values.memo,
                };
                return obj;
              }),
            };

      if (payment_type === "Card" || payment_type === "ACH") {
        const response = await axiosInstance.post(`/nmipayment/new-refund`, {
          refundDetails: commonData,
        });
        if (response.data.status === 200) {
          toast.success("Refund Done Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          await fetchfinancialData();
          closeRefund();
        } else if (response.data.status === 201) {
          toast.warning(response.data.data.error, {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          toast.error("Failed to process refund", {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else if (payment_type === "Cash" || payment_type === "Check") {
        const response = await axiosInstance.post(
          `/nmipayment/manual-refund/${payment_id}`,
          {
            refundDetails: commonData,
          }
        );

        if (response.data.statusCode === 200) {
          //await setRefund(false);
          toast.success("Refund Done Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          await fetchfinancialData();
          closeRefund();
        } else {
          toast.warning(response.statusText, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } else {
        toast.warning(
          "Refund is only available for Card, Cash, or Check payments.",
          {
            position: "top-center",
            autoClose: 1000,
          }
        );
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 400) {
        toast.warning(error.response.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } finally {
      setPaymentLoader(false);
    }
  };

  const checkKey = async (plan) => {
    try {
      const res = await axiosInstance.get(
        `/nmi-keys/nmi-keys/${accessType.admin_id}`
      );
      if (res.data.data) {
        navigate(
          admin
            ? `/${admin}/AddPayment/${lease_id}`
            : `/staff/staffaddpayment/${lease_id}`,
          {
            state: { tab: "Financial" },
          }
        );
      } else {
        swal(
          "Warning!",
          "Your NMI account is not linked, contact support.",
          "warning"
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const checkKey2 = async (plan) => {
    try {
      const res = await axiosInstance.get(
        `/nmi-keys/nmi-keys/${accessType.admin_id}`
      );
      if (res.data.data) {
        openCardForm();
      } else {
        swal(
          "Warning!",
          "Your NMI account is not linked, contact support.",
          "warning"
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const openCardForm = () => {
    setIsModalOpen(true);
  };

  // function formatDateWithoutTime(dateString) {
  //   if (!dateString) return "";
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${day}-${month}-${year}`;
  // }

  const [status, setStatus] = useState("");

  useEffect(() => {
    if (leaseData) {
      const state = getStatus(leaseData.start_date, leaseData.end_date);
      setStatus(state);
    }
  }, [leaseData]);

  const getStatus = (startDate, endDate) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (today >= start && today <= end) {
      return "Active";
    } else if (today < start) {
      return "FUTURE";
    } else if (today > end) {
      return "EXPIRED";
    } else {
      return "";
    }
  };

  const getRenewstatus = (startDate, endDate, renewed) => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (renewed) {
      if (today >= start && today <= end) {
        return "Active";
      } else if (today > end) {
        return "EXPIRED";
      }
    } else {
      if (today < start) {
        return "FUTURE";
      } else if (today >= start && today <= end) {
        return "NOT RENEWED";
      } else if (today > end) {
        return "NOT RENEWED";
      }
    }
    return "";
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if (newFiles.length + files.length > 10) {
      swal({
        text: "You can only upload 10 files",
        icon: "warning",
      });
      return;
    }

    setNewFiles([...newFiles, ...files]);
  };

  const removeFile = (index) => {
    const updatedFiles = [...newFiles];
    updatedFiles.splice(index, 1);
    setNewFiles(updatedFiles);
  };

  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedData, setExpandedData] = useState([]);
  const openAccount = (ledger, i) => {
    const isExpanded = expandedRows.includes(i);

    if (!isExpanded) {
      const sumOfAmounts = ledger?.entry.reduce(
        (sum, item) => sum + parseFloat(item?.amount || 0),
        0
      );
      let surcharge = 0;
      let surchargePercentage;
      if (ledger.payment_type === "Card" && ledger?.surcharge > 0) {
        surcharge = ledger?.surcharge.toFixed(2);
        surchargePercentage = (
          (ledger?.surcharge / sumOfAmounts) *
          100
        ).toFixed(2);
      } else if (ledger.payment_type === "ACH" && ledger?.surcharge > 0) {
        surcharge = (
          ledger?.surcharge - surchargeData?.surcharge_flat_ACH
        ).toFixed(2);
        surchargePercentage = ((surcharge / sumOfAmounts) * 100).toFixed(2);
      }

      setExpandedRows([...expandedRows, i]);
      setExpandedData((prevExpandedData) => ({
        ...prevExpandedData,
        [i]: {
          entry: ledger?.entry,
          surcharge: surcharge,
          serchargePercent: surchargePercentage,
          payment_type: ledger?.payment_type,
          flatFeeACH: surchargeData?.surcharge_flat_ACH,
        },
      }));
    } else {
      setExpandedRows(expandedRows.filter((row) => row !== i));
      setExpandedData((prevExpandedData) => {
        const newData = { ...prevExpandedData };
        delete newData[i];
        return newData;
      });
    }
  };
  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (financialData) {
    const allPaymentAndCharges = financialData.flatMap((item) => {
      if (item !== undefined) {
        return item?.paymentAndCharges?.map((payment) => ({
          paymentAndCharges: payment,
          unit: item.unit,
          unit_id: item.unit_id,
          _id: item._id,
        }));
      } else {
        return [];
      }
    });
    paginatedData = allPaymentAndCharges.slice(startIndex, endIndex);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setExpandedRows([]);
    setExpandedData([]);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterLedgerBySearch = () => {
    if (!searchQuery && !fromDate && !toDate) {
      return financialData;
    }

    return financialData.filter((item) => {
      const itemDate = new Date(item.entry[0].date);

      const isWithinDateRange =
        (!fromDate || itemDate >= new Date(fromDate)) &&
        (!toDate || itemDate <= new Date(toDate));

      const query = searchQuery?.toLowerCase();
      const matchesSearchQuery = query
        ? item.type.toLowerCase().includes(query) ||
          item.entry.some((elem) =>
            elem.account.toLowerCase().includes(query)
          ) ||
          item.tenantData?.tenant_firstName?.toLowerCase().includes(query) ||
          item.tenantData?.tenant_lastName?.toLowerCase().includes(query) ||
          String(item.total_amount).includes(query) ||
          item.entry.some((elem) => elem.memo.toLowerCase().includes(query))
        : true;

      return isWithinDateRange && matchesSearchQuery;
    });
  };

  //6002 end
  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterLedgerBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const [LiabilityAccounts, setLiabilityAccounts] = useState([]);
  const [recAccounts, setRecAccounts] = useState([]);
  const [oneTimeAccounts, setoneTimeAccounts] = useState([]);
  const [accounttype, setAccounttype] = useState(false);
  const toggle5 = (index) => {
    if (index === accounttype) {
      setAccounttype(false);
    } else {
      setAccounttype(index);
    }
  };

  const fetchAccounts = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/accounts/accounts/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          const filteredData1 = res.data.data.filter(
            (item) => item.charge_type === "Liability Account"
          );
          const filteredData2 = res.data.data.filter(
            (item) => item.charge_type === "Recurring Charge"
          );
          const filteredData3 = res.data.data.filter(
            (item) => item.charge_type === "One Time Charge"
          );
          setLiabilityAccounts(filteredData1);
          setRecAccounts(filteredData2);
          setoneTimeAccounts(filteredData3);
        } else if (res.data.statusCode === 201) {
          setRecAccounts();
          setoneTimeAccounts();
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessType?.admin_id]);

  const [chargeData, setChargeData] = useState([
    { account: "", amount: "", charge_type: "" },
  ]);

  const addNewCharge = () => {
    setChargeData((charges) => [
      ...charges,
      { account: "", amount: "", charge_type: "" },
    ]);
  };

  const removeCharge = (index) => {
    setChargeData((charges) => charges.filter((_, i) => i !== index));
  };

  const handleChargeChange = (value, index, type) => {
    const updatedCharge = [...chargeData];
    if (type) {
      updatedCharge[index].account = value;
      updatedCharge[index].charge_type = type;
    } else {
      updatedCharge[index].amount = value;
    }
    setChargeData(updatedCharge);
  };

  const [selectedTenantToAddCard, setSelectedTenantToAddCard] = useState("");
  const [tenantCardsModal, setTenantCardsModal] = useState(false);
  const cardToggle = () => {
    if (currentPlan !== "Free Plan") {
      setTenantCardsModal(!tenantCardsModal);
    } else {
      swal(
        "Warning!",
        "You can not configure Recurring Payment in your current plan, Please upgrade your Current Plan",
        "warning"
      );
    }
  };
  const [accountTypeName, setAccountTypeName] = useState("");

  const [addBankAccountDialogOpen, setAddBankAccountDialogOpen] =
    useState(false);

  const toggleAddBankDialog = () => {
    setAddBankAccountDialogOpen((prevState) => !prevState);
  };

  const AddNewAccountName = async (accountName) => {
    toggleAddBankDialog();
    setAccountTypeName(accountName);
  };

  //Communication tab api's for Email
  const [emails, setEmails] = useState([]);
  const [emailmodal, setEmailModal] = useState({});
  const [currentPages, setCurrentPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalsPages, setTotalsPages] = useState(1);
  const [sortField] = useState("createdAt");
  const [sortOrder] = useState("desc");
  const [filterOptions] = useState();

  const fetchEmailsData = async () => {
    if (lease_id) {
      try {
        setLoader(true);
        const queryParams = new URLSearchParams({
          page: currentPages,
          limit: itemsPerPage,
          sortField,
          sortOrder,
          search: filterOptions || "",
        }).toString();
        const response = await axiosInstance.get(
          `/email-logs/emaillogs_lease/${lease_id}?${queryParams}`
        );

        if (response.data.statusCode === 200) {
          setEmails(response.data.emails);
          setTotalsPages(response.data.totalPages);
        } else {
          setEmails([]);
        }

        setLoader(false);
      } catch (error) {
        setEmails([]);
        setLoader(false);
        console.error("Error: ", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchEmailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPages,
    itemsPerPage,
    sortField,
    sortOrder,
    filterOptions,
    accessType,
  ]);
  const handleNextPage = () => {
    const nextPage = currentPages + 1;
    if (nextPage <= totalPages) {
      setCurrentPages(nextPage);
    }
  };

  const handlePrevPage = () => {
    const prevPage = currentPages - 1;
    if (prevPage >= 1) {
      setCurrentPages(prevPage);
    }
  };
  const [openemailrecipients, setOpenEmailrecipients] = useState(false);

  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [selectedEmailData, setSelectedEmailData] = useState(null);
  const toggleEmailModal = () => {
    setEmailModalOpen(!emailModalOpen);
  };

  const handleEmailModal = (item) => {
    setSelectedEmailData(item);
    toggleEmailModal();
  };

  const handleClose = () => {
    setOpenEmailrecipients(false);
  };

  const deleteEmails = async (email_id) => {
    if (email_id) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this e-mail history!",
        icon: "warning",
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter reason for deletion",
            type: "text",
            id: "delete-reason",
            oninput: (e) => {
              const reason = e.target.value;
              const deleteButton = document.querySelector(
                ".swal-button--confirm"
              );
              deleteButton.disabled = reason.trim() === "";
            },
          },
        },
        buttons: {
          confirm: {
            text: "Delete",
            closeModal: true,
            value: true,
            className: "swal-button--danger",
          },
          cancel: "Cancel",
        },
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const deletionReason = document.getElementById("delete-reason").value;
          // console.log("Confirmed Deletion Reason:", deletionReason);

          try {
            const response = await axiosInstance.delete(
              `/email-logs/${email_id}`,
              {
                params: {
                  company_name: admin,
                },
                data: {
                  reason: deletionReason,
                },
              }
            );

            if (response.data.statusCode === 200) {
              toast.success(response.data.message, {
                position: "top-center",
                autoClose: 800,
              });
              fetchEmailsData();
            }
          } catch (error) {
            console.error("Error: ", error);
          }
        } else {
          toast.success("E-mail history is safe!", {
            position: "top-center",
            autoClose: 800,
          });
        }
      });

      setTimeout(() => {
        const deleteButton = document.querySelector(".swal-button--confirm");
        if (deleteButton) {
          deleteButton.disabled = true;
        }
      }, 0);
    }
  };

  const [searchbyQuery, setSearchByQuery] = useState("");
  const filteredDatamodal = () => {
    if (searchbyQuery) {
      return emailmodal?.to?.filter((item) =>
        item?.includes(searchbyQuery?.toLowerCase())
      );
    } else {
      return emailmodal?.to;
    }
  };

  return (
    <div>
      <Header />
      <Modal
        isOpen={showModal}
        style={{ borderRadius: "15px", minxWidth: "600px", maxWidth: "700px" }}
      >
        <ModalHeader className=" text-white">
          <strong
            className="barforsummaryrent textcolorblue fontfamilysty"
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Move Out Tenants
          </strong>
        </ModalHeader>
        <ModalBody>
          <div
            className="labelfontstyle fontfamilysty"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#979797",
            }}
          >
            Select tenants to move out. If everyone is moving, the lease will
            end on the last move-out date. If some tenants are staying, you’ll
            need to renew the lease. Note: Renters insurance policies will be
            permanently deleted upon move-out.
          </div>
          <Card
            className="mt-3"
            style={{
              padding: "15px",
              border: "1px solid #324567",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
              borderRadius: "10px",
            }}
          >
            <React.Fragment>
              <Table striped bordered responsive>
                <thead>
                  <tr
                    style={{
                      textTransform: "none",
                      background: "none",
                      border: "0.5px solid #324567",
                    }}
                  >
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Address / Unit
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Lease Type
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Start - End
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ background: "none" }}>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      {clickedObject.rental_adress
                        ? clickedObject.rental_adress
                        : ""}{" "}
                      {clickedObject.rental_unit
                        ? clickedObject.rental_unit
                        : ""}
                    </td>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Fixed
                    </td>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      {clickedObject?.start_date
                        ? moment(clickedObject?.start_date).format(dateFormat) +
                          " To " +
                          moment(clickedObject?.end_date).format(dateFormat)
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table striped bordered responsive className="mt-3">
                <thead>
                  <tr
                    style={{
                      background: "none",
                      border: "0.5px solid #324567",
                    }}
                  >
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Tenant
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Notice Given Date
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Move-Out Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ background: "none" }}>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      {clickedObject.tenant_firstName + " "}{" "}
                      {clickedObject.tenant_lastName}
                    </td>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      <div className="col">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Notice Given Date"
                          value={noticeGivenDate}
                          onChange={(e) => setNoticeGivenDate(e.target.value)}
                        />
                      </div>
                    </td>
                    <td
                      className="fontstylerentmodal textcolorblue fontfamilysty"
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      <div className="col">
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Move-out Date"
                          value={moveOutDate}
                          onChange={(e) => setMoveOutDate(e.target.value)}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </React.Fragment>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            className="fontstylerentr textcolorblue fontfamilysty backgroundwhitesty"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          >
            Close
          </Button>
          <Button
            className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => handleMoveout(clickedObject.tenant_id)}
          >
            Move out
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showModal1}
        style={{ borderRadius: "15px", minxWidth: "600px", maxWidth: "800px" }}
        toggle={() => setShowModal1(!showModal1)}
        className="modal-dialog-scrollable"
      >
        <ModalHeader className=" text-white">
          <strong
            className="barforsummaryrent textcolorblue fontfamilysty"
            style={{
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Renew Lease
          </strong>
        </ModalHeader>
        <ModalBody>
          <div
            className="labelfontstyle textcolorblue fontfamilysty"
            style={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {leaseData?.rental_adress ? leaseData?.rental_adress : " "}
            {leaseData?.rental_unit &&
            leaseData?.rental_unit !== undefined &&
            leaseData?.rental_unit !== ""
              ? ` - ${leaseData?.rental_unit}`
              : ""}
          </div>

          <Card
            className="mt-3"
            style={{
              padding: "15px",
              border: "1px solid #324567",
              boxShadow: "0px 4px 4px 0px #00000040 ",
              borderRadius: "10px",
            }}
          >
            <CardHeader
              className="labelfontstyle textcolorblue fontfamilysty"
              style={{
                fontSize: "16px",
                fontWeight: "700",
                backgroundColor: "#ccc",
              }}
            >
              Current terms
            </CardHeader>
            <CardBody>
              <Row>
                <Col
                  className="textcolorblue fontfamilysty"
                  lg={4}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Lease Type
                </Col>
                <Col
                  className="textcolorblue fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={6}
                >
                  Start-End
                </Col>
                <Col
                  className="textcolorblue fontfamilysty"
                  lg={2}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Rent
                </Col>
              </Row>
              <Row>
                <Col
                  className="titleecolor fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  {leaseData?.lease_type || "N/A"}
                </Col>
                <Col
                  className="titleecolor fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={6}
                >
                  {moment(leaseData?.start_date).format(dateFormat) +
                    " To " +
                    moment(leaseData?.end_date).format(dateFormat) || "N/A"}
                </Col>
                <Col
                  className="titleecolor fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={2}
                >
                  {" "}
                  {leaseData?.amount}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card
            className="mt-3"
            style={{
              padding: "15px",
              border: "1px solid #324567",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
              borderRadius: "10px",
            }}
          >
            <CardHeader
              className="labelfontstyle textcolorblue fontfamilysty"
              style={{
                fontSize: "16px",
                fontWeight: "700",
                backgroundColor: "#ccc",
              }}
            >
              Offer
            </CardHeader>
            <CardBody>
              <Row>
                <Col
                  className="textcolorblue fontfamilysty"
                  lg={4}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Lease Type
                </Col>
                <Col
                  className="textcolorblue fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  Lease Start
                </Col>
                <Col
                  className="textcolorblue fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  Lease End
                </Col>
              </Row>
              <Row>
                <Col
                  className="titleecolor fontfamilysty"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  <FormGroup>
                    <Dropdown
                      className="dropdownfontsyle"
                      isOpen={leaseDateDropdownOpen}
                      toggle={toggle3}
                    >
                      <DropdownToggle
                        caret
                        className="leasetypee dropdownfontsyle titleecolor"
                        style={{
                          width: "100%",
                          boxShadow: " 0px 4px 4px 0px #00000040",
                          border: "1px solid #ced4da",

                          backgroundColor: "transparent",
                        }}
                      >
                        {renewLeaseData?.lease_type &&
                        renewLeaseData?.lease_type.length > 15
                          ? renewLeaseData?.lease_type?.substring(0, 15) + "..."
                          : renewLeaseData?.lease_type || "N/A"}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdownfontsyle"
                        style={{ width: "100%" }}
                      >
                        <DropdownItem
                          className="dropdownfontsyle"
                          onClick={() => {
                            setRenewLeaseData((values) => ({
                              ...values,
                              lease_type: "Fixed",
                            }));
                          }}
                        >
                          Fixed
                        </DropdownItem>
                        <DropdownItem
                          className="dropdownfontsyle"
                          onClick={() => {
                            setRenewLeaseData((values) => ({
                              ...values,
                              lease_type: "Fixed w/rollover",
                            }));
                          }}
                        >
                          Fixed w/rollover
                        </DropdownItem>
                        <DropdownItem
                          className="dropdownfontsyle"
                          onClick={() => {
                            setRenewLeaseData((values) => ({
                              ...values,
                              lease_type: "At-will(month to month)",
                            }));
                          }}
                        >
                          At-will(month to month)
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col
                  className="titleecolor textcolorblue fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  <FormGroup>
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                        width: "80%",
                      }}
                      className="form-control-alternative fontstylerentr titleecolor"
                      id="input-unitadd2"
                      placeholder="3000"
                      type="date"
                      name="start_date"
                      value={renewLeaseData?.start_date || "N/A"}
                      onChange={(e) => {
                        setRenewLeaseData((values) => ({
                          ...values,
                          start_date: e.target.value,
                        }));
                      }}
                      min={moment(leaseData?.end_date)
                        .add(1, "days")
                        .format("YYYY-MM-DD")}
                    />
                    {/* {{5757}}  */}
                  </FormGroup>
                </Col>
                <Col
                  className="titleecolor fontfamilysty textcolorblue"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  <FormGroup>
                    <Input
                      style={{
                        boxShadow: " 0px 4px 4px 0px #00000040 ",
                        borderRadius: "6px",
                        width: "80%",
                      }}
                      className="form-control-alternative fontstylerentr titleecolor"
                      id="input-unitadd2"
                      placeholder="3000"
                      type="date"
                      name="end_date"
                      // onBlur={leaseFormik.handleBlur}
                      onChange={(e) => {
                        setRenewLeaseData((values) => ({
                          ...values,
                          end_date: e.target.value,
                        }));
                      }}
                      value={renewLeaseData?.end_date || "N/A"}
                      min={moment(renewLeaseData?.start_date)
                        .add(1, "days")
                        .format("YYYY-MM-DD")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col
                  className="textcolorblue fontfamilysty"
                  lg={4}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Rent
                </Col>
                <Col
                  className="textcolorblue fontfamilysty"
                  lg={4}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Security Deposit
                </Col>
              </Row>
              <Row>
                <Col
                  className="titleecolor fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  <FormGroup>
                    <Input
                      style={{
                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderRadius: "6px",
                      }}
                      className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                      id="input-reserve"
                      placeholder="Enter amount"
                      type="text"
                      autoComplete="off"
                      name="amount"
                      value={renewLeaseData?.amount}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, "");
                        const finalValue = numericValue.replace(
                          /(\..*?)\..*/g,
                          "$1"
                        );

                        setRenewLeaseData((values) => ({
                          ...values,
                          amount: finalValue,
                          renewAmount:
                            finalValue - leaseData?.amount > 0
                              ? finalValue - leaseData?.amount
                              : 0,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col
                  className="titleecolor fontfamilysty"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  lg={4}
                >
                  <FormGroup>
                    <Input
                      style={{
                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderRadius: "6px",
                      }}
                      className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
                      id="input-reserve"
                      placeholder="Enter amount"
                      type="text"
                      autoComplete="off"
                      name="amount"
                      value={renewLeaseData?.renewAmount}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericValue = inputValue.replace(/[^0-9.]/g, "");
                        const finalValue = numericValue.replace(
                          /(\..*?)\..*/g,
                          "$1"
                        );

                        setRenewLeaseData((values) => ({
                          ...values,
                          renewAmount: finalValue,
                        }));
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col
                  className="textcolorblue fontfamilysty"
                  lg={5}
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Upload file (Maximum 10)
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      className="form-control-file d-none"
                      accept="application/pdf"
                      name="upload_file"
                      id="upload_file"
                      multiple
                      onChange={handleFileChange}
                    />
                    <label
                      htmlFor="upload_file"
                      className=" mr-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                      style={{
                        borderRadius: "5px",
                        padding: "8px",
                        cursor: "pointer",
                      }}
                    >
                      Choose File
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div 
                  className="d-flex mr-3 mt-2"
                  style={{
                    width: "auto",
                    flexWrap: "wrap",
                  }}
                  >
                    {newFiles.map((file, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-2"
                        >
                          <p
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              marginRight: "10px",
                            }}
                            onClick={() => setFileModalOpen(file)}
                          >
                            {file && typeof file !== "string"
                              ? `${file.name?.slice(0, 5)}${
                                  file.name?.length > 5 ? "..." : ""
                                }`
                              : `${file.slice(0, 5)}${
                                  file.name.length > 5 ? "..." : ""
                                }`}
                          </p>
                          <ClearIcon
                            style={{
                              cursor: "pointer",
                              alignSelf: "flex-start",
                              position: "relative",
                              top: "-2px",
                            }}
                            onClick={() => removeFile(index)}
                          />
                        </div>
                      );
                    })}
                    {fileModalOpen && (
                      <FileModal
                        open={fileModalOpen}
                        setOpen={setFileModalOpen}
                        file={fileModalOpen}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Table bordered className="mt-3">
                {/* <table> */}
                <thead>
                  <tr
                    style={{
                      background: "none",
                      border: "0.5px solid #324567",
                    }}
                  >
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Account
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      className="fontstylerentr textcolorblue fontfamilysty"
                      style={{
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "500",
                        border: "0.5px solid #324567",
                      }}
                    >
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {chargeData.length > 0 &&
                    chargeData.map((item, index) => (
                      <tr style={{ background: "none" }} key={index}>
                        <td
                          className="fontstylerentmodal textcolorblue fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            border: "0.5px solid #324567",
                          }}
                        >
                          <Dropdown
                            className="fontstylerentmodal"
                            isOpen={accounttype === index}
                            toggle={() => toggle5(index)}
                          >
                            <DropdownToggle
                              className="fontstylerentmodal titleecolor"
                              caret
                              style={{
                                width: "230px",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                                backgroundColor: "transparent",
                              }}
                            >
                              {item.account ? item.account : "Select"}
                            </DropdownToggle>
                            <DropdownMenu
                              className="fontstylerentmodal"
                              style={{
                                zIndex: 999,
                                maxHeight: "200px",
                                overflowY: "auto",
                              }}
                            >
                              <DropdownItem
                                className="dropdownfontsyle"
                                onClick={() => {
                                  AddNewAccountName("One Time Charge");
                                  handleClose();
                                }}
                                style={{
                                  backgroundColor: "#152B51",
                                  color: "#fff",
                                }}
                              >
                                Add One time charge
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal textcolorblue fontfamilysty"
                                header
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                Liability Account
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => {
                                  handleChargeChange(
                                    "Late Fee Income",
                                    index,
                                    "Liability Account"
                                  );
                                }}
                              >
                                Late Fee Income
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => {
                                  handleChargeChange(
                                    "Pre-payments",
                                    index,
                                    "Liability Account"
                                  );
                                }}
                              >
                                Pre-payments
                              </DropdownItem>
                              {LiabilityAccounts?.length > 0 ? (
                                <>
                                  {LiabilityAccounts?.map((item) => (
                                    <DropdownItem
                                      key={item._id}
                                      onClick={() => {
                                        handleChargeChange(
                                          item.account,
                                          index,
                                          "Liability Account"
                                        );
                                      }}
                                    >
                                      {item.account}
                                    </DropdownItem>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                              {recAccounts?.length > 0 ? (
                                <>
                                  <DropdownItem
                                    className="fontstylerentmodal textcolorblue fontfamilysty"
                                    header
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Reccuring Charges
                                  </DropdownItem>
                                  {recAccounts?.map((item) => (
                                    <DropdownItem
                                      className="fontstylerentmodal"
                                      key={item._id}
                                      onClick={() => {
                                        handleChargeChange(
                                          item.account,
                                          index,
                                          "Recurring Charge"
                                        );
                                      }}
                                    >
                                      {item.account}
                                    </DropdownItem>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                              {oneTimeAccounts?.length > 0 ? (
                                <>
                                  <DropdownItem
                                    className="fontstylerentmodal textcolorblue fontfamilysty"
                                    header
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    One Time Charges
                                  </DropdownItem>
                                  {oneTimeAccounts?.map((item) => (
                                    <DropdownItem
                                      className="fontstylerentmodal"
                                      key={item._id}
                                      onClick={() => {
                                        handleChargeChange(
                                          item.account,
                                          index,
                                          "One Time Charge"
                                        );
                                      }}
                                    >
                                      {item.account}
                                    </DropdownItem>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </td>
                        <td
                          className="fontstylerentmodal textcolorblue fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            border: "0.5px solid #324567",
                          }}
                        >
                          <div className="col">
                            <Input
                              className="form-control-alternative fontstylerentmodal"
                              style={{
                                width: "80%",
                                fontWeight: "bold",
                              }}
                              name={`amount[${index}]`}
                              placeholder="0"
                              type="text"
                              onChange={(e) => {
                                if (/^\d*\.?\d*$/.test(e.target.value)) {
                                  handleChargeChange(e.target.value, index);
                                }
                              }}
                              value={item.amount}
                            />
                          </div>
                        </td>
                        <td
                          className="fontstylerentmodal textcolorblue fontfamilysty"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            border: "0.5px solid #324567",
                          }}
                        >
                          <ClearIcon
                            className="addpaymenticonremove"
                            type="button"
                            style={{
                              cursor: "pointer",
                              padding: 0,
                            }}
                            onClick={() => removeCharge(index)}
                          >
                            Remove
                          </ClearIcon>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* </table> */}
                <tfoot>
                  <tr>
                    <td
                      colSpan="3"
                      style={{
                        border: "1px solid #152B51",
                      }}
                    >
                      <Button
                        type="button"
                        className="btn fontstylerentmodal textcolorblue fontfamilysty backgroundwhitesty"
                        onClick={() => addNewCharge()}
                        style={{
                          border: "0.5px solid #152B51",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        Add Row
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <BaseButton
            onClick={handleRenewModalClose}
            disabled={sendLoader}
            variant="outline"
            label="Cancel"
          />
          <BaseButton
            onClick={handleRenewLease}
            loading={sendLoader}
            label="Ok"
          />
        </ModalFooter>
      </Modal>

      <AccountDialog
        addBankAccountDialogOpen={addBankAccountDialogOpen}
        setAddBankAccountDialogOpen={setAddBankAccountDialogOpen}
        accountTypeName={accountTypeName}
        adminId={accessType?.admin_id}
        fetchAccounts={fetchAccounts}
      />

      <EmailDialogModal
        open={emailModalOpen}
        handleCloseEmailModal={toggleEmailModal}
        emailData={selectedEmailData}
      />

      <Modal isOpen={openemailrecipients} toggle={handleClose}>
        <ModalHeader>
          <div
            className="labelfontstyle textcolorblue fontfamilysty d-flex"
            style={{
              fontSize: "18px",
              fontWeight: "500",
              gap: "10px",
            }}
          >
            <i className="fa-solid fa-user-group" />
            Email Recipients
          </div>
        </ModalHeader>
        <ModalBody style={{ width: "100%", maxWidth: "500px" }}>
          <div>
            <div
              style={{
                maxHeight: "400px",
                overflow: "hidden",
              }}
            >
              <Input
                className="fontstylerentr titleecolor"
                type="text"
                placeholder="Search recipients"
                value={searchbyQuery}
                onChange={(e) => setSearchByQuery(e.target.value)}
                style={{
                  marginBottom: "10px",
                  width: "100%",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontWeight: "600",
                }}
              />
              <div
                className="labelfontstyle textcolorblue fontfamilysty"
                style={{
                  padding: "15px",
                  border: "1px solid #ddd",
                  fontWeight: "500",
                }}
              >
                {filteredDatamodal()?.map((elem) => (
                  <div className="d-flex justify-content-between">
                    <div>{elem}</div>
                    {emailmodal.accepted.includes(elem) &&
                    emailmodal.opens.some((item) => item.opened_by === elem) ? (
                      <div>
                        <DoneAllIcon color="success" />
                      </div>
                    ) : emailmodal.accepted.includes(elem) ? (
                      <div>
                        <CheckIcon color="success" />
                      </div>
                    ) : (
                      <div>
                        <CloseIcon color="error" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <br />
            </div>
            <br />
            <div
              className="ml-2 labelfontstyle textcolorblue fontfamilysty"
              style={{ fontWeight: "500" }}
            >
              Total Recipients : {emailmodal?.to?.length}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Container
        className="containerremovespace stylecontainer"
        fluid
        style={{ marginTop: "2rem" }}
      >
        <Row className="nameaddressrentroll">
          <Col className="text-left">
            <FormGroup className=" align-item-left text-left ml-4 addresstopspacerent">
              <h1
                className="text-left labelfontstyle textcolorblue fontfamilysty"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                {leaseData?.rental_adress ? leaseData?.rental_adress : " "}
                {leaseData?.rental_unit &&
                leaseData?.rental_unit !== undefined &&
                leaseData?.rental_unit !== ""
                  ? ` - ${leaseData?.rental_unit}`
                  : ""}
              </h1>
              <h4
                className="fontstylerentr titleecolor fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {getStatus(leaseData?.start_date, leaseData?.end_date)}
                {leaseData?.renewLeases &&
                  leaseData?.renewLeases?.length > 0 &&
                  "- Renewed"}
              </h4>
            </FormGroup>
          </Col>
          <Col className="text-right">
            <Button
              className="mr-2 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={() => {
                navigate(admin ? `/${admin}/send-email` : `/staff/send-email`, {
                  state: {
                    selectedTenants: leaseData?.tenant_data?.map(
                      (item) => item?.tenant_id
                    ),
                    lease_id: leaseData.lease_id,
                  },
                });
              }}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "4px",
                border: "0",
              }}
            >
              Send Mail
            </Button>
            {(admin || permissions?.lease_edit) && (
              <Button
                className="mr-2 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                onClick={() =>
                  navigate(
                    admin
                      ? `/${admin}/RentRollLeaseing/${lease_id}`
                      : `/staff/staffaddrentroll/${lease_id}`,
                    {
                      state: { lease_id: lease_id },
                    }
                  )
                }
                size="small"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "4px",
                  border: "0",
                }}
              >
                Edit
              </Button>
            )}
            <Button
              className="mr-4 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={() => handlebackclick()}
              size="small"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 4px 0px #00000040",
                borderRadius: "4px",
                border: "0",
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Col xs="12" lg="12" sm="12">
              <TabContext value={value}>
                <CardHeader
                  className="mt-3 headersummarybar"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                    border: "0.5px solid #324567",
                  }}
                >
                  <Box sx={{}}>
                    {/* MARK: TabList */}
                    <TabList
                      className="tablistsummary"
                      style={{ border: "none" }}
                      onChange={(e, newValue) => handleChange(newValue)}
                      aria-label="lab API tabs example"
                      value={value}
                    >
                      <Tab
                        className="tabsummarybar barforsummaryrent textcolorblue fontfamilysty"
                        label="Summary"
                        value="Summary"
                        style={{
                          fontWeight: "500",
                          textTransform: "none",

                          fontSize: "18px",
                        }}
                      />
                      <Tab
                        className="tabsummarybar barforsummaryrent text-nowrap textcolorblue fontfamilysty"
                        label="Financial"
                        value="Financial"
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          textTransform: "none",
                        }}
                      />
                      <Tab
                        className="tabsummarybar barforsummaryrent text-nowrap textcolorblue fontfamilysty"
                        label="Tenants"
                        value="Tenant"
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          textTransform: "none",
                        }}
                      />
                      <Tab
                        className="tabsummarybar barforsummaryrent text-nowrap textcolorblue fontfamilysty"
                        label="Communication"
                        value="Communication"
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          textTransform: "none",
                        }}
                      />
                      <Tab
                        className="tabsummarybar barforsummaryrent text-nowrap textcolorblue fontfamilysty"
                        label="Renter's Insurance"
                        value="rentersInsurance"
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          textTransform: "none",
                        }}
                      />
                      <Tab
                        className="tabsummarybar barforsummaryrent text-nowrap textcolorblue fontfamilysty"
                        label="Documents"
                        value="document"
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          textTransform: "none",
                        }}
                      />
                    </TabList>
                  </Box>
                </CardHeader>
                {/* MARK: TabPanel */}
                <TabPanel value="Summary" className="mx-0 px-0 mt-2">
                  <Row>
                    {getLeaseDetailsLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "20px",
                          width: "100%",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : getLeaseDetailsError ? (
                      <p
                        style={{
                          textAlign: "center",
                          color: "#ff0000",
                          fontWeight: "normal",
                          width: "100%",
                          padding: "20px",
                        }}
                      >
                        {getLeaseDetailsError}
                      </p>
                    ) : (
                      <div className="col">
                        <div
                          className="table-responsive"
                          style={{ scrollbarWidth: "none" }}
                        >
                          <div className="row">
                            <Col
                              className="cardmarginrentroll"
                              lg={
                                getStatus(
                                  leaseData?.start_date,
                                  leaseData?.end_date
                                ) === "EXPIRED" ||
                                getStatus(
                                  leaseData?.start_date,
                                  leaseData?.end_date
                                ) === "FUTURE"
                                  ? "12"
                                  : "8"
                              }
                              xl={
                                getStatus(
                                  leaseData?.start_date,
                                  leaseData?.end_date
                                ) === "EXPIRED" ||
                                getStatus(
                                  leaseData?.start_date,
                                  leaseData?.end_date
                                ) === "FUTURE"
                                  ? "12"
                                  : "8"
                              }
                              md={
                                getStatus(
                                  leaseData?.start_date,
                                  leaseData?.end_date
                                ) === "EXPIRED" ||
                                getStatus(
                                  leaseData?.start_date,
                                  leaseData?.end_date
                                ) === "FUTURE"
                                  ? "12"
                                  : "8"
                              }
                              sm="12"
                            >
                              <div
                                className="align-items-start table-flush"
                                style={{ width: "100%" }}
                              >
                                <Card
                                  className="w-100 "
                                  style={{
                                    border: "1px solid #324567",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    padding: "15px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <Row
                                    className="w-100 my-3 labelfontstyle textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <Col>Tenant Details</Col>
                                  </Row>
                                  <Row className="w-100 mb-1">
                                    <Col
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Unit
                                    </Col>
                                    <Col
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Rental Owner
                                    </Col>
                                    <Col
                                      className="fontstylerentr titleecolor fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Tenant
                                    </Col>
                                  </Row>
                                  <Row className="w-100 mt-1 mb-5">
                                    <Col
                                      className="fontstylerentr fontfamilysty textcolorblue"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {leaseData?.rental_adress
                                        ? leaseData?.rental_adress
                                        : " "}
                                      {leaseData?.rental_unit &&
                                      leaseData?.rental_unit !== undefined &&
                                      leaseData?.rental_unit !== ""
                                        ? ` - ${leaseData?.rental_unit}`
                                        : ""}
                                    </Col>
                                    <Col
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {leaseData?.rentalOwner_name || "N/A"}
                                    </Col>

                                    <Col
                                      className="fontstylerentr textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {leaseData?.tenant_data &&
                                      leaseData?.tenant_data.length > 0
                                        ? leaseData?.tenant_data.map(
                                            (tenant, index) => (
                                              <div
                                                key={index}
                                                className="fontstylerentr fontfamilysty textcolorblue"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {tenant?.tenant_firstName +
                                                  " " +
                                                  tenant?.tenant_lastName ||
                                                  "N/A"}
                                              </div>
                                            )
                                          )
                                        : ""}
                                    </Col>
                                  </Row>
                                </Card>
                              </div>
                            </Col>
                            {status === "Active" && (
                              <Col lg="4" md="4" sm="12">
                                <Card
                                  style={{
                                    background: "white",
                                    border: "1px solid #324567",
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <CardContent>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Typography
                                        className="fontstylerentmodal textcolorblue fontfamilysty"
                                        sx={{
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                        gutterBottom
                                      >
                                        Balance:
                                      </Typography>
                                      <Typography
                                        className="fontstylerentmodal textcolorblue fontfamilysty"
                                        sx={{
                                          marginLeft: "10px",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          color: "#737791",
                                        }}
                                      >
                                        {totalAmount
                                          ? totalAmount < 0
                                            ? `$(${Math.abs(
                                                totalAmount?.toFixed(2)
                                              )})`
                                            : `$${totalAmount?.toFixed(2)}`
                                          : "$ 0.00"}
                                      </Typography>
                                    </div>
                                    <>
                                      <div>
                                        <div className="entry-container">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <Typography
                                              className="fontstylerentmodal textcolorblue fontfamilysty"
                                              sx={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                              }}
                                              gutterBottom
                                            >
                                              Rent:
                                            </Typography>
                                            <Typography
                                              className="fontstylerentmodal fontfamilysty"
                                              sx={{
                                                marginLeft: "10px",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                color: "#737791",
                                              }}
                                              gutterBottom
                                            >
                                              ${leaseData?.amount}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <Typography
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "500",
                                            }}
                                            gutterBottom
                                          >
                                            Due date:
                                          </Typography>
                                          <Typography
                                            className="fontstylerentmodal fontfamilysty"
                                            sx={{
                                              marginLeft: "10px",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              color: "#737791",
                                            }}
                                            gutterBottom
                                          >
                                            {moment(leaseData?.date).format(
                                              dateFormat
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </>

                                    <div
                                      className="btnreceiverent"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <Button
                                        className="fontstylerentmodal textcolorblue fontfamilysty backgroundwhitesty"
                                        onClick={() =>
                                          navigate(
                                            admin
                                              ? `/${admin}/AddPayment/${lease_id}`
                                              : `/staff/staffaddpayment/${lease_id}`
                                          )
                                        }
                                        style={{
                                          border: "1px solid #152B51",
                                          marginRight: "10px",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Make Payment
                                      </Button>
                                      <Button
                                        className="fontstylerentmodal textcolorblue fontfamilysty backgroundwhitesty"
                                        onClick={() => cardToggle()}
                                        style={{
                                          border: "1px solid #152B51",
                                          marginRight: "10px",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Configure Recurring Payment{" "}
                                        {tenantDetails?.some(
                                          (item) => item?.recurring
                                        ) ? (
                                          <TaskAltIcon color="success" />
                                        ) : (
                                          ""
                                        )}
                                      </Button>
                                    </div>
                                  </CardContent>
                                </Card>
                              </Col>
                            )}
                          </div>

                          <Row>
                            <Col style={{}}>
                              <Card
                                className="mt-4"
                                style={{
                                  padding: "15px",
                                  border: "1px solid #324567",
                                  boxShadow: "0px 4px 4px 0px #00000040",
                                  borderRadius: "10px",
                                }}
                              >
                                <Row
                                  className="my-3 labelfontstyle textcolorblue fontfamilysty"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                  }}
                                >
                                  <Col>Lease Details</Col>
                                  {(admin || permissions?.lease_edit) &&
                                    leaseData?.is_renewing && (
                                      <Col className="d-flex">
                                        <Button
                                          className="ml-auto fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                                          onClick={handleClickOpen}
                                          size="small"
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            boxShadow:
                                              "0px 4px 4px 0px #00000040",
                                            borderRadius: "4px",
                                            border: "0",
                                          }}
                                        >
                                          Renew lease
                                        </Button>
                                      </Col>
                                    )}
                                </Row>
                                {/* end */}
                                <Row
                                  className="mb-1 m-0 p-0"
                                  style={{
                                    fontSize: "12px",
                                    color: "#000",
                                    overflowX: "auto",
                                    width: "100%",
                                  }}
                                >
                                  <Table>
                                    <tbody
                                      className="tbbody p-0 m-0"
                                      style={{
                                        borderTopRightRadius: "5px",
                                        borderTopLeftRadius: "5px",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                      }}
                                    >
                                      <tr
                                        className=""
                                        style={{
                                          background: "none",
                                          border: "0.5px solid #324567",
                                        }}
                                      >
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Status
                                        </th>
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Start - End
                                        </th>
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Property
                                        </th>
                                        <th
                                          className="fontstylerentr fontfamilysty textcolorblue"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          className="fontstylerentr textcolorblue fontfamilysty"
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            border: "0.5px solid #324567",
                                          }}
                                        >
                                          Rent
                                        </th>
                                      </tr>
                                      {leaseData ? (
                                        <>
                                          <tr
                                            key={leaseData?.lease_id}
                                            className="body"
                                          >
                                            <td
                                              className="fontstylerentmodal fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              {getStatus(
                                                leaseData?.start_date,
                                                leaseData?.end_date
                                              )}
                                            </td>
                                            <td
                                              className="fontstylerentmodal titleecolor fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              {leaseData?.start_date
                                                ? moment(
                                                    leaseData?.start_date
                                                  ).format(dateFormat) +
                                                  " To " +
                                                  moment(
                                                    leaseData?.end_date
                                                  ).format(dateFormat)
                                                : "N/A"}
                                            </td>
                                            <td
                                              className="fontstylerentmodal titleecolor fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              {leaseData?.rental_adress ||
                                                "N/A"}
                                            </td>
                                            <td
                                              className="fontstylerentmodal titleecolor fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              {leaseData?.lease_type || "N/A"}
                                            </td>
                                            <td
                                              className="fontstylerentmodal titleecolor fontfamilysty"
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              {leaseData?.amount}
                                            </td>
                                          </tr>
                                        </>
                                      ) : null}
                                    </tbody>
                                  </Table>
                                </Row>
                              </Card>
                            </Col>
                          </Row>
                          {leaseData?.renewLeases &&
                            leaseData?.renewLeases?.length > 0 && (
                              <Row>
                                <Col>
                                  <Card
                                    className="mt-4"
                                    style={{
                                      padding: "15px",
                                      border: "1px solid #324567",
                                      boxShadow: "0px 4px 4px 0px #00000040",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <Row
                                      className="w-100 my-3 labelfontstyle textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <Col>Renewable History</Col>
                                    </Row>
                                    <Row
                                      className="mb-1 m-0 p-0"
                                      style={{
                                        fontSize: "12px",
                                        color: "#000",
                                        overflowX: "auto",
                                        width: "100%",
                                      }}
                                    >
                                      <Table>
                                        <tbody
                                          className="tbbody p-0 m-0"
                                          style={{
                                            borderTopRightRadius: "5px",
                                            borderTopLeftRadius: "5px",
                                            borderBottomLeftRadius: "5px",
                                            borderBottomRightRadius: "5px",
                                          }}
                                        >
                                          <tr
                                            className=""
                                            style={{
                                              background: "none",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            <th
                                              className="fontstylerentr textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              Status
                                            </th>
                                            <th
                                              className="fontstylerentr textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              Start - End
                                            </th>
                                            <th
                                              className="fontstylerentr textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              Property
                                            </th>
                                            <th
                                              className="fontstylerentr textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              Type
                                            </th>
                                            <th
                                              className="fontstylerentr textcolorblue fontfamilysty"
                                              style={{
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                border: "0.5px solid #324567",
                                              }}
                                            >
                                              Rent
                                            </th>
                                          </tr>
                                          {leaseData?.renewLeases &&
                                          leaseData?.renewLeases?.length > 0
                                            ? leaseData?.renewLeases.map(
                                                (item, index) => (
                                                  <tr
                                                    key={index}
                                                    className="body"
                                                  >
                                                    <td
                                                      className="fontstylerentmodal fontfamilysty"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        border:
                                                          "0.5px solid #324567",
                                                      }}
                                                    >
                                                      {getRenewstatus(
                                                        item?.start_date,
                                                        item?.end_date,
                                                        item?.is_renewed
                                                      )}
                                                    </td>
                                                    <td
                                                      className="fontstylerentmodal titleecolor fontfamilysty"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        border:
                                                          "0.5px solid #324567",
                                                      }}
                                                    >
                                                      {item?.start_date
                                                        ? moment(
                                                            item?.start_date
                                                          ).format(dateFormat) +
                                                          " To " +
                                                          moment(
                                                            item?.end_date
                                                          ).format(dateFormat)
                                                        : "N/A"}
                                                    </td>
                                                    <td
                                                      className="fontstylerentmodal titleecolor fontfamilysty"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        border:
                                                          "0.5px solid #324567",
                                                      }}
                                                    >
                                                      {leaseData?.rental_adress ||
                                                        "N/A"}
                                                    </td>
                                                    <td
                                                      className="fontstylerentmodal titleecolor fontfamilysty"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        border:
                                                          "0.5px solid #324567",
                                                      }}
                                                    >
                                                      {item?.lease_type ||
                                                        "N/A"}
                                                    </td>
                                                    <td
                                                      className="fontstylerentmodal titleecolor fontfamilysty"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        border:
                                                          "0.5px solid #324567",
                                                      }}
                                                    >
                                                      {item?.amount}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            : null}
                                        </tbody>
                                      </Table>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                            )}
                        </div>
                        {/* </Card> */}
                      </div>
                    )}
                  </Row>
                </TabPanel>

                <TabPanel value="Financial" className="p-0">
                  <div className="mt--10" fluid style={{ width: "100%" }}>
                    {(status === "Active" || status === "EXPIRED") && (
                      <Row className="justify-content-end">
                        <Col
                          className="d-flex justify-content-end mt-4"
                          xs="12"
                          sm="6"
                        >
                          {currentPlan !== "Free Plan" &&
                            status === "Active" && (
                              <>
                                {scriptGenerating ? (
                                  <p>NMI fields loading...</p>
                                ) : scriptError ? (
                                  <p style={{ color: "red" }}>{scriptError}</p>
                                ) : (
                                  <Button
                                    className="fontstylerentr btnsizerent textcolorblue fontfamilysty backgroundwhitesty"
                                    onClick={() => checkKey2()}
                                    style={{
                                      marginRight: "10px",
                                      fontSize: "18px",
                                      fontWeight: "500",
                                      border: "0.5px solid #152B51",
                                      boxShadow: "0px 4px 4px 0px #00000040",
                                    }}
                                  >
                                    Add Cards
                                  </Button>
                                )}
                              </>
                            )}

                          <Button
                            className="fontstylerentr  btnrecieverents  textcolorblue fontfamilysty backgroundwhitesty"
                            // color="primary"
                            onClick={() => {
                              navigate(
                                admin
                                  ? `/${admin}/AddPayment/${lease_id}`
                                  : `/staff/staffaddpayment/${lease_id}`,
                                { state: { tab: "Financial" } }
                              );
                            }}
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              border: "0.5px solid #152B51",
                              boxShadow: "0px 4px 4px 0px #00000040",
                            }}
                          >
                            Make Payment
                          </Button>
                          {status === "Active" && (
                            <Button
                              className="fontstylerentr btnchargerent  textcolorblue fontfamilysty backgroundwhitesty"
                              // color="primary"
                              onClick={() =>
                                navigate(
                                  admin
                                    ? `/${admin}/AddCharge/${lease_id}`
                                    : `/staff/staffaddcharge/${lease_id}`
                                )
                              }
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                border: "0.5px solid #152B51",
                                boxShadow: "0px 4px 4px 0px #00000040",
                              }}
                            >
                              Enter Charge
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                    <br />
                    <Row
                      className="mr-1"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    ></Row>
                    <br />
                    <div className="">
                      {loader ? (
                        <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                          <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="50"
                            visible={loader}
                          />
                        </div>
                      ) : (
                        <Card
                          className=""
                          style={{
                            border: "1px solid #324567",
                            boxShadow: "0px 4px 4px 0px #00000040",
                          }}
                        >
                          <CardHeader
                            className="border-0 labelfontstyle textcolorblue fontfamilysty"
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            <Row>
                              <Col xs="12" sm="12" md="3" lg="3" xl="2">
                                <Label
                                  className="labelfontstyle textcolorblue"
                                  style={{
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                  }}
                                  htmlFor="fromDate"
                                >
                                  Search
                                </Label>
                                <Input
                                  className="filterh"
                                  // fullWidth
                                  type="text"
                                  placeholder="Search here..."
                                  value={searchQuery}
                                  onChange={handleSearch}
                                  style={{
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    border: "1px solid #ced4da",
                                  }}
                                />
                              </Col>
                              <Col
                                xs="6"
                                sm="3"
                                md="3"
                                lg="3"
                                xl="2"
                                className="rentrolldetaildate"
                              >
                                <Label
                                  className="labelfontstyle textcolorblue"
                                  style={{
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                  }}
                                  htmlFor="fromDate"
                                >
                                  From
                                </Label>
                                <Input
                                  style={{
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "6px",
                                  }}
                                  className="form-control-alternative fontstylerentmodal"
                                  type="date"
                                  name="fromDate"
                                  id="fromDate"
                                  value={fromDate}
                                  onChange={(e) => setFromDate(e.target.value)}
                                  max={
                                    moment(toDate).format("YYYY-MM-DD") || null
                                  }
                                />
                              </Col>
                              <Col
                                xs="6"
                                sm="3"
                                md="3"
                                lg="3"
                                xl="2"
                                className="rentrolldetaildate"
                              >
                                <Label
                                  className="labelfontstyle textcolorblue"
                                  style={{
                                    fontSize: "18px",
                                    textTransform: "capitalize",
                                    fontWeight: "600",
                                  }}
                                  htmlFor="toDate"
                                >
                                  To
                                </Label>
                                <Input
                                  style={{
                                    boxShadow: "0px 4px 4px 0px #00000040",
                                    borderRadius: "6px",
                                  }}
                                  className="form-control-alternative fontstylerentmodal"
                                  type="date"
                                  name="toDate"
                                  id="toDate"
                                  value={toDate}
                                  onChange={(e) => setToDate(e.target.value)}
                                  min={
                                    moment(fromDate)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD") || null
                                  }
                                />
                              </Col>
                              <Col
                                className="mr-1 mt-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                  <DropdownToggle
                                    className="background-colorsty bgtextwhite fontfamilysty"
                                    caret
                                    style={{
                                      marginBottom: "12px",
                                      fontSize: "16px",
                                      border: "1px solid #152B5180",
                                      boxShadow: "0px 4px 4px 0px #00000040",
                                      fontWeight: "500",
                                    }}
                                  >
                                    Export
                                  </DropdownToggle>
                                  <DropdownMenu style={{ minWidth: "120px" }}>
                                    <DropdownItem
                                      className="textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => handleOptionClick("PDF")}
                                    >
                                      PDF
                                    </DropdownItem>
                                    <DropdownItem
                                      className="textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => handleOptionClick("XLSX")}
                                    >
                                      XLSX
                                    </DropdownItem>
                                    <DropdownItem
                                      className="textcolorblue fontfamilysty"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                      onClick={() => handleOptionClick("CSV")}
                                    >
                                      CSV
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            </Row>
                          </CardHeader>

                          <br />
                          <div className="px-4 mb-4">
                            <Table
                              className="align-items-center table-flush"
                              responsive
                              style={{ width: "100%" }}
                            >
                              <thead
                                className=""
                                style={{
                                  background: "none",
                                  border: "0.5px solid #324567",
                                }}
                              >
                                <tr>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Date
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Tenant
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Type
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Description
                                  </th>
                                  <th
                                    className="fontstylerentr text-right textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Amount
                                  </th>
                                  <th
                                    className="fontstylerentr text-right textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Balance
                                  </th>
                                  <th
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      border: "0.5px solid #324567",
                                    }}
                                    scope="col"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filterTenantsBySearchAndPage()?.length > 0 ? (
                                  filterTenantsBySearchAndPage().map(
                                    (generalledger, index) => (
                                      <>
                                        <tr
                                          key={`${
                                            generalledger?.payment_id ||
                                            generalledger?.charge_id
                                          }`}
                                          onClick={() => {
                                            if (
                                              generalledger?.entry?.length >=
                                                1 &&
                                              generalledger?.type !== "Refund"
                                            ) {
                                              openAccount(generalledger, index);
                                            }
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            transition:
                                              "background-color 0.3s ease",
                                          }}
                                          onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "#f0f0f0")
                                          }
                                          onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor =
                                              "transparent")
                                          }
                                        >
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {generalledger?.entry[0]?.date
                                              ? moment(
                                                  generalledger?.entry[0]?.date
                                                ).format(dateFormat)
                                              : "-"}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {generalledger?.tenantData
                                              ? `${
                                                  generalledger?.tenantData
                                                    ?.tenant_firstName || ""
                                                } ${
                                                  generalledger?.tenantData
                                                    ?.tenant_lastName || ""
                                                }`
                                              : generalledger?.type ===
                                                "Payment"
                                              ? "External Source"
                                              : "-"}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {generalledger?.type || "-"}
                                          </td>
                                          <td
                                            className="fontstylerentmodal fontfamilysty"
                                            style={{
                                              color:
                                                generalledger.type ===
                                                  "Payment" &&
                                                generalledger.response ===
                                                  "SUCCESS"
                                                  ? "#50975E"
                                                  : generalledger.type ===
                                                      "Refund" &&
                                                    generalledger.response ===
                                                      "SUCCESS"
                                                  ? "#ffc40c"
                                                  : generalledger.response ===
                                                    "FAILURE"
                                                  ? "#AA3322"
                                                  : "inherit",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            <React.Fragment>
                                              {generalledger.response &&
                                              generalledger.payment_type
                                                ? `Manual ${generalledger.type} ${generalledger.response} for ${generalledger.payment_type}`
                                                : generalledger?.entry.map(
                                                    (item, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        {item.memo}
                                                        {(index + 1) % 3 ===
                                                        0 ? (
                                                          <>
                                                            <br />
                                                          </>
                                                        ) : index <
                                                          generalledger.entry
                                                            .length -
                                                            1 ? (
                                                          ", "
                                                        ) : (
                                                          ""
                                                        )}
                                                      </React.Fragment>
                                                    )
                                                  ) || "-"}

                                              {(generalledger.transaction_id ||
                                                generalledger.check_number ||
                                                generalledger.reference) && (
                                                <br />
                                              )}

                                              {generalledger.transaction_id &&
                                                ` (#${generalledger.transaction_id})`}
                                              {generalledger.check_number &&
                                                ` (#${generalledger.check_number})`}
                                              {generalledger.reference &&
                                                ` (#reference: ${generalledger.reference})`}

                                              {generalledger.externalPostingId && (
                                                <>
                                                  <br />
                                                  (Imported Transaction Id #
                                                  {
                                                    generalledger.externalPostingId
                                                  }
                                                  )
                                                </>
                                              )}
                                            </React.Fragment>
                                          </td>
                                          <td
                                            className="fontstylerentmodal text-right textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {generalledger.type === "Charge" ||
                                            generalledger.type === "Refund"
                                              ? "$" +
                                                Number(
                                                  generalledger.total_amount ||
                                                    0
                                                ).toFixed(2)
                                              : generalledger.type === "Payment"
                                              ? "-$" +
                                                Number(
                                                  generalledger.total_amount ||
                                                    0
                                                ).toFixed(2)
                                              : ""}
                                          </td>
                                          <td
                                            className="fontstylerentmodal text-right textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            {Math.abs(generalledger.balance) <
                                            1e-10
                                              ? "$0.00"
                                              : generalledger.balance !==
                                                undefined
                                              ? generalledger.balance >= 0
                                                ? `$${Number(
                                                    generalledger?.balance || 0
                                                  ).toFixed(2)}`
                                                : `-$${Number(
                                                    Math.abs(
                                                      generalledger?.balance ||
                                                        0
                                                    )
                                                  ).toFixed(2)}`
                                              : "$0.00"}
                                          </td>
                                          <td
                                            className="fontstylerentmodal textcolorblue fontfamilysty"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              border: "0.5px solid #324567",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                marginLeft: "15px",
                                              }}
                                            >
                                              {/* 6002 */}
                                              {generalledger?.response !==
                                                "FAILURE" &&
                                              generalledger?.type !==
                                                "Refund" ? (
                                                <UncontrolledDropdown nav>
                                                  <DropdownToggle
                                                    className="pr-0"
                                                    nav
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      toggleOptions(
                                                        generalledger?.payment_id
                                                      );
                                                    }}
                                                  >
                                                    <span
                                                      className="avatar avatar-sm rounded-circle fontstylerentmodal"
                                                      style={{
                                                        margin: "-20px",
                                                        background:
                                                          "transparent",
                                                        color: "lightblue",
                                                        fontWeight: "bold",
                                                        border:
                                                          "2px solid lightblue",
                                                        padding: "10px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "center",
                                                      }}
                                                    >
                                                      ...
                                                    </span>
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-menu-arrow">
                                                    {generalledger?.payment_id ===
                                                      showOptionsId && (
                                                      <div>
                                                        {generalledger?.response ===
                                                          "SUCCESS" && (
                                                          <DropdownItem
                                                            className="fontstylerentmodal"
                                                            onClick={async () => {
                                                              if (
                                                                generalledger.payment_type ===
                                                                  "Card" ||
                                                                generalledger.payment_type ===
                                                                  "ACH"
                                                              ) {
                                                                fetchData(
                                                                  generalledger.payment_id
                                                                );
                                                                setIsRefundOpen(
                                                                  true
                                                                );
                                                                setRefund(true);
                                                              } else {
                                                                debugger;
                                                                swal({
                                                                  title:
                                                                    "Are you sure?",
                                                                  text: "Once deleted, you will not be able to recover this payment history!",
                                                                  icon: "warning",
                                                                  content: {
                                                                    element:
                                                                      "input",
                                                                    attributes:
                                                                      {
                                                                        placeholder:
                                                                          "Enter reason for deletion",
                                                                        type: "text",
                                                                        id: "delete-reason",
                                                                        oninput:
                                                                          (
                                                                            e
                                                                          ) => {
                                                                            const reason =
                                                                              e
                                                                                .target
                                                                                .value;

                                                                            const deleteButton =
                                                                              document.querySelector(
                                                                                ".swal-button--confirm"
                                                                              );
                                                                            deleteButton.disabled =
                                                                              reason.trim() ===
                                                                              "";
                                                                          },
                                                                      },
                                                                  },
                                                                  buttons: {
                                                                    confirm: {
                                                                      text: "Delete",
                                                                      closeModal: true,
                                                                      value: true,
                                                                      className:
                                                                        "swal-button--danger",
                                                                    },
                                                                    cancel:
                                                                      "Cancel",
                                                                  },
                                                                  dangerMode: true,
                                                                }).then(
                                                                  async (
                                                                    willDelete
                                                                  ) => {
                                                                    if (
                                                                      willDelete
                                                                    ) {
                                                                      const deletionReason =
                                                                        document.getElementById(
                                                                          "delete-reason"
                                                                        ).value;

                                                                      try {
                                                                        const res =
                                                                          await axiosInstance.delete(
                                                                            `/payment/payment/${generalledger.payment_id}`,
                                                                            {
                                                                              data: {
                                                                                reason:
                                                                                  deletionReason,
                                                                              },
                                                                            }
                                                                          );

                                                                        if (
                                                                          res.status ===
                                                                          200
                                                                        ) {
                                                                          toast.success(
                                                                            res
                                                                              .data
                                                                              .message,
                                                                            {
                                                                              position:
                                                                                "top-center",
                                                                              autoClose: 1000,
                                                                            }
                                                                          );
                                                                          fetchfinancialData();
                                                                        } else {
                                                                          toast.warning(
                                                                            res
                                                                              .data
                                                                              .message,
                                                                            {
                                                                              position:
                                                                                "top-center",
                                                                              autoClose: 1000,
                                                                            }
                                                                          );
                                                                        }
                                                                      } catch (error) {
                                                                        console.error(
                                                                          "Error deleting payment history:",
                                                                          error
                                                                        );
                                                                        toast.error(
                                                                          "Error deleting payment history",
                                                                          {
                                                                            position:
                                                                              "top-center",
                                                                          }
                                                                        );
                                                                      }
                                                                    } else {
                                                                      toast.success(
                                                                        "Payment history is safe :)",
                                                                        {
                                                                          position:
                                                                            "top-center",
                                                                          autoClose: 1000,
                                                                        }
                                                                      );
                                                                    }
                                                                  }
                                                                );

                                                                setTimeout(
                                                                  () => {
                                                                    const deleteButton =
                                                                      document.querySelector(
                                                                        ".swal-button--confirm"
                                                                      );
                                                                    if (
                                                                      deleteButton
                                                                    ) {
                                                                      deleteButton.disabled = true;
                                                                    }
                                                                  },
                                                                  0
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            {generalledger.payment_type ===
                                                              "Card" ||
                                                            generalledger.payment_type ===
                                                              "ACH"
                                                              ? "Refund"
                                                              : "Delete"}
                                                          </DropdownItem>
                                                        )}
                                                        {(generalledger?.type ===
                                                          "Payment" ||
                                                          generalledger?.type ===
                                                            "Charge") && (
                                                          <DropdownItem
                                                            className="fontstylerentmodal"
                                                            tag="div"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              if (
                                                                generalledger?.type ===
                                                                "Charge"
                                                              ) {
                                                                navigate(
                                                                  admin
                                                                    ? `/${admin}/AddCharge/${lease_id}/${generalledger.charge_id}`
                                                                    : `/staff/staffaddcharge/${lease_id}/${generalledger.charge_id}`
                                                                );
                                                              } else {
                                                                navigate(
                                                                  admin
                                                                    ? `/${admin}/AddPayment/${lease_id}/${generalledger.payment_id}`
                                                                    : `/staff/staffaddpayment/${lease_id}/${generalledger.payment_id}`
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            Edit
                                                          </DropdownItem>
                                                        )}
                                                        {generalledger?.type ===
                                                          "Charge" && (
                                                          <DropdownItem
                                                            className="fontstylerentmodal"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              deleteTenant(
                                                                generalledger.charge_id
                                                              );
                                                            }}
                                                          >
                                                            Delete
                                                          </DropdownItem>
                                                        )}
                                                      </div>
                                                    )}
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              ) : (
                                                <div
                                                  className="fontstylerentmodal"
                                                  style={{
                                                    fontSize: "15px",
                                                    fontWeight: "bolder",
                                                    paddingLeft: "5px",
                                                  }}
                                                >
                                                  --
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                        {expandedRows.includes(index) && (
                                          <tr
                                            style={{
                                              border: "0",
                                              backgroundColor: "#f6f9fc",
                                            }}
                                            key={`expanded_${index}`}
                                          >
                                            <td
                                              scope="col"
                                              style={{ border: "0" }}
                                              colSpan="2"
                                            ></td>
                                            <td
                                              scope="col"
                                              style={{ border: "0" }}
                                              colSpan="2"
                                              className="text-left"
                                            >
                                              <b className="fontstylerentmodal">
                                                Accounts
                                              </b>
                                              <br />
                                              {expandedData[index].entry.map(
                                                (item, subIndex) => (
                                                  <span
                                                    className="fontstylerentmodal"
                                                    key={`expanded_${index}_${subIndex}`}
                                                  >
                                                    {item?.account}
                                                    <br />
                                                  </span>
                                                )
                                              )}
                                            </td>
                                            <td
                                              className="fontstylerentmodal text-right"
                                              scope="col"
                                              style={{ border: "0" }}
                                            >
                                              {financialData[index]?.type ===
                                                "Charge" ||
                                              financialData[index]?.type ===
                                                "Refund" ||
                                              financialData[index]?.type ===
                                                "Payment" ? (
                                                <>
                                                  <b className="fontstylerentmodal">
                                                    Amount
                                                  </b>
                                                  <br />
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {expandedData[index].entry.map(
                                                (data, subIndex) => (
                                                  <React.Fragment
                                                    key={subIndex}
                                                  >
                                                    {(financialData[index]
                                                      ?.type === "Charge" ||
                                                      financialData[index]
                                                        ?.type ===
                                                        "Refund") && (
                                                      <>
                                                        {"$" +
                                                          Number(
                                                            data?.amount
                                                          ).toFixed(2)}
                                                        <br />
                                                      </>
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )}
                                              {expandedData[index].entry.map(
                                                (data, subIndex) => (
                                                  <React.Fragment
                                                    key={subIndex}
                                                  >
                                                    <span className="fontstylerentmodal">
                                                      {financialData[index]
                                                        ?.type === "Payment"
                                                        ? "$" +
                                                          Number(
                                                            data?.amount
                                                          ).toFixed(2)
                                                        : ""}
                                                    </span>
                                                    {financialData[index]
                                                      ?.type === "Payment" && (
                                                      <br />
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )}
                                            </td>
                                            <td
                                              // eslint-disable-next-line jsx-a11y/scope
                                              scope="col"
                                              style={{ border: "0" }}
                                            ></td>
                                            <td></td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      className="textcolorblue "
                                      colSpan="10"
                                      style={{
                                        textAlign: "center",
                                        border: "0.5px solid #324567",
                                        fontSize: "16px",
                                        fontFamily: "600",
                                      }}
                                    >
                                      No records found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                            {paginatedData?.length > 0 ? (
                              <Row>
                                <Col className="text-right mt-3 mb-3">
                                  <Dropdown
                                    isOpen={leasedropdownOpen}
                                    toggle={toggle2}
                                  >
                                    <DropdownToggle
                                      className="paginationbtnstyle"
                                      caret
                                    >
                                      {pageItem}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(10);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(25);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        25
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(50);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        50
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setPageItem(100);
                                          setCurrentPage(1);
                                        }}
                                      >
                                        100
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                  <Button
                                    className="p-0"
                                    style={{ backgroundColor: "#d0d0d0" }}
                                    onClick={() =>
                                      handlePageChange(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-caret-left"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                                    </svg>
                                  </Button>
                                  <span>
                                    Page {currentPage} of {totalPages}
                                  </span>{" "}
                                  <Button
                                    className="p-0"
                                    style={{ backgroundColor: "#d0d0d0" }}
                                    onClick={() =>
                                      handlePageChange(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-caret-right"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                                    </svg>
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Card>
                      )}
                    </div>
                    <br />
                    <br />
                  </div>
                </TabPanel>
                <TabPanel value="Tenant">
                  <CardHeader className="border-0">
                    <span>
                      <span
                        className="fontstylerentr titleecolor fontfamilysty"
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        Property :
                      </span>
                      <h2
                        className="labelfontstyle fontfamilysty textcolorblue"
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {leaseData?.rental_adress +
                          (leaseData?.rental_unit
                            ? " - " + leaseData?.rental_unit
                            : "")}
                      </h2>
                    </span>
                  </CardHeader>
                  <Row>
                    <Col>
                      {Array.isArray(tenantDetails) ? (
                        <Grid container spacing={2}>
                          {tenantDetails.map((tenant, index) => (
                            <Grid
                              key={index}
                              item
                              xs={12}
                              sm={8}
                              md={6}
                              lg={6}
                              xl={4}
                            >
                              <Box
                                key={index}
                                border="1px solid #324567"
                                boxShadow=" 0px 4px 4px 0px #00000040"
                                borderRadius="10px"
                                padding="16px"
                                maxWidth="700px"
                                margin="20px"
                              >
                                <Row className="d-flex">
                                  <Col xl="2" lg="2" md="2" xs="2">
                                    <Box
                                      className="boxrentalroll"
                                      width="40px"
                                      height="40px"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      backgroundColor="#152B51"
                                      borderRadius="4px"
                                      color="white"
                                      fontSize="24px"
                                    >
                                      <img
                                        className="imageprofilerent"
                                        src={profilei}
                                        width={20}
                                        height={20}
                                        alt="Profile"
                                      />
                                    </Box>
                                  </Col>

                                  <Col xl="6" lg="6" md="6" xs="6">
                                    <div
                                      className="tenantnamefontsty namemodalstyle textcolorblue fontfamilysty"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "18px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {(tenant.tenant_firstName || "N/A") +
                                        " " +
                                        (tenant.tenant_lastName || "N/A")}
                                    </div>
                                    <div
                                      className="fontstylerentr modaltenantfontstyle fontfamilysty"
                                      style={{
                                        color: "#979797",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {tenant.rental_adress}
                                      {tenant.rental_unit !== "" &&
                                      tenant.rental_unit !== undefined
                                        ? ` - ${tenant.rental_unit}`
                                        : null}
                                    </div>
                                  </Col>
                                  {getStatus(
                                    leaseData.start_date,
                                    leaseData.end_date
                                  ) !== "EXPIRED" && (
                                    <Col xl="4" lg="4" md="4" xs="4">
                                      {!tenant?.moveout_notice_given_date ? (
                                        <div
                                          className="d-flex justify-content-end h5 p-0 fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                          onClick={() =>
                                            handleMoveOutClick(tenant)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <LogoutIcon
                                            fontSize="small"
                                            style={{ color: "#152B51" }}
                                          />{" "}
                                          Move out
                                        </div>
                                      ) : (
                                        <div
                                          className="d-flex justify-content-end fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty align-items-center"
                                          onClick={() => handleMoveIn(tenant)}
                                          style={{
                                            cursor: "pointer",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                          }}
                                        >
                                          <ArrowCircleLeftIcon
                                            fontSize="medium"
                                            style={{ color: "#152B51" }}
                                          />
                                          Move In
                                        </div>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  <Col xl="2" lg="2" md="2" xs="2"></Col>

                                  <Col xl="9" lg="9" md="9" xs="9">
                                    <div
                                      className="mt-3 fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        justifyContent: "start",
                                      }}
                                    >
                                      {" "}
                                      {tenant.start_date
                                        ? moment(tenant.start_date).format(
                                            dateFormat
                                          ) +
                                          " TO " +
                                          moment(tenant.end_date).format(
                                            dateFormat
                                          )
                                        : " "}
                                    </div>

                                    <div
                                      className="fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          paddingRight: "3px",
                                          fontSize: "2px",
                                        }}
                                      >
                                        {/* <PhoneAndroidIcon /> */}
                                        <img
                                          className="imagetenant"
                                          src={phone}
                                          width={20}
                                          height={20}
                                          alt="Phone"
                                        />
                                      </Typography>
                                      {tenant.tenant_phoneNumber || "N/A"}
                                    </div>

                                    <div
                                      className="emailstyletenant fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "10px",
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          paddingRight: "3px",
                                          fontSize: "7px",
                                        }}
                                      >
                                        <img
                                          className="imagetenant"
                                          src={email}
                                          width={20}
                                          height={20}
                                          alt=""
                                        />
                                      </Typography>
                                      {/* 6002 change email display outside the card */}
                                      <span
                                        className="emailrentrollsty emailstyletenant textcolorblue fontfamilysty"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                          maxWidth: "220px",
                                          display: "inline-block",
                                        }}
                                      >
                                        {tenant.tenant_email || "N/A"}
                                      </span>
                                      {/* end */}
                                    </div>
                                    <div
                                      className="fontstylerentmodal titleecolor"
                                      style={
                                        tenant.moveout_notice_given_date
                                          ? {
                                              display: "flex",
                                              flexDirection: "row",
                                              marginTop: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              fontFamily: "Poppins",
                                            }
                                          : {
                                              display: "none",
                                            }
                                      }
                                    >
                                      <Typography
                                        className="fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                        style={{
                                          paddingRight: "3px",
                                          fontSize: "14px",

                                          fontWeight: "600",
                                        }}
                                      >
                                        Notice date:
                                      </Typography>
                                      {tenant.moveout_notice_given_date
                                        ? moment(
                                            tenant.moveout_notice_given_date
                                          ).format(dateFormat)
                                        : "N/A"}
                                    </div>
                                    <div
                                      className="fontstylerentmodal titleecolor"
                                      style={
                                        tenant.moveout_date
                                          ? {
                                              display: "flex",
                                              flexDirection: "row",
                                              marginTop: "10px",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                              fontFamily: "Poppins",
                                            }
                                          : {
                                              display: "none",
                                            }
                                      }
                                    >
                                      <Typography
                                        className="fontstylerentr modaltenantfontstyle textcolorblue fontfamilysty"
                                        style={{
                                          paddingRight: "3px",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Move out:
                                      </Typography>
                                      {tenant.moveout_date
                                        ? moment(tenant.moveout_date).format(
                                            dateFormat
                                          )
                                        : "N/A"}
                                    </div>
                                  </Col>

                                  <Col xl="1" lg="1" md="1" xs="1"></Col>
                                </Row>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <h3>No data available....</h3>
                      )}
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="Communication" className="mx-0 px-0 mt-2">
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    style={{
                      borderTopLeftRadius: "20px",
                      borderCollapse: "collapse",
                    }}
                  >
                    <thead
                      style={{
                        height: "45px",
                        fontSize: "14px",
                        fontFamily: "poppins",
                        fontWeight: "600",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        borderTopLeftRadius: "20px",
                      }}
                    >
                      <tr
                        style={{
                          border: "2px solid rgba(50, 69, 103, 1)",
                          borderTopLeftRadius: "20px",
                        }}
                      >
                        <th
                          className="tablefontstyle px-3"
                          scope="col"
                          style={{
                            color: "#152B51",
                            width: "20%",
                            borderTopLeftRadius: "20px",
                          }}
                        >
                          Subject
                        </th>
                        <th
                          className="tablefontstyle px-3"
                          scope="col"
                          style={{ color: "#152B51", width: "20%" }}
                        >
                          Recipient
                        </th>
                        <th
                          className="tablefontstyle px-3"
                          scope="col"
                          style={{ color: "#152B51", width: "40%" }}
                        >
                          Body
                        </th>
                        <th
                          className="tablefontstyle px-3"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                            color: "#152B51",
                            width: "20%",
                          }}
                        >
                          Send date
                        </th>
                        <th
                          className="tablefontstyle px-3"
                          scope="col"
                          style={{
                            borderTopRightRadius: "15px",
                            color: "#152B51",
                            width: "20%",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody style={{ overflow: "auto" }}>
                      <tr
                        style={{
                          border: "none",
                          height: "20px",
                        }}
                      >
                        <td colSpan="9" className="border-none"></td>
                      </tr>

                      {emails?.length > 0 ? (
                        emails.map((item, index) => {
                          const olElement = document.createElement("div");
                          olElement.innerHTML = item.body;
                          const extractedText = olElement.textContent
                            .trim()
                            .substring(0, 40);
                          return (
                            <tr
                              key={index}
                              style={{
                                border: "0.5px solid rgba(50, 69, 103, 1)",
                                fontSize: "12px",
                                height: "40px",
                                fontFamily: "poppins",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEmailModal(item)}
                            >
                              <td className="bordertopintd tablebodyfont px-3">
                                {item.subject}
                              </td>
                              <td className="bordertopintd tablebodyfont px-3">
                                {item.to.map((iteam, index) => (
                                  <span key={index} className="tablebodyfont">
                                    {iteam}
                                    <br />
                                  </span>
                                ))}
                              </td>
                              <td className="bordertopintd tablebodyfont px-3">
                                {extractedText.length > 39
                                  ? `${extractedText.substring(0, 39)}...`
                                  : extractedText}
                              </td>
                              <td
                                className="bordertopintd tablebodyfont px-3"
                                // style={{ color: "green" }}
                              >
                                {moment(item?.createdAt).format(
                                  `${dateFormat} HH:mm:ss`
                                )}
                              </td>
                              <td className="bordertopintd tablebodyfont px-3">
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deleteEmails(item.email_id);
                                    }}
                                  >
                                    <img
                                      src={deleicon}
                                      width={20}
                                      height={20}
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    // onClick={() => handleEmailtableClick(item)}
                                  >
                                    {/* <i className="fa-solid fa-user-group " /> */}
                                    {item.to.map((elem) => (
                                      <div className="d-flex justify-content-between">
                                        {item.accepted.includes(elem) &&
                                        item.opens?.some(
                                          (op) => op.opened_by === elem
                                        ) ? (
                                          <DoneAllIcon color="success" />
                                        ) : item.accepted.includes(elem) ? (
                                          <CheckIcon color="success" />
                                        ) : (
                                          <CloseIcon color="error" />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr
                          style={{
                            border: "none",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <td
                            colSpan="9"
                            className="text-center"
                            style={{ border: 0 }}
                          >
                            Emails not available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Row>
                    <Col className="text-right mt-3 mb-3">
                      <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                        <DropdownToggle className="paginationbtnstyle" caret>
                          {itemsPerPage}{" "}
                        </DropdownToggle>
                        <DropdownMenu>
                          {[10, 25, 50, 100].map((perPage) => (
                            <DropdownItem
                              key={perPage}
                              onClick={() => setItemsPerPage(perPage)} // Update itemsPerPage on click
                            >
                              {perPage}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={handlePrevPage}
                        disabled={currentPages === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-left"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                        </svg>
                      </Button>
                      <span>
                        Page {currentPages} of {totalsPages}
                      </span>{" "}
                      <Button
                        className="p-0"
                        style={{ backgroundColor: "#d0d0d0" }}
                        onClick={handleNextPage}
                        disabled={currentPages === totalsPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-caret-right"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                        </svg>
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="rentersInsurance" className="mx-0 px-0 mt-2">
                  <RenterInsurance />
                </TabPanel>
                <TabPanel value="document" className="mx-0 px-0 mt-2">
                  <RentDocument leaseId={lease_id} />
                </TabPanel>
              </TabContext>
            </Col>
            {/* </Card> */}
          </div>
        </Row>
        <br />
        <br />
      </Container>

      <Modal
        isOpen={isRefundOpen}
        toggle={closeRefund}
        style={{ maxWidth: "1000px" }}
      >
        <ModalHeader
          className=" mx-4 mt-5 text-white background-colorsty bgtextwhite"
          style={{
            borderRadius: "6px",
            height: "45px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
            padding: "10px 7px 1px 21px",
          }}
        >
          <span
            className=" align-items-center bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            Make Refund
          </span>
        </ModalHeader>

        <Form>
          <ModalBody>
            <Row>
              <Col lg="2">
                <FormGroup>
                  <label
                    className="form-control-label titleecolor fontfamilysty"
                    htmlFor="input-unitadd"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Date
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative"
                    id="input-unitadd"
                    placeholder="3000"
                    type="date"
                    name="date"
                    onBlur={generalledgerFormik.handleBlur}
                    onChange={generalledgerFormik.handleChange}
                    value={generalledgerFormik.values.date}
                  />
                  {generalledgerFormik.touched.date &&
                  generalledgerFormik.errors.date ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {generalledgerFormik.errors.date}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <label
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="form-control-label titleecolor fontfamilysty"
                    htmlFor="input-property"
                  >
                    Refund Amount *
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    type="text"
                    id="amount"
                    placeholder="Enter amount"
                    name="amount"
                    onBlur={generalledgerFormik.handleBlur}
                    onWheel={(e) => e.preventDefault()}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/\D/g, "");
                      generalledgerFormik.values.amount = numericValue;
                      generalledgerFormik.handleChange({
                        target: {
                          name: "amount",
                          value: numericValue,
                        },
                      });
                    }}
                    value={generalledgerFormik.values.amount}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label titleecolor fontfamilysty"
                    htmlFor="input-unitadd"
                    style={{
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Memo
                  </label>
                  <Input
                    style={{
                      boxShadow: " 0px 4px 4px 0px #00000040 ",
                      borderRadius: "6px",
                    }}
                    className="form-control-alternative"
                    id="input-unitadd"
                    placeholder="if left blank, will show 'Payment'"
                    type="text"
                    name="memo"
                    onBlur={generalledgerFormik.handleBlur}
                    onChange={generalledgerFormik.handleChange}
                    value={generalledgerFormik.values.memo}
                  />

                  {generalledgerFormik.touched.memo &&
                  generalledgerFormik.errors.memo ? (
                    <div className="requiredstylefont" style={{ color: "red" }}>
                      {generalledgerFormik.errors.memo}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {paymentLoader ? (
              <Button
                className="background-colorsty bgtextwhite"
                disabled
                type="submit"
              >
                Loading
              </Button>
            ) : (
              <Button
                className="background-colorsty bgtextwhite"
                type="submit"
                onClick={(e) => {
                  handleRefundClick();
                  e.preventDefault();
                }}
              >
                Make Refund
              </Button>
            )}
            <Button
              className="backgroundwhitesty textcolorblue"
              onClick={closeRefund}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        toggle={closeModal}
        style={{ maxWidth: "1000px", borderRadius: "20px" }}
      >
        <ModalHeader
          style={{
            padding: "20px",
            paddingBottom: "0px",
          }}
          close={
            <button
              onClick={closeModal}
              style={{
                background: "none",
                border: "none",
                fontSize: "18px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#152B51",
              }}
              aria-label="Close"
            >
              <FaTimes />
            </button>
          }
        >
          <h2>Add Card</h2>
        </ModalHeader>
        <ModalBody>
          <CreditCardForm
            rentalAddress={rentalAddress}
            closeModal={closeModal}
            admin_id={accessType?.admin_id}
            selectedTenant={selectedTenantToAddCard}
            cardToggle={cardToggle}
          />
        </ModalBody>
      </Modal>
      <RecurringTenants
        isOpen={tenantCardsModal}
        toggle={cardToggle}
        openmodal={openCardForm}
        tenantsData={tenantDetails}
        admin_id={accessType?.admin_id}
        fetchTenantData={fetchTenantsData}
        amount={leaseData?.amount}
        setSelectedTenantToAddCard={setSelectedTenantToAddCard}
      />
      <ToastContainer />
    </div>
  );
};

export default RentRollDetail;
