import {
  CardHeader,
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";

const Dailytransactionreport = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState(null);
  let [transactionData, setTransactionData] = useState([]);
  let [reportData, setReportData] = useState([]);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [chargetypedropdownopen, setChargetypeDropdownOpen] = useState(false);
  const toggle3 = () => setChargetypeDropdownOpen((prevState) => !prevState);
  let [loader, setLoader] = useState(true);
  const { admin } = useParams();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [totalPages, setTotalPages] = useState(1);
  // const [totalRecords, setTotalRecorrds] = useState(0);

  let navigate = useNavigate();
  const location = useLocation();

  const [cookies, setCoockie, removeCookie] = useCookies();

  React.useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  // const [selectedDate, setSelectedDate] = useState(() => {
  //   const today = new Date();
  //   return today.toISOString().split("T")[0]; // format date to YYYY-MM-DD
  // });

  // const [selectedToDate, setSelectedToDate] = useState(() => {
  //   const today = new Date();
  //   return today.toISOString().split("T")[0]; // format date to YYYY-MM-DD
  // });

  // const handleDateChange = (event) => {
  //   setSelectedDate(event.target.value); // Update the date on selection
  //   // setCurrentPage(1);
  // };

  const [selectedChargeType, setSelectedChargeType] =
    useState("Select charge type");

  const [daterangedropdownopen, setDateRangeDropdownOpen] = useState(false);

  const toggle4 = () => setDateRangeDropdownOpen((prevState) => !prevState);

  const [daterange, setDateRange] = useState("Today");
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );

  let [grandTotal, setGrandTotal] = useState([]);

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const handledaterange = (type) => {
    setDateRange(type);
    setIsCustomDate(type === "Custom Date");
    const today = new Date();
    let start, end;

    switch (type) {
      case "Today":
        start = today.toISOString().split("T")[0];
        end = today.toISOString().split("T")[0];
        break;
      case "This Week":
        start = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 1))
        ).format("YYYY-MM-DD"); // Monday of the current week
        end = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 7))
        ).format("YYYY-MM-DD"); // Sunday of the current week
        break;
      case "This Month":
        start = moment(
          new Date(today.getFullYear(), today.getMonth(), 1)
        ).format("YYYY-MM-DD"); // First day of the month
        end = moment(
          new Date(today.getFullYear(), today.getMonth() + 1, 0)
        ).format("YYYY-MM-DD"); // Last day of the month
        break;
      case "This Year":
        start = moment(new Date(today.getFullYear(), 0, 1)).format(
          "YYYY-MM-DD"
        ); // January 1st of the current year
        end = moment(new Date(today.getFullYear(), 11, 31)).format(
          "YYYY-MM-DD"
        ); // December 31st of the current year
        break;
      case "Custom Date":
        start = "";
        end = "";
        break;
      default:
        break;
    }

    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };

  const getPaymentsData = async () => {
    if (accessType?.admin_id) {
      setLoader(true);
      try {
        const params = {
          selectedDate: selectedStartDate,
          selectedToDate: selectedEndDate,
          // page: currentPage,
          // limit: itemsPerPage,
        };

        if (
          selectedChargeType !== "All" &&
          selectedChargeType !== "Select charge type"
        ) {
          params.selectedChargeType = selectedChargeType;
        }

        const response = await axiosInstance.get(
          `/payment/todayspayment/${accessType?.admin_id}`,
          { params }
        );
        if (response.data.statusCode === 200) {
          const data = response.data.data;
          setGrandTotal(response?.data?.grandTotal);
          setTransactionData(data);
          // setTotalPages(response.data.totalPages);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching payments data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getPaymentsData();
  }, [
    accessType,
    // currentPage,
    // itemsPerPage,
    // selectedDate,
    // selectedToDate,
    // totalPages,
    // selectedChargeType,
  ]);

  const handleChargeTypeSelect = (type) => {
    setSelectedChargeType(type);
  };

  const getReportData = async () => {
    if (accessType?.admin_id) {
      try {
        const params = {
          selectedDate: selectedStartDate,
          selectedToDate: selectedEndDate,
        };
        if (
          selectedChargeType !== "All" &&
          selectedChargeType !== "Select charge type"
        ) {
          params.selectedChargeType = selectedChargeType;
        }
        const response = await axiosInstance.get(
          `/payment/dailyreport/${accessType?.admin_id}`,
          { params }
        );
        const data = response.data.data;
        setReportData(data);
      } catch (error) {
        console.error("Error fetching payments data:", error);
      }
    }
  };
  React.useEffect(() => {
    getReportData();
  }, [
    accessType,
    //  selectedDate, selectedChargeType, selectedToDate
  ]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("l");

    // Add company logo
    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    // Add the title "Daily Transaction Report"
    const titleYPosition = 10 + 10;
    doc.setFontSize(20);
    doc.text(
      "Daily Transaction Report",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Add the subtitle with the selected date
    const subtitleYPosition = titleYPosition + 10;
    doc.setFontSize(12);
    doc.text(
      selectedStartDate === selectedEndDate
        ? `Date: ${String(selectedStartDate)}`
        : `Date: ${String(selectedStartDate)} to ${String(selectedEndDate)}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Add contact information on the right
    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");
    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20;
    const lineHeight = 4;

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight
      );
    });

    // Create table for transaction details
    const tableYPosition = subtitleYPosition + 20;

    const tableColumns = [
      { header: "Property", dataKey: "property" },
      { header: "Tenant", dataKey: "tenant" },
      { header: "Date", dataKey: "date" },
      { header: "Pmt Type", dataKey: "type" },
      { header: "Txn ID", dataKey: "transaction_id" },
      { header: "Reference", dataKey: "reference" },
      { header: "Crd Type", dataKey: "cc_type" },
      { header: "Crd No", dataKey: "cc_number" },
      { header: "Total", dataKey: "total" },
    ];

    // Add rows for each transaction by mapping the input data
    const tableRows = [];

    for (const table of reportData) {
      const tableRow = [
        {
          content: `Date ${table.date || ""}`,
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
      ];

      tableRows.push(tableRow);

      for (const transaction of table.charges) {
        const transactionRow = [
          {
            content:
              (transaction.rental_data?.rental_adress || "") +
              (transaction.unit_data?.rental_unit
                ? " - " + transaction.unit_data?.rental_unit
                : ""),
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content: transaction.tenant_data?.tenant_firstName
              ? `${transaction.tenant_data?.tenant_firstName} ${transaction.tenant_data?.tenant_lastName}`
              : "External Source",
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content:
              transaction?.entry && transaction?.entry[0]?.date
                ? moment(transaction?.entry[0]?.date).format(`${dateFormat}`)
                : "N/A",
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content:
              transaction?.payment_type +
              (transaction?.type === "Refund" ? " (Refund)" : ""),
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content: transaction?.transaction_id || "N/A",
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content: transaction?.payment_id || "N/A",
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content:
              transaction.payment_type !== "ACH"
                ? transaction?.cc_type || "N/A"
                : "N/A",
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content:
              transaction.payment_type !== "ACH"
                ? transaction?.cc_number || "N/A"
                : "N/A",
            styles: { borderBottom: "none", fontStyle: "bold" },
          },
          {
            content: `$${
              transaction?.is_delete === true
                ? "0.00"
                : new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(transaction?.total_amount || 0.0)
            }`,
            styles: {
              borderBottom: "none",
              fontStyle: "bold",
              halign: "right",
            },
          },
        ];

        var nestedDetails = (
          transaction?.entry ||
          [] ||
          transaction?.is_delete === true
        )
          .map((entry) => entry?.account || "Void") // Fallback to "N/A" if account is null or undefined
          .join("\n");
        nestedDetails =
          transaction?.is_delete === true
            ? `Void (Reason : ${transaction.reason})`
            : nestedDetails;

        var nestedDetailsAmount = (
          transaction?.entry ||
          [] ||
          transaction?.is_delete === true
        )
          ?.map((entry) => {
            const amount =
              entry?.amount !== undefined && entry?.amount !== null
                ? entry?.amount
                : "$0.00";
            return `$${new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(amount || 0.0)}`; // Ensure amount is a number before calling toFixed()
          })
          .join("\n");

        const detailsRow = [
          {
            content: `${nestedDetails}`,
            colSpan: 8,
            styles: {
              halign: "left",
              cellPadding: { top: 1, bottom: 5, left: 15, right: 0 },
              borderTop: 0,
              fontSize: 9,
            },
            border: 0,
          },
          {
            content: `${nestedDetailsAmount}`,
            styles: {
              halign: "right",
              cellPadding: { top: 1, bottom: 5, left: 0, right: 2 },
              borderTop: 0,
              fontSize: 9,
            },
            border: 0,
          },
        ];

        tableRows.push(transactionRow);
        tableRows.push(detailsRow);
      }

      tableRows.push([
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: "Subtotal:",
          styles: { borderBottom: "none", fontStyle: "bold" },
        },
        {
          content: `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(table.subtotal || 0.0)}`,
          styles: {
            borderBottom: "none",
            fontStyle: "bold",
            halign: "right",
          },
        },
      ]);
    }

    // Calculate the total amount for all transactions
    const totalAmount = reportData
      .reduce((sum, t) => sum + t.subtotal, 0)
      .toFixed(2);

    // Add the total amount row
    const totalRow = [
      {
        content: `GRAND TOTAL:`,
        colSpan: 7,
        styles: { halign: "left", fontStyle: "bold", fontSize: 14 },
      },
      {
        content: `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(totalAmount || 0.0)}`,
        colSpan: 9,
        styles: { halign: "right", fontStyle: "bold", fontSize: 14 },
      },
    ];

    // Add the total row to the result array
    tableRows.push(totalRow);

    // Add the table to the PDF
    doc.autoTable({
      startY: tableYPosition,
      head: [tableColumns.map((col) => col.header)],
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 11,
        fontStyle: "bold",
      },
      body: tableRows,
      theme: "plain",
      styles: { fontSize: 10, cellPadding: 2 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 25 },
        3: { cellWidth: 25 },
        4: { cellWidth: 25 },
        5: { cellWidth: 30 },
        6: { cellWidth: 25 },
        7: { cellWidth: 35 },
        8: { cellWidth: 25 },
      },
    });

    const lineYPosition1 = doc.autoTable.previous.finalY - 10;

    // Ensure the line positions are valid
    if (lineYPosition1 > 10) {
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(
        15,
        lineYPosition1,
        doc.internal.pageSize.getWidth() - 13,
        lineYPosition1
      );
    } else {
      console.error("The lines are positioned outside the page bounds.");
    }

    const pageCount = doc.getNumberOfPages();
    const currentDate = new Date();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `${moment(currentDate).format("YYYY-MM-DD HH:ss:mm")}`,
        10,
        doc.internal.pageSize.getHeight() - 8
      );
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 8,
        { align: "right" }
      );
    }

    // Save the PDF
    doc.save(`Daily_transaction_report.pdf`);
  };

  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];
    let grandTotal = 0;

    const headers = [
      "Property",
      "Tenant",
      "Date",
      "Payment Type",
      "Transaction Id",
      "Reference",
      "Card Type",
      "Card No",
      "Total",
    ];

    wsData?.push(headers);

    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" }, sz: "14" },

      border: {
        top: { style: "thin", color: "000000" },
        bottom: { style: "thin", color: "000000" },
        left: { style: "thin", color: "000000" },
        right: { style: "thin", color: "000000" },
      },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "5A86D7" } },
    };

    reportData?.forEach((transaction) => {
      wsData?.push([
        {
          v: transaction?.date || "N/A",
          s: { font: { bold: true, sz: 12 } },
        },
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      // Rows for each payment
      transaction.charges?.forEach((payment) => {
        // Payment row
        wsData?.push([
          (payment.rental_data?.rental_adress || "") +
            (payment.unit_data?.rental_unit
              ? " - " + payment.unit_data?.rental_unit
              : ""),
          payment.tenant_data?.tenant_firstName
            ? `${payment.tenant_data?.tenant_firstName} ${payment.tenant_data?.tenant_lastName}`
            : "N/A",
          payment?.entry[0]?.date && payment?.entry[0]?.date
            ? moment(payment?.entry[0]?.date).format(`${dateFormat}`)
            : "-",
          payment?.payment_type || "N/A",
          payment?.transaction_id || "N/A",
          payment?.payment_id || "N/A",
          payment?.cc_type || "N/A",
          payment?.cc_number || "N/A",
          {
            v: `$${new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(payment?.total_amount || 0.0)}`,
            s: {
              alignment: { horizontal: "right" },
            },
          },
        ]);

        grandTotal += Number(payment?.total_amount) || 0;

        payment.entry?.forEach((entry) => {
          wsData?.push([
            entry.account || "N/A",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            {
              v: `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry?.amount || 0.0)}`,
              s: {
                alignment: { horizontal: "right" },
              },
            },
          ]);
        });
      });
      wsData?.push([
        {
          v: `Subtotal - ${transaction?.date || "N/A"}`,
          s: { font: { bold: true } },
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        {
          v: `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(transaction?.subtotal || 0.0)}`,
          s: {
            alignment: { horizontal: "right" },
            font: { bold: true },
          },
        },
      ]);
    });

    wsData?.push([
      {
        v: "Grand Total",
        s: { font: { bold: true } },
      },
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      {
        v: `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(grandTotal)}`,
        s: {
          alignment: { horizontal: "right" },
          font: { bold: true },
        },
      },
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    for (let i = 0; i < headers?.length; i++) {
      ws[XLSX.utils.encode_cell({ r: 0, c: i })].s = headerStyle;
    }

    ws["!cols"] = headers.map(() => ({ width: 20 }));

    for (let rowIndex = 0; rowIndex < wsData?.length; rowIndex++) {
      const row = wsData[rowIndex];
      row?.forEach((cell, colIndex) => {
        if (cell?.s) {
          ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })].s = cell?.s;
        }
        if (rowIndex > 0) {
          ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })].s = {
            ...ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })]?.s,
          };
        }
      });
    }

    XLSX.utils.book_append_sheet(wb, ws, "Daily Transaction Report");

    XLSX.writeFile(wb, "Daily_transaction_report.xlsx");
  };

  const generateCSV = () => {
    let csvData = [];
    let grandTotal = 0;

    const headers = [
      "Property",
      "Tenant",
      "Date",
      "Payment Type",
      "Transaction Id",
      "Reference",
      "Card Type",
      "Card No",
      "Total",
    ];

    csvData?.push(headers.join(","));

    reportData?.forEach((transaction) => {
      csvData?.push(
        [transaction.date || "N/A", "", "", "", "", "", "", "", ""].join(",")
      );

      transaction.charges?.forEach((payment) => {
        const propertyAddress = (
          (payment.rental_data?.rental_adress.split(",").join(" ") || "") +
          (payment.unit_data?.rental_unit
            ? " - " + payment.unit_data?.rental_unit
            : "")
        ).padEnd(50, " ");

        csvData?.push(
          [
            propertyAddress,
            payment.tenant_data?.tenant_firstName
              ? `${payment.tenant_data?.tenant_firstName} ${payment.tenant_data?.tenant_lastName}`
              : "N/A",
            payment?.entry[0]?.date && payment?.entry[0]?.date
              ? moment(payment?.payment?.entry[0]?.date).format(`${dateFormat}`)
              : "-",
            payment?.payment_type || "N/A",
            payment?.transaction_id || "N/A",
            payment?.payment_id || "N/A",
            payment?.cc_type || "N/A",
            payment?.cc_number || "N/A",
            `$${payment?.total_amount?.toFixed(2) || 0.0}`,
          ].join(",")
        );

        grandTotal += Number(payment?.total_amount) || 0;

        payment.entry?.forEach((entry) => {
          csvData?.push(
            [
              entry.account || "N/A",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              `$${entry.amount.toFixed(2) || "0.00"}`,
            ].join(",")
          );
        });
      });
      csvData?.push(
        [
          `Subtotal - ${transaction?.date || "N/A"}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          `$${transaction?.subtotal?.toFixed(2) || 0.0}`,
        ].join(",")
      );
    });

    csvData?.push(
      [
        "Grand Total",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        `$${grandTotal?.toFixed(2) || 0.0}`,
      ].join(",")
    );

    const csvString = csvData?.join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Daily_transaction_report.csv`);
  };

  return (
    <>
      <Header />
      {/* <Container className="mt--8" fluid> */}
      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Daily Transaction Report
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row className="mb-3">
          <Col xl="2" lg="3" md="3" sm="6" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Date Range
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={daterangedropdownopen}
                toggle={toggle4}
                style={{ width: "100%" }}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {daterange}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handledaterange("Today")}
                  >
                    Today
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handledaterange("This Week")}
                  >
                    This Week
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handledaterange("This Month")}
                  >
                    This Month
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handledaterange("This Year")}
                  >
                    This Year
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handledaterange("Custom Date")}
                  >
                    Custom Date
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="5" xl="2">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-startdate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                From
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-startdate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                disabled={!isCustomDate}
                max={moment(selectedEndDate).format("YYYY-MM-DD") || null}
              />
            </FormGroup>
          </Col>
          <Col lg="3" md="3" sm="5" xl="2">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-enddate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                To
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-enddate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                disabled={!isCustomDate}
                min={moment(selectedStartDate).format("YYYY-MM-DD") || null}
              />
            </FormGroup>
          </Col>
          <Col lg="5" md="5" sm="12" xl="3">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Charge Type
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={chargetypedropdownopen}
                toggle={toggle3}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {selectedChargeType}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Card")}
                  >
                    Card
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("ACH")}
                  >
                    ACH
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Check")}
                  >
                    Check
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Cash")}
                  >
                    Cash
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Manual")}
                  >
                    Manual
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Money Order")}
                  >
                    Money Order
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Cashier's Check")}
                  >
                    Cashier 's Check
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("All")}
                  >
                    All
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col className="d-flex justify-content-end addtotnstyle mt-4">
            <Button
              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={() => {
                getPaymentsData();
                getReportData();
              }}
              style={{
                height: "46px",
                fontSize: "16px",
                border: "1px solid #152B5180",
                boxShadow: "0px 4px 4px 0px #00000040",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              Run
            </Button>
            {transactionData && transactionData?.length > 0 && (
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className="background-colorsty bgtextwhite fontfamilysty"
                  caret
                  style={{
                    marginBottom: "12px",
                    fontSize: "16px",
                    border: "1px solid #152B5180",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    fontWeight: "500",
                  }}
                >
                  Exports
                </DropdownToggle>
                <DropdownMenu style={{ minWidth: "120px" }}>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("PDF")}
                  >
                    PDF
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("XLSX")}
                  >
                    XLSX
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("CSV")}
                  >
                    CSV
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>
        </Row>
        <Table
          className="align-items-center table-flush"
          responsive
          style={{ borderCollapse: "collapse" }}
        >
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <thead
                className="fontfamilysty"
                style={{
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "600",
                  boxShadow: "none",
                  border: 0,
                }}
              >
                <tr className="" style={{ border: 0 }}>
                  <td
                    className="tabletextcolor"
                    colSpan={5}
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    Grand total
                  </td>
                  <td
                    className="tabletextcolor  text-right"
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {`$${new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(grandTotal || 0.0)}`}
                  </td>
                </tr>
                <tr
                  style={{
                    borderBottom: "2px solid rgba(50, 69, 103, 1)",
                  }}
                >
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Property
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Type
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    TXN Date
                  </th>

                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Payment
                  </th>

                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Detail
                  </th>

                  <th
                    className="tablefontstyle textcolorblue text-right"
                    scope="col"
                    style={{ fontWeight: "Bold", border: 0 }}
                  >
                    Total
                  </th>

                  {/* <th className="tablefontstyle textcolorblue" scope="col">
                    Total Transaction Amount
                  </th> */}
                </tr>
              </thead>

              <tbody>
                {transactionData?.length > 0 ? (
                  transactionData?.map((data, index) => (
                    <>
                      <tr key={index}>
                        <td
                          className="dailyreporttablefont tabletextcolor"
                          colSpan={6}
                          style={{
                            border: 0,
                            verticalAlign: "top",
                            fontWeight: "bold",
                          }}
                        >
                          Date
                          <br />
                          {moment(data?.date).format(`${dateFormat}`)}
                        </td>
                      </tr>
                      {data.charges && data.charges?.length > 0 ? (
                        data.charges.map((tenant) => (
                          <tr
                            key={tenant?.transaction_id} // Adding a key to each row
                            style={{
                              // border: "0.5px solid rgba(50, 69, 103, 1)",
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                            }}
                          >
                            <td
                              className="dailyreporttablefont tabletextcolor"
                              style={{
                                border: 0,
                                verticalAlign: "top",
                              }}
                            >
                              <span className="dailyreporttablefont rentrollcolorsty">
                                {(tenant?.rental_data?.rental_adress || "") +
                                  (tenant?.unit_data?.rental_unit
                                    ? " - " + tenant?.unit_data?.rental_unit
                                    : " ")}
                              </span>
                              <br />
                              {tenant?.tenant_data?.tenant_firstName
                                ? `${
                                    tenant?.tenant_data?.tenant_firstName ||
                                    "N/A"
                                  }` +
                                  " " +
                                  `${
                                    tenant?.tenant_data?.tenant_lastName ||
                                    "N/A"
                                  }`
                                : "External Source"}
                            </td>
                            <td
                              className="dailyreporttablefont tabletextcolor"
                              style={{ border: 0, verticalAlign: "top" }}
                            >
                              {tenant?.payment_type || "N/A"}
                            </td>
                            <td
                              className="dailyreporttablefont tabletextcolor"
                              style={{
                                border: 0,
                                width: "15%",
                                verticalAlign: "top",
                                // wordWrap: "break-word",
                                // overflowWrap: "break-word",
                                // maxWidth: "60px",
                                // display: "inline-block",
                              }}
                            >
                              {tenant?.entry && tenant?.entry[0]?.date
                                ? moment(tenant?.entry[0]?.date).format(
                                    `${dateFormat}`
                                  )
                                : "N/A"}
                            </td>
                            <td
                              className="dailyreporttablefont tabletextcolor"
                              style={{ border: 0, verticalAlign: "top" }}
                            >
                              {!tenant?.cc_type ||
                              Object.keys(tenant.cc_type).length !== 0 ? (
                                <>
                                  {tenant?.cc_type} &nbsp;
                                  {tenant?.cc_number || "N/A"}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>

                            <td
                              className="dailyreporttablefont tabletextcolor"
                              style={{
                                border: 0,
                                width: "20%",
                                verticalAlign: "top",
                              }}
                            >
                              {tenant.is_delete === true ? (
                                `Void (Reason : ${tenant.reason})`
                              ) : (
                                <>
                                  <span className="d-flex flex-column dailyreporttablefont tabletextcolor">
                                    {tenant?.entry?.length > 0
                                      ? tenant.entry.map((item) => (
                                          <span
                                            key={item?.account}
                                            className="d-flex justify-content-between"
                                          >
                                            <span
                                              className="tabletextcolor dailyreporttablefont"
                                              style={{
                                                whiteSpace: "break-spaces",
                                              }}
                                            >
                                              {item?.account}
                                            </span>
                                            <span className="tabletextcolor dailyreporttablefont">
                                              $
                                              {new Intl.NumberFormat("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }).format(item.amount)}
                                            </span>
                                          </span>
                                        ))
                                      : "No account"}
                                  </span>
                                </>
                              )}
                            </td>

                            <td
                              className="dailyreporttablefont tabletextcolor"
                              style={{ border: 0, verticalAlign: "top" }}
                            >
                              <span
                                className="d-flex justify-content-end dailyreporttablefont"
                                style={{ fontWeight: "Bold" }}
                              >
                                {tenant.is_delete === true ? (
                                  "$0.00"
                                ) : (
                                  <>
                                    {`$${new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(tenant?.total_amount || "0.00")}`}
                                  </>
                                )}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center"
                            style={{ fontSize: "15px" }}
                          >
                            No Payment Details Found
                          </td>
                        </tr>
                      )}
                      <tr key={index}>
                        <td
                          className="dailyreporttablefont tabletextcolor"
                          colSpan={6}
                          style={{
                            border: 0,
                            verticalAlign: "top",
                            textAlign: "right",
                            fontWeight: "Bold",
                          }}
                        >
                          Subtotal
                          <br />
                          {`$${new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(data?.subtotal || "0.00")}`}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr className="text-center">
                    <td
                      colSpan="9"
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      No Transaction Found
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          )}
        </Table>

        <ToastContainer />
      </Container>
    </>
  );
};

export default Dailytransactionreport;
