import React from "react";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Paper, Typography, Box } from "@mui/material";
import moment from "moment";

const PoliciesTable = ({ policies,insuranceLoader, totalPolicies, insurancePage, insuranceRowsPerPage, setInsurancePage, setInsuranceRowsPerPage }) => {
  
  // Handle page change
  const handleChangeInsurancePage = (event, newPage) => {
    setInsurancePage(newPage);
   
  };

  // Handle rows per page change
  const handleChangeInsuranceRowsPerPage = (event) => {
    setInsuranceRowsPerPage(parseInt(event.target.value, 10));
    setInsurancePage(0); // Reset to the first page when rows per page changes
    
  };

  return (
    <>
      {insuranceLoader ? (
        // Show loader when insurance data is loading
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ height: "50px" }}>
                <TableCell><span className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}>Tenant Name</span></TableCell>
                <TableCell><span className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}>Rental Address</span></TableCell>
                <TableCell><span className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}>Expiration Date</span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
              {Array.from({ length: insuranceRowsPerPage }).map((_, index) => (
                <TableRow key={index} style={{ height: "50px" }}>
                  <TableCell>Loading...</TableCell>
                  <TableCell>Loading...</TableCell>
                  <TableCell>Loading...</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
                  component="div"
                  count={totalPolicies}
                  page={insurancePage}
                  onPageChange={handleChangeInsurancePage}
                  rowsPerPage={insuranceRowsPerPage}
                  onRowsPerPageChange={handleChangeInsuranceRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                    "& p": { margin: "0" },
                    "& div": { margin: "0" },
                  }}
                />
        </TableContainer>
      ) : (
        <>
          {policies.length === 0 ? (
            <Typography>No policies are expiring within 90 days.</Typography>
          ) : (
            <>
              <TableContainer component={Paper} style={{ borderRadius: "0 0 20px 20px", boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0px", display: "flex", "flexDirection": "column" }}>
                <Table>
                  <TableHead>
                    <TableRow style={{ height: "50px" }}>
                      <TableCell><span className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}>Tenant Name</span></TableCell>
                      <TableCell><span className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}>Rental Address</span></TableCell>
                      <TableCell><span className="labelfontstyle titleecolor"
                                        style={{
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                        }}>Expiration Date</span></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                    {policies.map((policy) => (
                      <TableRow key={policy.rentersInsurance.policy_id} style={{ height: "50px" }}>
                        <TableCell>{policy.tenant_name}</TableCell>
                        <TableCell>{policy.rental_address}</TableCell>
                        <TableCell>
                          {moment(policy.rentersInsurance.expiration_date).format("YYYY-MM-DD")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <TablePagination
                  component="div"
                  count={totalPolicies}
                  page={insurancePage}
                  onPageChange={handleChangeInsurancePage}
                  rowsPerPage={insuranceRowsPerPage}
                  onRowsPerPageChange={handleChangeInsuranceRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                    "& p": { margin: "0" },
                    "& div": { margin: "0" },
                  }}
                />
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PoliciesTable;