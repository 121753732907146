import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useParams } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Media,
  FormGroup,
  Row,
  Col,
  InputGroup,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useCookies } from "react-cookie";
import notify from "../../assets/icons/notify.svg";
import { useMediaQuery } from "@mui/material";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "axiosInstance";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Notificationmodal from "../Notificationmodal";
import { verifyToken } from "components/Functions/Functions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Loader from "components/ReusableComponents/Loader";
import { handleLogout } from "plugins/helpers";
import moment from "moment";

const AdminNavbar = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const { admin } = useParams();

  const [loader, setLoader] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showInputGroup, setShowInputGroup] = useState(false);
  const [accessType, setAccessType] = useState({});
  const [currentPlan, setCurrentPlan] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie, admin);
      setAccessType(data);
      if (data) {
        try {
          const res = await axiosInstance.get(
            `/purchase/plan-purchase/${data.admin_id}`
          );
          if (res.data.data && res.data.data.plan_detail) {
            setCurrentPlan(res.data.data.plan_detail.plan_name);
          }
        } catch (error) {
          console.log("Error: ", error);
        } finally {
          setLoader(false);
        }
      }
    };
    fetchData();
  }, [navigate]);

  const fetchNotification = async () => {
    try {
      const response = await axiosInstance.get(
        `/notification/admin/${accessType?.admin_id}`
      );
      const data = response.data;

      if (data.statusCode === 200) {
        const unreadNotifications = data.data;
        setNotificationData(unreadNotifications);
        setNotificationCount(unreadNotifications.length);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    if (accessType?.admin_id) {
      fetchNotification();
    }
  }, [accessType?.admin_id]);

  const Logout = () => {
    handleLogout(cookies, removeCookie);
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const readAdminNotification = async (notification_id) => {
    try {
      const response = await axiosInstance.put(
        `/notification/admin_notification/${notification_id}`
      );
      if (response.status === 200) {
        fetchNotification();
      } else {
        console.error("Response status is not 200");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const setAllRead = async (admin_id) => {
    try {
      const response = await axiosInstance.put(
        `/notification/marked-all-read/${admin_id}`
      );

      if (response.status === 200) {
        fetchNotification();
      } else {
        console.error("Failed to mark all notifications as read.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const navigateToDetails = (data) => {
    axiosInstance
      .put(`/notification/admin_notification/${data.notification_id}`)
      .then((response) => {
        if (response.status === 200) {
          if (data.is_workorder) {
            navigate(
              `/admin/addworkorder/${data.notification_type.workorder_id}`
            );
            fetchNotification();
          } else {
            navigate(
              `/admin/rentrolldetail/${data.notification_type.lease_id}?Financial`,
              { state: { tab: "Financial" } }
            );
            fetchNotification();
          }
        } else {
          console.error(
            `Failed to delete notification with workorder_id ${data.notification_type.workorder_id}.`
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [plan, setPlan] = useState("");
  const getPlan = async () => {
    if (accessType?.admin_id) {
      try {
        const res = await axiosInstance.get(
          `/purchase/plan-purchase/${accessType?.admin_id}`
        );
        if (res.data.statusCode === 200) {
          const planData = res.data.data;
          const expirationDate = new Date(planData.expiration_date);
          const currentDate = new Date();
          expirationDate.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);

          if (expirationDate >= currentDate) {
            // Expiration date is in the past
            setPlan(planData);
          } else {
            // Expiration date is in the future or today
            navigate("/plans");
          }
        } else {
          navigate("/plans");
        }
      } catch (error) {
        navigate("/plans");
      }
    }
  };

  useEffect(() => {
    getPlan();
  }, [accessType?.admin_id]);

  const [selectedProp, setSelectedProp] = useState("Select");

  const handlePropertySelect = (property) => {
    setSelectedProp(property);
  };

  const isMediumScreen = useMediaQuery("(max-width:991px)");

  const fetchTenants = async (adminId, query) => {
    try {
      const response = await axiosInstance.get(
        `/tenant/tenantsearch/${adminId}`,
        {
          params: { search: query },
        }
      );
      return Array.isArray(response.data.data) ? response.data.data : [];
    } catch (error) {
      console.error("Error fetching tenants:", error);
      return [];
    }
  };

  const handleSearch = async (query) => {
    if (query.trim() === "") {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }
    if (query.length >= 3) {
      const tenants = await fetchTenants(accessType?.admin_id, query);
      setSearchResults(Array.isArray(tenants) ? tenants : []);
      setShowDropdown(true);
    } else {
      setSearchResults([]);
      setShowDropdown(false);
    }
  };

  const handleInputChange = (newValue) => {
    setSearchQuery(newValue);
    handleSearch(newValue);
  };

  const handleKeyDown = (event) => {
  
    if (event.key === "Enter") {
      debugger;
      handleSearch(searchQuery);
      event.preventDefault();
    }
  };

  const customOption = ({ innerRef, innerProps, data }) => (
    <div ref={innerRef} {...innerProps}>
      <div
        className="d-flex justify-content-between"
        style={{ margin: "15px" }}
      >
        <div>
          <FontAwesomeIcon
            icon={faCircleUser}
            style={{ width: "18px", height: "18px" }}
          />{" "}
          &nbsp;
          <Link
            style={{
              fontWeight: "800",
              color: "#5A86D5",
              fontFamily: "Poppins",
            }}
            to={"/" + admin + "/tenantdetail/" + data.tenant_id}
            onClick={(e) => {}}
          >
            {data.tenant_firstName} {data.tenant_lastName}
          </Link>
        </div>

        <div>
          {/* <MonetizationOnIcon />
          &nbsp; */}
          {data?.leaseDetails?.lease_id && (
            <span
              className="lease-link"
              onClick={(e) => {
                navigate(
                  `/${admin}/rentrolldetail/${data?.leaseDetails?.lease_id}`,
                  { state: "financial" }
                );
              }}
            >
              {data?.leaseDetails?.rentalAdress || "Lease Ledger"}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  const customDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon={faSearch}
          onClick={(e) => {
            handleSearch(searchQuery);
            e.stopPropagation();
          }}
        />
      </components.DropdownIndicator>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        className="navbar-top navbar-dark navbaradmintopstyle pb-2 pr-3"
        id="navbar-main"
        style={{
          backgroundColor: isMediumScreen
            ? "rgba(249, 250, 253, 1)"
            : "#ffffff",
          boxShadow: isScrolled ? "0 4px 4px 0 rgba(0, 0, 0, 0.25)" : "none",
          position: "fixed",
          width: isMediumScreen
            ? "100%"
            : !props.isCollapse
            ? "calc(100vw - 345px)"
            : "calc(100vw - 146px)",
          zIndex: 2,
        }}
      >
        <Form className="navbar-search navbar-search-dark form-inline mt-1 px-3 d-none d-lg-flex justify-content-between w-100">
          <InputGroup style={{ width: "400px" }} className="d-none d-lg-flex">
            <div style={{ width: "400px" }}>
              <Select
                value={null}
                inputValue={searchQuery}
                onInputChange={(newValue) => handleInputChange(newValue)}
                options={searchResults}
                getOptionLabel={(tenant) =>
                  `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
                }
                onKeyDown={handleKeyDown}
                noOptionsMessage={() => "No results found"}
                getOptionValue={(tenant) => tenant.tenant_id}
                components={{
                  DropdownIndicator: customDropdownIndicator,
                  Option: customOption,
                }}
                placeholder="Search tenants..."
                onFocus={() => setShowDropdown(true)}
                onBlur={() => setShowDropdown(false)}
                isClearable
              />
            </div>
          </InputGroup>

          <Row className="d-lg-flex align-items-center px-3">
            <div className="p-0 mx-3 mb-0">
              <Button
                color="primary"
                onClick={() =>
                  plan?.plan_detail?.plan_name &&
                  plan?.plan_detail?.plan_name !== "Free Plan"
                    ? navigate("/" + admin + "/Purchaseplandetail")
                    : navigate("/" + admin + "/Plans")
                }
                size="sm"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                className="p-2 background-colorsty bgtextwhite fontfamilysty"
              >
                {loader ? (
                  <Loader color="#ffffff" />
                ) : plan?.plan_detail?.plan_name &&
                  plan?.plan_detail?.plan_name !== "Free Plan" ? (
                  plan?.plan_detail?.plan_name
                ) : (
                  "Buy Now"
                )}
              </Button>
            </div>

            <FormGroup
              className="mb-1 mr-3"
              onClick={toggleSidebar}
              style={{ cursor: "pointer", position: "relative" }}
            >
              {notificationCount === 0 ? (
                <i className="far fa-bell" style={{ fontSize: "30px" }}></i>
              ) : (
                <img src={notify} width={30} height={30} />
              )}
            </FormGroup>

            <UncontrolledDropdown
              className="mb-0 ml-1 mr-0 d-none d-lg-flex"
              style={{ border: "none", background: "none", boxShadow: "none" }}
            >
              <DropdownToggle
                style={{
                  border: "none",
                  background: "rgba(54, 159, 255, 0.1)",
                  boxShadow: "none",
                }}
              >
                <Media className="align-items-center">
                  <span
                    className="d-flex justify-content-center align-items-center background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "12px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {loader ? (
                      <Loader color="#ffffff" />
                    ) : (
                      `${accessType?.first_name
                        ?.slice(0, 1)
                        .toUpperCase()}${accessType?.last_name
                        ?.slice(0, 1)
                        .toUpperCase()}`
                    )}
                  </span>
                  <Media className="ml-3 d-none d-lg-block d-flex justify-content-start">
                    <span
                      className="mb-0 font-weight-bold text-dark"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Manrope",
                      }}
                    >
                      {accessType?.first_name || ""}{" "}
                      {accessType?.last_name || ""}
                    </span>
                    <br />
                    <span
                      className="mb-0 ml-0 font-weight-bold"
                      style={{
                        fontSize: "10px",
                        fontFamily: "Manrope",
                      }}
                    >
                      Property Manager
                    </span>
                  </Media>
                  <span
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      fontSize: "20px",
                      color: "#000",
                      marginLeft: "35px",
                    }}
                  >
                    <i className="fa-solid fa-angle-down"></i>
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title w-100" header tag="div">
                  <h6
                    className="text-overflow m-0"
                    style={{
                      fontSize: "14px",
                      color: "#000",
                      marginLeft: "35px",
                    }}
                  >
                    Welcome
                  </h6>
                </DropdownItem>
                <DropdownItem
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                  className="text-overflow m-0"
                  onClick={() => navigate("/" + admin + "/user-profile")}
                >
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                  className="text-overflow m-0"
                  onClick={() => navigate("/" + admin + "/Userrole")}
                >
                  {/* < i className="fa-solid fa-user-pen"/> */}
                  {/* <img src={userpermission} width={15} height={15} /> */}
                  {/* <ContactPageIcon style={{fontSize:"18px"}} /> */}
                  <i className="fa-solid fa-file-pen"></i>
                  <span>User permission</span>
                </DropdownItem>
                <DropdownItem
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                  className="text-overflow m-0"
                  onClick={() => navigate("/" + admin + "/activities")}
                >
                  <AssignmentIcon />
                  {/* <i className="fa-solid fa-file-pen"></i> */}
                  <span>Activities</span>
                </DropdownItem>
                {currentPlan !== "Free Plan" && (
                  <DropdownItem
                    style={{
                      fontSize: "14px",
                      color: "#000",
                      marginLeft: "35px",
                    }}
                    className="text-overflow m-0"
                    onClick={() => navigate("/" + admin + "/settings")}
                  >
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                )}
                <DropdownItem divider />
                <DropdownItem
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                  className="text-overflow m-0"
                  to="/auth/login"
                  onClick={Logout}
                  tag={Link}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
              {searchResults.length > 0 && (
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem header>
                    <strong>Search Results</strong>
                  </DropdownItem>
                  {searchResults.map((tenant, index) => (
                    <DropdownItem key={index}>
                      {tenant.tenant_firstName}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          </Row>
        </Form>

        <Nav className="align-items-center d-none d-lg-flex" navbar>
          <Drawer
            className="adminnavv"
            anchor="right"
            open={isSidebarOpen}
            onClose={toggleSidebar}
          >
            <div
              role="presentation"
              onClick={toggleSidebar}
              onKeyDown={toggleSidebar}
            >
              <List
                className="notificationtextbar"
                style={{ width: "350px", border: "none" }}
              >
                <Divider />
                <div>
                  <h2
                    className="fonteightteen background-colorsty bgtextwhite fontfamilysty"
                    style={{
                      marginLeft: "15px",
                      borderRadius: "10px, 0px, 0px, 10px",
                      fontWeight: "600",
                      marginTop: "-9px",
                      padding: "18px",
                      marginLeft: "0",
                    }}
                  >
                    Notifications
                    <i
                      style={{ marginLeft: "150px", cursor: "pointer" }}
                      className="fa-solid fa-xmark"
                      onClick={toggleSidebar}
                    />
                  </h2>
                </div>
                {notificationData?.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    No Notification
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-end align-items-end mr-3 mb-2">
                      <Button
                        className="fontstylerentmodal"
                        variant="contained"
                        style={{
                          background: "#152B51",
                          color: "white",
                          textTransform: "none",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                        onClick={() => setAllRead(accessType?.admin_id)}
                      >
                        Read All
                      </Button>
                    </div>
                    <Divider />
                    {notificationData.map((data) => {
                      const notificationTitle =
                        data.notification_title || "No Title Available";
                      const notificationDetails =
                        data.notification_detail || "No Details Available";
                      const notificationTime = new Date(
                        data.createdAt
                      ).toLocaleString();

                      return (
                        <div key={data._id}>
                          <ListItem
                            onClick={() => handlePropertySelect(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="w-100">
                              <h4
                                className="barforsummaryrent textcolorblue fontfamilysty"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "600",
                                }}
                              >
                                {notificationTitle}
                              </h4>
                              <p
                                className="notificationtitle titleecolor fontfamilysty"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                {notificationDetails}
                              </p>
                              <Row>
                                <Col lg="8">
                                  <p
                                    className="fontstylerentr textcolorblue fontfamilysty"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {moment(notificationTime).fromNow()}
                                  </p>
                                </Col>
                                <Col>
                                  <Button
                                    className="fontstylerentmodal background-colorsty bgtextwhite fontfamilysty"
                                    variant="contained"
                                    style={{
                                      textTransform: "none",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => navigateToDetails(data)}
                                  >
                                    Views
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </ListItem>
                          <Divider />
                        </div>
                      );
                    })}
                  </>
                )}
              </List>
              <Divider />
            </div>
          </Drawer>
        </Nav>

        <div className="d-flex justify-content-center d-lg-none align-items-center">
          <FormGroup
            className="mb-1 menub"
            onClick={props.toggleOpenSidebar}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <MenuIcon className="menub" style={{ width: 40, height: 40 }} />
          </FormGroup>
          <FormGroup
            className="mb-1 ml-3"
            onClick={() => navigate(`/${admin}/index`)}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <img src={props.logo} width={50} height={40} />
          </FormGroup>
        </div>

        <div className="d-flex justify-content-center d-lg-none align-items-center">
          {!showInputGroup ? (
            <Button onClick={() => setShowInputGroup(true)}>
              <FontAwesomeIcon
                icon={faSearch}
                style={{ height: "30px", width: "25px", color: "#152B51" }}
              />
            </Button>
          ) : (
            <InputGroup
              style={{ width: "400px" }}
              className="searchinnavbar searchmrinnavbar"
            >
              <div style={{ width: "400px" }} className="searchinnavbar">
                <Select
                  value={null}
                  inputValue={searchQuery}
                  onInputChange={(newValue) => handleInputChange(newValue)}
                  options={searchResults}
                  getOptionLabel={(tenant) =>
                    `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
                  }
                  noOptionsMessage={() => null}
                  getOptionValue={(tenant) => tenant.tenant_id}
                  components={{
                    DropdownIndicator: customDropdownIndicator,
                    Option: customOption,
                  }}
                  placeholder="Search tenants..."
                  onFocus={() => setShowDropdown(true)}
                  onBlur={() => setShowDropdown(false)}
                  isClearable
                />
              </div>
            </InputGroup>
          )}
          <Button
            color="primary"
            onClick={() =>
              plan?.plan_detail?.plan_name &&
              plan?.plan_detail?.plan_name !== "Free Plan"
                ? navigate("/" + admin + "/Purchaseplandetail")
                : navigate("/" + admin + "/Plans")
            }
            size="sm"
            style={{
              fontSize: "12px",
              fontWeight: "500",
            }}
            className="p-2 mr-3 background-colorsty bgtextwhite fontfamilysty"
          >
            {loader ? (
              <Loader color="#ffffff" />
            ) : plan?.plan_detail?.plan_name &&
              plan?.plan_detail?.plan_name !== "Free Plan" ? (
              plan?.plan_detail?.plan_name.split(" ")[0]
            ) : (
              "Buy"
            )}
          </Button>
          <FormGroup
            className="mb-1"
            onClick={handleClick}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {notificationCount === 0 ? (
              <i className="far fa-bell" style={{ fontSize: "30px" }}></i>
            ) : (
              <img src={notify} width={30} height={30} />
            )}
          </FormGroup>
          <UncontrolledDropdown
            className="d-flex d-lg-none"
            style={{ border: "none", background: "none", boxShadow: "none" }}
          >
            <DropdownToggle
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              className="m-0 p-0 ml-3"
            >
              <Media className="align-items-center">
                <span
                  className="d-flex justify-content-center align-items-center background-colorsty bgtextwhite
fontfamilysty"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "12px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {loader ? (
                    <Loader color="#ffffff" />
                  ) : (
                    `${accessType?.first_name
                      ?.slice(0, 1)
                      .toUpperCase()}${accessType?.last_name
                      ?.slice(0, 1)
                      .toUpperCase()}`
                  )}
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow d-lg-none" right>
              <DropdownItem className="noti-title w-100" header tag="div">
                <h6
                  className="text-overflow m-0"
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                >
                  Welcome
                </h6>
              </DropdownItem>
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                onClick={() => navigate("/" + admin + "/user-profile")}
              >
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                onClick={() => navigate("/" + admin + "/Userrole")}
              >
                {/* < i className="fa-solid fa-user-pen"/> */}
                {/* <img src={userpermission} width={15} height={15} /> */}
                <ContactPageIcon />
                <span>User permission</span>
              </DropdownItem>
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                onClick={() => navigate("/" + admin + "/activities")}
              >
                <AssignmentIcon />
                <span>Activities</span>
              </DropdownItem>
              {currentPlan !== "Free Plan" && (
                <DropdownItem
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginLeft: "35px",
                  }}
                  className="text-overflow m-0"
                  onClick={() => navigate("/" + admin + "/settings")}
                >
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
              )}

              <DropdownItem divider />
              <DropdownItem
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginLeft: "35px",
                }}
                className="text-overflow m-0"
                to="/auth/login"
                onClick={Logout}
                tag={Link}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <Modal
          isOpen={isModalOpen}
          toggle={closeModal}
          style={{ maxWidth: "400px", borderRadius: "20px" }}
        >
          <ModalHeader
            toggle={closeModal}
            className=" ml-2 mr-3 mt-2 p-0 pl-3 pr-3 pt-3 textcolorblue fontfamilysty"
          >
            <h5
              style={{
                fontSize: "18px",
                fontweight: "500",
              }}
            >
              Notification
            </h5>
          </ModalHeader>
          <ModalBody>
            <Notificationmodal
              notifications={notificationData}
              handlePropertySelect={handlePropertySelect}
              readNotification={readAdminNotification}
              role={`admin`}
              closeModal={closeModal}
            />
          </ModalBody>
        </Modal>
      </Navbar>
    </>
  );
};
export default AdminNavbar;
