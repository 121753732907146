import React, { useState, useEffect } from 'react'
import {
	Button,
	Card,
	CardHeader,
	FormGroup,
	Container,
	Row,
	Col,
	UncontrolledDropdown,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Table,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Modal,
	Form,
	Input,
	Label,
	CardBody,
} from "reactstrap";
import BaseButton from 'components/ReusableComponents/BaseButton';
import ClearIcon from "@mui/icons-material/Clear";
import DocumentFileModal from './DocumentFileModal';
import moment from 'moment';
import axios from "axios";
import interceptor from "plugins/axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileText, faFileWord, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { RotatingLines } from "react-loader-spinner";
import { v4 as uuidv4 } from "uuid";

export default function RentDocument(props) {

	const lease_id = props.leaseId;
	const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;

	const [documentdropdownOpen, setDocumentdropdownOpen] = useState(false);
	const toggle2 = () => setDocumentdropdownOpen((prevState) => !prevState);

	const [documentTypeDropdownOpen, setDocumentTypeDropdownOpen] = useState(false);
	const documentTypeDropDownToggle = () => setDocumentTypeDropdownOpen((prevState) => !prevState);


	const [pageItem, setPageItem] = React.useState(10);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [totalPages, setTotalPages] = React.useState(1);
	const [showAddModal, setShowAddModal] = useState(false);
	const [fileModalOpen, setFileModalOpen] = useState(null);

	const [sendLoader, setSendLoader] = useState(false);

	const [documentName, setDocumentName] = useState("");
	const [documentType, setDocumentType] = useState("Insurance Policy Docs");
	const [documentFile, setDocumentFile] = useState(null);
	const [documentFileType, setDocumentFileType] = useState(null);
	const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
	const [viewerModalOpen, setViewerModalOpen] = useState(null);
	const [viewFile, setViewFile] = useState(null);
	const [viewFileType, setViewFileType] = useState(null);

	const [documentList, setDocumentList] = useState([]);
	const [tableDocList, setTableDocList] = useState([]);

	const [loader, setLoader] = useState(false);

	const documentTypeList = [
		"Insurance policy Docs",
		"Lease agreements",
		"Lease renewal offers",
		"Lease renewal letters"
	];

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleClickOpen = () => {
		setShowAddModal(true);
	};

	const getFileExtension = (filename) => {
		const lastDotIndex = filename.lastIndexOf('.');
		return lastDotIndex === -1 ? '' : filename.substring(lastDotIndex + 1);
	}

	const handleAddNewDocument = async () => {

		if (!documentName || !documentType || !documentFile) {
			return;
		}

		try {
			let file = "";

			setUploadDocumentLoading(true);

			if (documentFile instanceof File) {
				try {
					const form = new FormData();
					form.append("files", documentFile);

					const res = await axios.post(`${imageUrl}/images/upload`, form, {
						headers: {
							'Content-Type': documentFile.type, // Ensure the MIME type is set
						},
					});

					if (res && res.data && res.data.files && res.data.files.length > 0) {
						file = res.data.files[0].filename;
					} else {
						console.error("Unexpected response format:", res);
					}
				} catch (error) {
					console.error("Error uploading file:", error);
				}
			} else {
				file = documentFile;
			}
			// const document_id = Date.now();
			const fileExtension = getFileExtension(documentFile.name);

			let data = {
				document_id: uuidv4(),
				lease_id: lease_id,
				file_name: documentName,
				file_type: documentType,
				document_name: file,
				document_type: documentFile.type,
				created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
				created_by: "created_by"
			};

			await interceptor.post("lease-document/add-document", data);
			toast.success("Document added successfully");


			setDocumentName("");
			setDocumentType("Insurance Policy Docs");
			setDocumentFile(null);

			if (refetch) refetch();
			handleCloseNewDocument();

		} catch (e) {
			let error = e;
			console.error("Error during file upload:", error);
			if (typeof error !== "string") error = JSON.stringify(error);
			toast.error(error);
		} finally {
			setUploadDocumentLoading(false);
		}
	}

	const handleCloseNewDocument = () => {
		setShowAddModal(false);
		setDocumentFile(null);
	};

	const refetch = async () => {
		try {
			const result = await interceptor.get(`lease-document/get-documents/${lease_id}`);
			// console.log(JSON.stringify(result.data.documents));
			// debugger;
			setDocumentList(result.data.documents);
			setTotalPages(Math.ceil(result.data.documents.length / pageItem));
			setCurrentPage(1);
		} catch (error) {
		}
	}

	useEffect(() => {
		refetch();
	}, [])

	useEffect(() => {
		let startIndex = (currentPage - 1) * pageItem;
		if (currentPage < totalPages)
			setTableDocList(documentList.slice(startIndex, startIndex + pageItem));
		if (currentPage == totalPages)
			setTableDocList(documentList.slice(startIndex));
	}, [currentPage, documentList, pageItem])

	useEffect(() => {
	}, [tableDocList])


	const downloadFile = async (item) => {
		try {
			const response = await interceptor.post(
			`${imageUrl}/images/get-file-with-type/`,
			{
				fileName: item.document_name,
				contentType: item.document_type,
			},
			{
				responseType: "blob",
			}
			);
	
			const blob = response.data;
	
			const file = new File([blob], item.document_name, { type: blob.type });
	
			console.log("File created:", file);
	
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", item.document_name);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
	
			return file;
		} catch (error) {
			console.error('Error downloading file:', error);
		}
	}

	const showDocument = async (item) => {
		try {
			setLoader(true);
			const file = await downloadFile(item);
			setViewerModalOpen(file);
			setViewFile(file);
			setViewFileType(item.document_type);
			setLoader(false);
		} catch (error) {
			setLoader(false);
		}
	}

	const classifyFileType = (mimeType) => {
		const mimeTypeMapping = {
			//docs
			"application/pdf": "pdf",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
			"application/msword": "doc",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
			"application/vnd.ms-excel": "xls",
			"text/plain": "txt",
			"application/vnd.ms-powerpoint": "ppt",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
			// images
			"image/jpeg": "jpeg",
			"image/png": "png",
			"image/gif": "gif",
			"image/bmp": "bmp",
			"image/webp": "webp",
		};

		return mimeTypeMapping[mimeType] || "unknown";
	}

	const getDocIcon = (extension) => {
		// { faFile, faFileExcel, faFilePdf, faFilePowerpoint, faFileText, faFileWord } 
		switch (extension) {
			case "pdf":
				return faFilePdf;
			case "doc":
			case "docx":
				return faFileWord;
			case "xls":
			case "xlsx":
				return faFileExcel;
			case "ppt":
			case "pptx":
				return faFilePowerpoint;
			case "txt":
				return faFileText;
			case "jpeg":
			case "png":
			case "gif":
			case "bmp":
			case "webp":
				return faFileImage;
			default:
				return faFile;
		}
	}


	return (
		<div>
			<Card
				className=""
				style={{
					border: "1px solid #324567",
					boxShadow: "0px 4px 4px 0px #00000040",
				}}
			>
				<div className=' mt-4 '></div>
				<CardHeader
					className="border-0 labelfontstyle textcolorblue fontfamilysty"
					style={{
						fontSize: "18px",
						fontWeight: "600",
					}}
				>
					<Row>
						<Col className="d-flex">
							<Button
								className="ml-auto fontstylerentr background-colorsty bgtextwhite fontfamilysty"
								onClick={handleClickOpen}
								size="small"
								style={{
									fontSize: "16px",
									fontWeight: "500",
									boxShadow:
										"0px 4px 4px 0px #00000040",
									borderRadius: "4px",
									border: "0",
								}}
							>
								Add document
							</Button>
						</Col>
					</Row>
				</CardHeader>

				<br />
				<div className="px-4 mb-4">
					<Table
						className="align-items-center table-flush"
						responsive
						style={{ width: "100%" }}
					>
						<thead
							className=""
							style={{
								background: "none",
								border: "0.5px solid #324567",
							}}
						>
							<tr>
								<th
									className="fontstylerentr textcolorblue fontfamilysty"
									style={{
										fontSize: "12px",
										fontWeight: "500",
										border: "0.5px solid #324567",
									}}
									scope="col"
								>
									Date
								</th>
								<th
									className="fontstylerentr textcolorblue fontfamilysty"
									style={{
										fontSize: "12px",
										fontWeight: "500",
										border: "0.5px solid #324567",
									}}
									scope="col"
								>
									File Type
								</th>
								<th
									className="fontstylerentr textcolorblue fontfamilysty"
									style={{
										fontSize: "12px",
										fontWeight: "500",
										border: "0.5px solid #324567",
									}}
									scope="col"
								>
									Document Name
								</th>
								<th
									className="fontstylerentr textcolorblue fontfamilysty"
									style={{
										fontSize: "12px",
										fontWeight: "500",
										border: "0.5px solid #324567",
									}}
									scope="col"
								>
									Document Type
								</th>
								<th
									className="fontstylerentr textcolorblue fontfamilysty"
									style={{
										fontSize: "12px",
										fontWeight: "500",
										border: "0.5px solid #324567",
									}}
									scope="col"
								>
									Created By
								</th>
							</tr>
						</thead>
						<tbody>
							{tableDocList.length > 0 ?
								tableDocList.map((item, index) => {
									return <tr
										key={index}
										onClick={() => {
											showDocument(item);

										}}
										style={{
											cursor: "pointer",
											transition:
												"background-color 0.3s ease",
										}}
										onMouseEnter={(e) =>
										(e.currentTarget.style.backgroundColor =
											"#f0f0f0")
										}
										onMouseLeave={(e) =>
										(e.currentTarget.style.backgroundColor =
											"transparent")
										}
									>
										<td
											className="fontstylerentmodal textcolorblue fontfamilysty"
											style={{
												fontSize: "14px",
												fontWeight: "500",
												border: "0.5px solid #324567",
											}}
										>
											{item.date_created}
										</td>
										<td
											className="fontstylerentmodal fontfamilysty text-center"
											style={{
												fontWeight: "500",
												fontSize: "14px",
												border: "0.5px solid #324567",
											}}
										>
											{/* {
											switch (classifyFileType(item.document_type)) {
												case value:
													
													break;
											
												default:
													break;
											}
										} */}
											<FontAwesomeIcon icon={getDocIcon(classifyFileType(item.document_type))} />
										</td>
										<td
											className="fontstylerentmodal textcolorblue fontfamilysty"
											style={{
												fontSize: "14px",
												fontWeight: "500",
												border: "0.5px solid #324567",
											}}
										>
											{item.file_name}
										</td>
										<td
											className="fontstylerentmodal textcolorblue fontfamilysty"
											style={{
												fontSize: "14px",
												fontWeight: "500",
												border: "0.5px solid #324567",
											}}
										>
											{item.file_type}
										</td>
										<td
											className="fontstylerentmodal fontfamilysty"
											style={{
												fontWeight: "500",
												fontSize: "14px",
												border: "0.5px solid #324567",
											}}
										>
											{item.adminDetails.first_name + " " + item.adminDetails.last_name}
										</td>
									</tr>
								})
								: (
									<tr>
										<td
											className="textcolorblue "
											colSpan="10"
											style={{
												textAlign: "center",
												border: "0.5px solid #324567",
												fontSize: "16px",
												fontFamily: "600",
											}}
										>
											No records found
										</td>
									</tr>
								)}
						</tbody>
					</Table>
					{tableDocList.length > 0 ? (
						<Row>
							<Col className="text-right mt-3 mb-3">
								<Dropdown
									isOpen={documentdropdownOpen}
									toggle={toggle2}
								>
									<DropdownToggle
										className="paginationbtnstyle"
										caret
									>
										{pageItem}
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem
											onClick={() => {
												setPageItem(10);
												setCurrentPage(1);
											}}
										>
											10
										</DropdownItem>
										<DropdownItem
											onClick={() => {
												setPageItem(25);
												setCurrentPage(1);
											}}
										>
											25
										</DropdownItem>
										<DropdownItem
											onClick={() => {
												setPageItem(50);
												setCurrentPage(1);
											}}
										>
											50
										</DropdownItem>
										<DropdownItem
											onClick={() => {
												setPageItem(100);
												setCurrentPage(1);
											}}
										>
											100
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Button
									className="p-0"
									style={{ backgroundColor: "#d0d0d0" }}
									onClick={() =>
										handlePageChange(currentPage - 1)
									}
									disabled={currentPage === 1}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										fill="currentColor"
										className="bi bi-caret-left"
										viewBox="0 0 16 16"
									>
										<path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
									</svg>
								</Button>
								<span>
									Page {currentPage} of {totalPages}
								</span>{" "}
								<Button
									className="p-0"
									style={{ backgroundColor: "#d0d0d0" }}
									onClick={() =>
										handlePageChange(currentPage + 1)
									}
									disabled={currentPage === totalPages}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										fill="currentColor"
										className="bi bi-caret-right"
										viewBox="0 0 16 16"
									>
										<path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
									</svg>
								</Button>
							</Col>
						</Row>
					) : (
						<></>
					)}
				</div>
			</Card>
			<Modal
				isOpen={showAddModal}
				style={{ borderRadius: "15px", minxWidth: "600px", maxWidth: "800px" }}
				toggle={() => setShowAddModal(!showAddModal)}
				className="modal-dialog-scrollable"
			>
				<ModalHeader className=" text-white">
					<strong
						className="barforsummaryrent textcolorblue fontfamilysty"
						style={{
							fontSize: "20px",
							fontWeight: "600",
						}}
					>
						Add Document
					</strong>
				</ModalHeader>
				<ModalBody>
					<div
						className="labelfontstyle textcolorblue fontfamilysty"
						style={{
							fontSize: "16px",
							fontWeight: "600",
						}}
					>
						<Row className="mt-3">
							<Col
								className="textcolorblue fontfamilysty"
								lg={12}
								style={{
									fontSize: "16px",
									fontWeight: "600",
								}}
							>
								Name <span className=' text-red'>*</span>
							</Col>
						</Row>
						<Row>
							<Col
								className="titleecolor fontfamilysty"
								style={{
									fontSize: "16px",
									fontWeight: "600",
								}}
								lg={12}
							>
								<FormGroup>
									<Input
										style={{
											boxShadow: "0px 4px 4px 0px #00000040",
											borderRadius: "6px",
										}}
										className="form-control-alternative fontstylerentr inputwidthrent titleecolor"
										id="input-reserve"
										placeholder="Enter name"
										type="text"
										name="documentname"
										value={documentName}
										onChange={(e) => {
											const inputValue = e.target.value;
											setDocumentName(inputValue);
										}}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col
								className="textcolorblue fontfamilysty"
								lg={12}
								style={{
									fontSize: "16px",
									fontWeight: "600",
								}}
							>
								Document Type <span className=' text-red'>*</span>
							</Col>
						</Row>
						<Row>
							<Col
								className="titleecolor fontfamilysty"
								style={{
									fontSize: "16px",
									fontWeight: "600",
								}}
								lg={12}
							>
								<FormGroup>
									<Dropdown
										className="dropdownfontsyle"
										isOpen={documentTypeDropdownOpen}
										toggle={documentTypeDropDownToggle}
									>
										<DropdownToggle
											caret
											className="leasetypee dropdownfontsyle titleecolor"
											style={{
												width: "100%",
												boxShadow: " 0px 4px 4px 0px #00000040",
												border: "1px solid #ced4da",

												backgroundColor: "transparent",
											}}
										>
											{documentType}
											&nbsp;&nbsp;&nbsp;&nbsp;
										</DropdownToggle>
										<DropdownMenu
											className="dropdownfontsyle"
											style={{ width: "100%" }}
										>
											{
												documentTypeList.map((item, index) => {
													return <DropdownItem
														key={index}
														className="dropdownfontsyle"
														onClick={() => {
															setDocumentType(item);
														}}
													>
														{item}
													</DropdownItem>
												})
											}
										</DropdownMenu>
									</Dropdown>
								</FormGroup>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col
								className="textcolorblue fontfamilysty"
								lg={4}
								style={{
									fontSize: "16px",
									fontWeight: "600",
								}}
							>
								Upload file <span className=' text-red'>*</span>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col>
								<div className="d-flex align-items-center">
									<input
										type="file"
										className="form-control-file d-none"
										accept=".pdf, .txt, .doc, .docx, .jpg, .jpeg, .png"
										name="upload_file"
										id="upload_file"
										multiple={false}
										onChange={(e) => {
											setDocumentFile(e.target.files[0]);
											setDocumentFileType(e.target.files[0].type);
										}}
									/>
									<label
										htmlFor="upload_file"
										className=" mr-3 px-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
										style={{
											borderRadius: "5px",
											padding: "8px",
											cursor: "pointer",
										}}
									>
										Choose File
									</label>
									{documentFile && (
										<>
											<p
												style={{
													cursor: "pointer",
													color: "blue",
												}}
												onClick={() => {
													setFileModalOpen(documentFile);
												}}
											>
												{documentFile?.name}
											</p>
											<ClearIcon
												style={{
													cursor: "pointer",
													alignSelf: "flex-start",
													position: "relative",
													top: "-12px",
												}}
												onClick={() => setDocumentFile(null)}
											/>
										</>
									)}
									{fileModalOpen && (
										<DocumentFileModal
											open={fileModalOpen}
											setOpen={setFileModalOpen}
											file={documentFile}
											fileType={documentFileType}
										/>
									)}
								</div>
							</Col>
						</Row>
					</div>
				</ModalBody>
				<ModalFooter>
					<BaseButton
						onClick={handleCloseNewDocument}
						disabled={uploadDocumentLoading}
						variant="outline"
						label="Cancel"
					/>
					<BaseButton
						onClick={handleAddNewDocument}
						loading={uploadDocumentLoading}
						label="Ok"
					/>
				</ModalFooter>
			</Modal>

			{viewerModalOpen && viewFile && (
				<DocumentFileModal
					open={viewerModalOpen}
					setOpen={setViewerModalOpen}
					file={viewFile}
					fileType={viewFileType}
				/>
			)}
			{
				loader && <div style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.2)', // Gray background with opacity
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					zIndex: 1000, // Ensure it appears above other elements
				}}>
					<RotatingLines
						strokeColor="grey"
						strokeWidth="5"
						animationDuration="0.75"
						width="50"
						visible={true}
					/>
				</div>
			}
		</div>
	)
}
