import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Col, Row } from "reactstrap";
import axiosInstance from "axiosInstance";
import PoliciesTable from "components/PoliciesTable";

const PoliciesExpiringComponent = ({ adminId}) => {
  const [policies, setPolicies] = useState([]);
  const [insuranceLoader, setInsuranceLoader] = useState(false);
  const [totalPolicies, setTotalPolicies] = useState(0);
  const [insurancePage, setInsurancePage] = useState(0);
  const [insuranceRowsPerPage, setInsuranceRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchPolicies = async (page, limit, admin_id) => {
      setInsuranceLoader(true);
      try {
        page = parseInt(page)+1;
        const response = await axiosInstance.get(`/renter-insurance/report/${admin_id}`, {
          params: { page, limit, sort: "asc" },
        });
        setPolicies(response.data.data);
        setTotalPolicies(response.data.metadata.total);
        console.log("Pagination changed:");
      } catch (error) {
        console.error("Error fetching policies:", error);
      } finally {
        setInsuranceLoader(false);
      }
    };

    if (adminId) {
      fetchPolicies(insurancePage, insuranceRowsPerPage, adminId);
    }
  }, [insurancePage, insuranceRowsPerPage, adminId]);

  return (
    <div style={{width: "100%"}}>
      <Row>
          <Col 
            className="mx-3"
          >
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <span className="d-flex justify-content-center align-items-center" style={{
                          height: "50px",
                          backgroundColor: "#324B77",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px 0",
                          fontSize: "16px",
                          width: "100%"
                        }}>Policies Expiring in the Next 90 Days</span>
                </Typography>

                {policies.length === 0 ? (
                  <Typography>No policies are expiring within 90 days.</Typography>
                ) : (
                  <PoliciesTable
                    policies={policies}
                    insuranceLoader={insuranceLoader}
                    totalPolicies={totalPolicies}
                    insurancePage={insurancePage}
                    insuranceRowsPerPage={insuranceRowsPerPage}
                    setInsurancePage={setInsurancePage}
                    setInsuranceRowsPerPage={setInsuranceRowsPerPage}
                  />
                )}
              </CardContent>

          </Col>
      </Row>
    </div>
  );
};

export default PoliciesExpiringComponent;