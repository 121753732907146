import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Col, FormGroup, Input, Row } from "reactstrap";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
  p: 4,
};

export default function EmailDialogModal({
  open,
  handleCloseEmailModal,
  emailData,
}) {

//   const olElement = document.createElement("div");
//   olElement.innerHTML = emailData?.body || "";
//   const emailBody = olElement.textContent;

  return (
    <Modal
      open={open}
      onClose={handleCloseEmailModal}
      aria-labelledby="email-modal-title"
      aria-describedby="email-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="email-modal-title"
          variant="h5"
          component="h2"
          style={{
            marginBottom: "20px",
            color: "#333",
            fontWeight: "bold",
          }}
        >
          Email Details
        </Typography>
        <div>
          {/* Subject */}
          <Row className="mb-3 align-items-center">
            <Col md={3}>
              <label
                htmlFor="email_subject"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  marginBottom: "0",
                }}
              >
                Subject:
              </label>
            </Col>
            <Col md={9}>
              <Input
                id="email_subject"
                type="text"
                value={emailData?.subject || ""}
                disabled
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  padding: "8px",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                }}
              />
            </Col>
          </Row>

          {/* To */}
          <Row className="mb-3 align-items-center">
            <Col md={3}>
              <label
                htmlFor="email_to"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  marginBottom: "0",
                }}
              >
                To:
              </label>
            </Col>
            <Col md={9}>
              <div
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  padding: "8px",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                  minHeight: "50px",
                  maxHeight: "150px",
                  overflowY: "auto",
                }}
              >
                {emailData?.to?.map((recipient, idx) => (
                  <div key={idx} style={{ marginBottom: "4px" }}>
                    {recipient}
                  </div>
                ))}
              </div>
            </Col>
          </Row>

          {/* From */}
          <Row className="mb-3 align-items-center">
            <Col md={3}>
              <label
                htmlFor="email_from"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  marginBottom: "0",
                }}
              >
                From:
              </label>
            </Col>
            <Col md={9}>
              <Input
                id="email_from"
                type="text"
                value={emailData?.from || ""}
                disabled
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  padding: "8px",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                }}
              />
            </Col>
          </Row>

          {/* Opened */}
          <Row className="mb-3 align-items-center">
            <Col md={3}>
              <label
                htmlFor="email_open"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                  marginBottom: "0",
                }}
              >
                Opened:
              </label>
            </Col>
            <Col md={9}>
              <Input
                id="email_open"
                type="text"
                value={
                  emailData?.opens?.[0]?.openedAt
                    ? `${moment(new Date(Number(emailData.opens[0].openedAt))).format(`YYYY-MM-DD HH:mm:ss`)}`
                    : "Not Opened"
                }
                disabled
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  padding: "8px",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                }}
              />
            </Col>
          </Row>

          {/* Body */}
          <Row>
            <Col md={12}>
              <FormGroup className="w-100">
                <label
                  className="form-control-label"
                  htmlFor="email_body"
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    marginBottom: "8px",
                  }}
                >
                  Body:
                </label>
                 <div
                id="email_body"
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "6px",
                  padding: "10px",
                  fontSize: "14px",
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#f9f9f9",
                  overflowY: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: emailData?.body || "" }}
              />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </Box>
    </Modal>
  );
}
