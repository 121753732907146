import {
  Button,
  Card,
  ModalHeader,
  ModalBody,
  Modal,
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useCookies } from "react-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TenantsHeader from "components/Headers/TenantsHeader";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import swal from "sweetalert";
import TenantCreditCardForm from "./TenantCreditCardForm";
import axiosInstance from "axiosInstance";
import { getUserPermission } from "../../views/source/Functions/Userrolefunction";
import { getTokenizationKey } from "plugins/ApiHandler";
import { addTokenizationScript } from "plugins/helpers";
import Loader from "components/ReusableComponents/Loader";
import { verifyToken } from "components/Functions/Functions";
import BaseButton from "components/ReusableComponents/BaseButton";
import { useDispatch } from "react-redux";
import { checkTenantToken } from "Stores/ApiHandler";
import CloseIcon from "@mui/icons-material/Close";
import { FaTimes } from "react-icons/fa";
import { Autocomplete } from "@react-google-maps/api";

const TenantFinancial = () => {
  const [Ledger, setLedger] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [userroleData, setUserroleData] = useState(null);
  const [accessType, setAccessType] = useState(null);
  const [currentPlan, setCurrentPlan] = useState("");
  const { item_id, admin } = useParams();

  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies();

  const [scriptGenerating, setScriptGenerating] = useState(false);
  const [scriptError, setScriptError] = useState("");

  const getNmiKey = async (tenant_id) => {
    setScriptGenerating(true);
    setScriptError("");

    try {
      const keyResponse = await getTokenizationKey(tenant_id);
      await addTokenizationScript(keyResponse.publicKey);
    } catch (error) {
      setScriptError(
        "Failed to load the tokenization script. Make sure you have suitable internet connection."
      );
    } finally {
      setScriptGenerating(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      try {
        const res = await axiosInstance.get(
          `/purchase/plan-purchase/${data.admin_id}`
        );
        setCurrentPlan(res.data.data.plan_detail.plan_name);
        setAccessType(data);
        getNmiKey(data.tenant_id);
      } catch (err) {
        console.error(err.message);
      }
    };
    fetchData();
  }, [navigate, location, removeCookie]);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  useEffect(() => {
    const handleGetUserPermission = async () => {
      if (accessType?.admin_id) {
        const adminData = await getUserPermission(accessType.admin_id);
        setUserroleData(adminData?.tenant_permission);
      }
    };
    if (accessType) {
      handleGetUserPermission();
    }
  }, [accessType]);

  const openCardForm = () => {
    getTenantData();
    setIsModalOpen(true);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchLedger = async () => {
    if (accessType?.tenant_id) {
      setLoader(true);
      try {
        const response = await axiosInstance.get(
          `/payment/tenant_financial/${accessType.tenant_id}`
        );
        setLedger(response.data.data);
        setTotalPages(Math.ceil(response.data.data.length / pageItem));
        const filteredData = Array.from(
          new Set(
            response.data.data.map(
              (item) => `${item?.rental_adress}, ${item?.rental_unit}`
            )
          )
        );
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchLedger();
  }, [accessType]);

  // Event handler to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const startIndex = (currentPage - 1) * pageItem;
  const endIndex = currentPage * pageItem;
  var paginatedData;
  if (Ledger) {
    const allPaymentAndCharges = Ledger.flatMap((item) => {
      if (item !== undefined) {
        return item?.paymentAndCharges?.map((payment) => ({
          paymentAndCharges: payment,
          unit: item.unit,
          unit_id: item.unit_id,
          _id: item._id,
        }));
      } else {
        return;
      }
    });
    paginatedData = allPaymentAndCharges.slice(startIndex, endIndex);
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const filterLedgerBySearch = () => {
    if (!searchQuery) {
      return Ledger;
    }

    const filteredData = Ledger.filter((item) => {
      const manualSearchString =
        item?.response && item?.payment_type
          ? `Manual ${item?.type} ${item?.response} for ${item?.payment_type}`
          : "- - - - - - - - - - - - - - - - -";

      const transactionSearchString = item?.transaction_id
        ? ` (#${item?.transaction_id})`
        : "";

      const combinedSearchString =
        `${manualSearchString} ${transactionSearchString}`.toLowerCase();

      return (
        item?.type?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.entry?.some((data) =>
          data?.account?.toLowerCase()?.includes(searchQuery.toLowerCase())
        ) ||
        item?.entry?.some((data) =>
          data?.date?.toLowerCase()?.includes(searchQuery.toLowerCase())
        ) ||
        item?.total_amount
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase()) ||
        item?.balance
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase()) ||
        combinedSearchString.includes(searchQuery.toLowerCase())
      );
    });

    return filteredData;
  };

  const filterTenantsBySearchAndPage = () => {
    const filteredData = filterLedgerBySearch();
    const paginatedData = filteredData?.slice(startIndex, endIndex);
    return paginatedData;
  };

  const deleteTenant = (payment_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete the scheduled payment!",
      icon: "warning",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          Autocomplete: "off",
          id: "delete-reason",
          oninput: (e) => {
            const reason = e.target.value;
            const deleteButton = document.querySelector(
              ".swal-button--confirm"
            );
            if (deleteButton) {
              deleteButton.disabled = reason.trim() === "";
            }
          },
        },
      },
      buttons: {
        confirm: {
          text: "Delete",
          closeModal: false,
          value: true,
          className: "swal-button--danger",
        },
        cancel: "Cancel",
      },
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const deleteReasonInput = document.getElementById("delete-reason");
        const deletionReason = deleteReasonInput
          ? deleteReasonInput.value.trim()
          : "";

        if (!deletionReason) {
          toast.error("Reason for deletion is required", {
            position: "top-center",
            autoClose: 2000,
          });
          return;
        }

        const deleteButton = document.querySelector(".swal-button--confirm");
        if (deleteButton) deleteButton.disabled = true;

        try {
          const res = await axiosInstance.delete(
            `/payment/payment/${payment_id}`,
            {
              params: { company_name: admin },
              data: { reason: deletionReason },
            }
          );

          if (res.data.statusCode === 200) {
            toast.success("Ledger Deleted Successfully", {
              position: "top-center",
              autoClose: 1000,
            });
            setTimeout(fetchLedger, 500);
            swal.close();
          } else {
            toast.warning(res.data.message || "An error occurred", {
              position: "top-center",
              autoClose: 1000,
            });
            swal.close();
          }
        } catch (error) {
          console.error("Error deleting payment:", error);
          toast.error("Error deleting payment. Please try again.", {
            position: "top-center",
          });
          swal.close();
        }
      } else {
        toast.success("Payment is safe :)", {
          position: "top-center",
          autoClose: 1000,
        });
        swal.close();
      }
    });

    // Safely disable the delete button after the modal is displayed
    const observer = new MutationObserver(() => {
      const deleteButton = document.querySelector(".swal-button--confirm");
      if (deleteButton) {
        deleteButton.disabled = true;
        observer.disconnect(); // Stop observing once the button is found
      }
    });
    observer.observe(document.body, { childList: true, subtree: true });
  };

  const [preFillData, setpreFillData] = useState({});
  const getTenantData = async () => {
    if (cookies.token) {
      try {
        const response = await dispatch(checkTenantToken(cookies.token));
        const data = response.payload;
        setpreFillData({
          first_name: data.tenant_firstName,
          last_name: data.tenant_lastName,
          email: data.tenant_email,
          phone: data.tenant_phoneNumber,
        });
      } catch (error) {}
    }
  };

  return (
    <>
      <TenantsHeader />
      <Container className="stylecontainer containerremovespace" fluid>
        <Row className="mx-1">
          {userroleData?.financial_add && (
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {currentPlan !== "Free Plan" && (
                <>
                  {scriptGenerating ? (
                    <p style={{ padding: "0px 10px" }}>NMI fields loading...</p>
                  ) : scriptError ? (
                    <p style={{ color: "red" }}>{scriptError}</p>
                  ) : (
                    <BaseButton
                      onClick={openCardForm}
                      label="Add Cards"
                    ></BaseButton>
                  )}
                  <Button
                    className="fontstylerentr background-colorsty bgtextwhite"
                    onClick={() => navigate(`/tenant/add-payment`)}
                    size="small"
                  >
                    Make Payment
                  </Button>
                </>
              )}
            </Col>
          )}
        </Row>
        <CardHeader
          className=" mt-3 mb-1 mx-3 headproptypebar background-colorsty"
          style={{
            borderRadius: "10px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
          }}
        >
          <h2
            className="mb-0 headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "26px",
            }}
          >
            Ledger
          </h2>
        </CardHeader>

        <br />
        <Row>
          <div className="col">
            <Container className="mt--10" fluid>
              <Row>
                <div className="col">
                  <Row>
                    <Col xs="12" sm="6">
                      <FormGroup className="searchstyle">
                        <Input
                          className="filterh"
                          fullWidth
                          type="text"
                          placeholder="Search here..."
                          value={searchQuery}
                          onChange={handleSearch}
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            minWidth: "200px",
                            boxShadow: "0px 4px 4px 0px #00000040",
                            border: "1px solid #ced4da",
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Table
                    className="align-items-center table-flush mt-3"
                    responsive
                    style={{ borderCollapse: "collapse", width: "100%" }}
                  >
                    {loader ? (
                      <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                        <RotatingLines
                          strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="50"
                          visible={loader}
                        />
                      </div>
                    ) : (
                      <>
                        <thead
                          className="fontfamilysty"
                          style={{
                            height: "45px",
                            fontSize: "14px",
                            fontFamily: "poppins",
                            fontWeight: "600",
                            boxShadow: " 0px 4px 4px 0px #00000040",
                          }}
                        >
                          <tr
                            style={{
                              border: "2px solid rgba(50, 69, 103, 1)",
                            }}
                          >
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                              style={{
                                borderTopLeftRadius: "15px",
                              }}
                            >
                              Date
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Tenant
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Type
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Account
                            </th>
                            {/* 6002 take only one amount column instead of the increase, decrease and adding Number */}
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Description
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Amount
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Balance
                            </th>
                            <th
                              className="tablefontstyle textcolorblue"
                              scope="col"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            style={{
                              border: "none",
                            }}
                          >
                            <td colSpan="9"></td>
                          </tr>
                          {filterTenantsBySearchAndPage()?.length > 0 ? (
                            filterTenantsBySearchAndPage().map(
                              (item, index) => (
                                <>
                                  <tr
                                    key={index}
                                    className="w-100"
                                    style={{
                                      border:
                                        "0.5px solid rgba(50, 69, 103, 1)",
                                      fontSize: "12px",
                                      height: "auto",
                                      fontFamily: "poppins",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <td className="bordertopintd tablebodyfont tabletextcolor">
                                      {item?.entry[0]?.date
                                        ? moment(item?.entry[0]?.date).format(
                                            dateFormat
                                          )
                                        : "-"}
                                    </td>
                                    <td className="bordertopintd tablebodyfont tabletextcolor">
                                      {item?.type !== "Charge"
                                        ? `${item?.tenantData?.tenant_firstName} ${item?.tenantData?.tenant_lastName}`
                                        : item?.tenantData
                                        ? `${item?.tenantData?.tenant_firstName} ${item?.tenantData?.tenant_lastName}`
                                        : "-"}
                                    </td>

                                    <td className="bordertopintd tablebodyfont tabletextcolor">
                                      {item?.type || "N/A"}
                                    </td>

                                    <td className="bordertopintd tablebodyfont tabletextcolor">
                                      {item?.entry?.map((data) => (
                                        <>
                                          {data?.account}
                                          <br />
                                        </>
                                      )) || "-"}
                                    </td>
                                    <td
                                      className="bordertopintd tablebodyfont tabletextcolor"
                                      style={{
                                        fontWeight: "500",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <React.Fragment>
                                        {item.response && item.payment_type
                                          ? `Manual ${item.type} ${item.response} for ${item.payment_type}`
                                          : item?.entry?.map(
                                              (entryItem, index) => (
                                                <React.Fragment key={index}>
                                                  {entryItem.memo}
                                                  {(index + 1) % 3 === 0 ? (
                                                    <>
                                                      <br />
                                                    </>
                                                  ) : index <
                                                    item?.entry?.length - 1 ? (
                                                    ", "
                                                  ) : (
                                                    ""
                                                  )}
                                                </React.Fragment>
                                              )
                                            ) || "-"}
                                        {(item.transaction_id ||
                                          item.check_number ||
                                          item.reference) && <br />}
                                        {item.transaction_id &&
                                          ` (#${item.transaction_id})`}
                                        {item.check_number &&
                                          ` (#${item.check_number})`}
                                        {item.reference &&
                                          ` (#reference: ${item.reference})`}
                                      </React.Fragment>
                                    </td>
                                    {/* 6002 only one amount column */}
                                    <td
                                      className="bordertopintd text-right tablebodyfont tabletextcolor"
                                      style={{
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {item?.type === "Charge" ||
                                      item?.type === "Refund"
                                        ? "$" +
                                          Number(
                                            item?.total_amount || 0
                                          ).toFixed(2)
                                        : item?.type === "Payment"
                                        ? "-$" +
                                          Number(
                                            item?.total_amount || 0
                                          ).toFixed(2)
                                        : ""}
                                    </td>
                                    <td className="bordertopintd text-right tablebodyfont tabletextcolor">
                                      {" "}
                                      {item.balance !== undefined
                                        ? item.balance >= 0
                                          ? `$${Number(
                                              item?.balance || 0
                                            ).toFixed(2)}`
                                          : `-$${Number(
                                              Math.abs(item?.balance) || 0
                                            ).toFixed(2)}`
                                        : "0"}
                                    </td>
                                    <td className="bordertopintd text-right tablebodyfont tabletextcolor">
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {item?.type === "Payment" &&
                                        item?.response === "PENDING" ? (
                                          <UncontrolledDropdown nav>
                                            <DropdownToggle
                                              className="pr-0"
                                              nav
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span
                                                className="avatar avatar-sm rounded-circle fontstylerentmodal"
                                                style={{
                                                  margin: "-20px",
                                                  background: "transparent",
                                                  color: "lightblue",
                                                  fontWeight: "bold",
                                                  border: "2px solid lightblue",
                                                  padding: "5px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                ...
                                              </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow">
                                              <DropdownItem
                                                className="fontstylerentmodal"
                                                tag="div"
                                                onClick={() =>
                                                  navigate(
                                                    `/tenant/TenantPayment/${item.payment_id}`
                                                  )
                                                }
                                              >
                                                Edit
                                              </DropdownItem>
                                              <DropdownItem
                                                className="fontstylerentmodal"
                                                onClick={() => {
                                                  deleteTenant(item.payment_id);
                                                }}
                                              >
                                                Delete
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        ) : (
                                          <div
                                            className="fontstylerentmodal"
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "bolder",
                                              paddingLeft: "5px",
                                            }}
                                          >
                                            --
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            )
                          ) : (
                            <tr
                              style={{
                                border: "2px solid rgba(50, 69, 103, 1)",
                              }}
                            >
                              <td
                                colSpan={7}
                                className="text-center bordertopintd tablebodyfont"
                              >
                                No data available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    )}
                  </Table>
                  {paginatedData?.length > 0 ? (
                    <Row>
                      <Col className="text-right mt-3 mb-3">
                        <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                          <DropdownToggle className="paginationbtnstyle" caret>
                            {pageItem}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setPageItem(10);
                                setCurrentPage(1);
                              }}
                            >
                              10
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setPageItem(25);
                                setCurrentPage(1);
                              }}
                            >
                              25
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setPageItem(50);
                                setCurrentPage(1);
                              }}
                            >
                              50
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setPageItem(100);
                                setCurrentPage(1);
                              }}
                            >
                              100
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <Button
                          className="p-0"
                          style={{ backgroundColor: "#d0d0d0" }}
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-caret-left"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                          </svg>
                        </Button>
                        <span>
                          Page {currentPage} of {totalPages}
                        </span>{" "}
                        <Button
                          className="p-0"
                          style={{ backgroundColor: "#d0d0d0" }}
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-caret-right"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
                          </svg>
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
            </Container>
            {/* </Card> */}
          </div>
        </Row>
        <ToastContainer />
      </Container>
      <Modal
        isOpen={isModalOpen}
        toggle={closeModal}
        style={{ maxWidth: "1000px", borderRadius: "20px" }}
      >
        <ModalHeader
          className="mx-3 mt-4 text-white headproptypebar background-colorsty bgtextwhite"
          style={{
            borderRadius: "6px",
            height: "45px",
            boxShadow: " 0px 4px 4px 0px #00000040 ",
            padding: "10px 7px 1px 21px",
          }}
          close={
            <button
              onClick={closeModal}
              style={{
                background: "none",
                border: "none",
                fontSize: "18px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#fff",
              }}
              aria-label="Close"
            >
              <FaTimes />
            </button>
          }
        >
          <span
            className=" align-items-center headproptypee bgtextwhite fontfamilysty"
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            Add Card
          </span>
        </ModalHeader>
        <ModalBody>
          <TenantCreditCardForm
            preFillData={preFillData}
            tenantId={accessType?.tenant_id}
            closeModal={closeModal}
            admin_id={accessType?.admin_id}
            tenantName={`${accessType?.tenant_firstName} ${accessType?.tenant_lastName}`}
            scriptGenerating={scriptGenerating}
            scriptError={scriptError}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default TenantFinancial;
