import React from "react";
import {
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState, useEffect } from "react";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { verifyToken } from "components/Functions/Functions";
import moment from "moment";
import interceptor from "plugins/axios";
import {
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Loader from "components/ReusableComponents/Loader";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--bg-blue-color)",
    color: "var(--text-white-color)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ExpiringRenterInsurancesReport = () => {
  const today = moment().format("YYYY-MM-DD");
  const oneMonthLater = moment().add(1, "month").format("YYYY-MM-DD");

  const [loading, setLoader] = useState(true);
  const { admin } = useParams();
  const [error, setError] = useState("");
  const [accessType, setAccessType] = useState(null);
  const [policies, setPolicies] = useState([]);
  const [start_date, setStartDate] = useState(today);
  const [end_date, setEndDate] = useState(oneMonthLater);
  const [userProfile, setUserProfile] = useState({});

  let navigate = useNavigate();
  const location = useLocation();

   const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const getInsuranceReport = async () => {
    try {
      setError("");
      setPolicies([]);
      setLoader(true);

      const response = await interceptor.get(
        `renter-insurance/expiring-policies?start_date=${start_date}&end_date=${end_date}`
      );
      const data = response.data;
      if (Array.isArray(data.data)) {
        const policies = data.data.map((el) => {
          return {
            ...el,
            effective_date: moment(el.effective_date).format(dateFormat),
            expiration_date: moment(el.expiration_date).format(dateFormat),
          };
        });
        setPolicies(policies);
        console.log(policies);
      } else setError(data.message);
    } catch (error) {
      setError(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getInsuranceReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date]);

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessType]);

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  // Function to generate PDF from table data
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add logo at the top
    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    // Add title
    const titleYPosition = 15 + img1Height;
    doc.setFontSize(20);
    doc.text(
      "Renters Insurance",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    // Add contact information
    const contactYPosition = titleYPosition;
    const contactInfoX = doc.internal.pageSize.getWidth() - 10; // Right-aligned with 10px margin

    doc.setFontSize(10);
    doc.text(userProfile.company_name, contactInfoX, contactYPosition, {
      align: "right",
    });

    const subtitleYPosition = contactYPosition + 10;
    doc.setFontSize(12);
    doc.text(
      `As of ${formattedDate}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    // Prepare table data
    const tableBody = policies.map((data) => [
      data.insurance_company,
      data.policy_id,
      data.effective_date,
      data.expiration_date,
      `$${data.liability_coverage}`,
      data.tenant_details
        .map((tenant) => `${tenant.tenant_firstName} ${tenant.tenant_lastName}`)
        .join(", "),
    ]);

    // Add table
    doc.autoTable({
      startY: subtitleYPosition + 10,
      head: [
        [
          "Insurance Company",
          "Policy ID",
          "Effective Date",
          "Expiration Date",
          "Liability Coverage",
          "Tenants",
        ],
      ],
      body: tableBody,
      theme: "grid",
      styles: { cellPadding: 3, fontSize: 10 },
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 11,
        fontStyle: "bold",
      },
    });

    // Add footer with timestamp and page numbers
    const pageCount = doc.getNumberOfPages();
    const footerDate = new Date();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(
        `Generated on: ${footerDate.toISOString().split("T")[0]}`,
        10,
        doc.internal.pageSize.getHeight() - 8
      );
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() - 10,
        doc.internal.pageSize.getHeight() - 8,
        { align: "right" }
      );
    }

    // Save the PDF
    doc.save("Renters-Insurance.pdf");
  };

  // Function to generate Excel from table data
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();

    // Create the worksheet with heading
    const worksheetData = [["Insurance Report"]];
    worksheetData.push([userProfile.company_name]);
    worksheetData.push([`As of ${formattedDate}`]);
    worksheetData.push([""]);

    worksheetData.push([
      "Insurance Company",
      "Policy ID",
      "Compnay Phone",
      "Effective Date",
      "Expiration Date",
      "Liability Coverage",
      "Tenants",
    ]);

    console.log(policies);

    // Add tenant data for each insurance record
    if (Array.isArray(policies) && policies.length > 0) {
      policies.forEach((data) => {
        worksheetData.push([
          data.insurance_company,
          data.policy_id,
          data.insurance_company_phone_number,
          data.effective_date,
          data.expiration_date,
          `$${data.liability_coverage.toFixed(2)}`,
          data.tenant_details
            .map(
              (tenant) => `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
            )
            .join(", "),
        ]);
      });
    } else worksheetData.push(["No record found"]);

    const ws = XLSX.utils.aoa_to_sheet(worksheetData);

    // Column width configuration
    const wscols = [
      { wch: 30 }, // Insurance Company
      { wch: 25 }, // Policy ID
      { wch: 20 }, // Effective Date
      { wch: 20 }, // Expiration Date
      { wch: 20 }, // Liability Coverage
      { wch: 30 }, // Tenants
    ];

    ws["!cols"] = wscols;

    // Apply bold style to insurance company rows
    worksheetData.forEach((row, rowIndex) => {
      if (rowIndex % 2 === 0 && rowIndex > 0) {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
        if (!ws[cellAddress]) ws[cellAddress] = { t: "s" };
        ws[cellAddress].s = {
          font: {
            bold: true,
          },
        };
      }
    });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Renters Insurance");

    // Write the workbook to an array and save it as a file
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "renters-insurance.xlsx"
    );
  };

  // Function to generate CSV from table data
  const generateCSV = () => {
    const worksheetData = [
      ["Insurance Report"],
      [userProfile.company_name],
      [`As of ${formattedDate}`],
      [""],
      [
        "Insurance Company",
        "Policy ID",
        "Company Phone",
        "Effective Date",
        "Expiration Date",
        "Liability Coverage",
        "Tenants",
      ],
    ];

    // Add tenant data for each insurance record
    if (Array.isArray(policies) && policies.length > 0) {
      policies.forEach((data) => {
        worksheetData.push([
          data.insurance_company,
          data.policy_id,
          data.insurance_company_phone_number,
          data.effective_date,
          data.expiration_date,
          `$${data.liability_coverage.toFixed(2)}`,
          data.tenant_details
            .map(
              (tenant) => `${tenant.tenant_firstName} ${tenant.tenant_lastName}`
            )
            .join(", "),
        ]);
      });
    } else worksheetData.push(["No record found"]);

    // Create CSV data from the worksheet
    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
    const csv = XLSX.utils.sheet_to_csv(ws, { FS: ",", RS: "\n", strip: true });

    // Create the CSV Blob and save the file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "renters-insurance.csv");
  };

  return (
    <>
      <Header />

      <Container className="stylecontainer" fluid style={{ marginTop: "2rem" }}>
        <Row className="mb-3">
          <Col xs="6" sm="6">
            <FormGroup
              className="searchspace searchstyle"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <div>
                <strong>Start Date</strong>
                <Input
                  className="filterh"
                  type="date"
                  value={start_date}
                  onChange={(e) => setStartDate(e.target.value)}
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    minWidth: "200px",
                    fontWeight: "500",
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div>
                <strong>End Date</strong>
                <Input
                  className="filterh"
                  type="date"
                  value={end_date}
                  onChange={(e) => setEndDate(e.target.value)}
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    minWidth: "200px",
                    fontWeight: "500",
                    boxShadow: " 0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                  }}
                  min={start_date}
                />
              </div>
            </FormGroup>
          </Col>
          <Col className="d-flex justify-content-end addtotnstyle">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle
                className="background-colorsty bgtextwhite fontfamilysty"
                caret
                style={{
                  marginBottom: "12px",
                  fontSize: "16px",
                  border: "1px solid #152B5180",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  fontWeight: "500",
                }}
              >
                Exports
              </DropdownToggle>
              <DropdownMenu style={{ minWidth: "120px" }}>
                <DropdownItem
                  className="textcolorblue fontfamilysty"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => handleOptionClick("PDF")}
                >
                  PDF
                </DropdownItem>
                <DropdownItem
                  className="textcolorblue fontfamilysty"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => handleOptionClick("XLSX")}
                >
                  XLSX
                </DropdownItem>
                <DropdownItem
                  className="textcolorblue fontfamilysty"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  onClick={() => handleOptionClick("CSV")}
                >
                  CSV
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Insurance Company</StyledTableCell>
                <StyledTableCell>Effective Date</StyledTableCell>
                <StyledTableCell>Expiration Date</StyledTableCell>
                <StyledTableCell>Tenants</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                // Show Loader
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : policies.length > 0 ? (
                // Render Rows
                policies.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.insurance_company}</StyledTableCell>
                    <StyledTableCell>{row.effective_date}</StyledTableCell>
                    <StyledTableCell>{row.expiration_date}</StyledTableCell>
                    <StyledTableCell>
                      {row.tenant_details.map((tenant, i) => (
                        <span>{`${tenant.tenant_firstName} ${
                          tenant.tenant_lastName
                        } ${
                          i !== row.tenant_details.length - 1 ? ", " : ""
                        }`}</span>
                      ))}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : error ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    {error}
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    No report found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default ExpiringRenterInsurancesReport;
