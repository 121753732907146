import React, { useEffect, useState } from "react";
import swal from "sweetalert";
const { Modal, ModalBody } = require("reactstrap");

const FileModal = ({ open, setOpen, file }) => {
  const cdnUrl = process.env.REACT_APP_IMAGE_GET_URL;

  const fileURL =
    file instanceof File ? URL.createObjectURL(file) : `${cdnUrl}/${file}`;

  const fileType =
    file?.type ||
    (typeof file === "string" ? `application/${file?.split(".").pop()}` : "");

  const renderContent = () => {
    if (!fileURL) return <p>No file to display.</p>;

    if (fileType?.includes("image/") || fileURL?.match(/\.(jpg|jpeg|png)$/i)) {
      // return <img src={fileURL} width={"100%"} alt="Image" />;
      return (
        <div style={{ position: "relative", textAlign: "center", display: "inline-block" }}>
          <img src={fileURL} width={"100%"} alt="Image" />
          <a
            href={fileURL}
            download="image.jpg"
            className="btn btn-primary"
            style={{
              position: "absolute",
              top: "10px",
              left: "90%",
              transform: "translateX(-50%)",
              zIndex: 1,
            }}
          >
            Download Image
          </a>
        </div>
      );
    } else if (fileType === "application/pdf" || fileURL?.match(/\.pdf$/i)) {
      window.open(fileURL, "_blank", "noopener,noreferrer");
      setOpen(false);
      return null;
    } else if (
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileURL?.match(/\.(doc|docx)$/i)
    ) {
      // Use Microsoft Office Online Viewer to open .doc/.docx in a new tab
      const officeViewerURL = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
        fileURL
      )}`;
      window.open(officeViewerURL, "_blank", "noopener,noreferrer");
      setOpen(false);
      return null;
    } else {
      swal({
        text: "The file type is not supported for preview. Download it to view",
        icon: "warning",
        buttons: {
          download: {
            text: "Download File",
            value: "download",
            className: "btn btn-primary",
          },
          cancel: {
            text: "Cancel",
            value: "cancel",
            visible: true,
            className: "btn btn-primary",
          },
        },
      }).then((value) => {
        if (value === "download") {
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = "filename.extension";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
        }
        setOpen(false);
      });

      return null;
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={() => setOpen(!open)}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ModalBody className="p-0 m-0">{renderContent()}</ModalBody>
    </Modal>
  );
};

export default FileModal;
