import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CardHeader,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
} from "reactstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip, Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosInstance from "axiosInstance";
import axios from "axios";
import { useCookies } from "react-cookie";
import { verifyToken } from "components/Functions/Functions";
import * as yup from "yup";
import zxcvbn from "zxcvbn";
import { Info } from "@mui/icons-material";
import swal from "sweetalert";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const VendorPassChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [accessType, setAccessType] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [currentPassword, setCurrentPassword] = useState("");

  const passwordValidation = yup
    .string()
    .min(12, "Password must be at least 12 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .notOneOf(
      [/1234/, /abcd/, /qwerty/, /password/, /\d{4}/, /[a-z]{4}/],
      'Avoid sequential or repeating patterns like "1234", "abcd", etc.'
    )
    .required("Password is required");

  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    try {
      await passwordValidation.validate(newPassword);
      if (newPassword !== confirmPassword) {
        setError("Passwords do not match");
      } else {
        handleChangePassword();
      }
    } catch (validationError) {
      setError(validationError.message);
    }
  };

  const handleChangePassword = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.put(
        `/vendor/reset_password/${accessType?.vendor_email}`,
        { currentPassword, password: newPassword }
      );

      if (response.status === 200) {
        toast.success("Password Changed Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setTimeout(() => navigate(`/vendor/VendordashBoard`), 2000);
      } else if (response.status === 203) {
        swal({
          text: response.data.message,
          icon: "warning",
        });
      } else {
        setError(response.data.message);
        toast.error("Failed To Change Password", {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred while changing the password");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container className="stylecontainer" fluid style={{ marginTop: "4rem" }}>
        <Row>
          <div className="col">
            <CardHeader
              className="mt-3 mb-3 headproptypebar"
              style={{
                backgroundColor: "#152B51",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="mb-0 headproptypee"
                style={{
                  color: "#ffffff",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Change Password
              </h2>
            </CardHeader>
          </div>
        </Row>
        <Form
          role="form"
          onSubmit={handleSubmit}
          className="ml-3"
          style={{ width: "30%" }}
        >
          {/* Current Password Field */}
          <FormGroup className="mt-3 mb-4">
            <label
              className="m-0"
              htmlFor="currentPassword"
              style={{
                fontWeight: "500",
                fontFamily: "Poppins",
                fontSize: "16px",
              }}
            >
              Current Password
            </label>
            <InputGroup
              className="input-group-alternative"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                border: "1px solid #ced4da",
              }}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Current Password"
                id="currentPassword"
                value={currentPassword}
                autoComplete="off"
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <IconButton
                style={{ padding: "7px" }}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              >
                {showCurrentPassword ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </IconButton>
            </InputGroup>
          </FormGroup>

          {/* New Password Field */}
          <FormGroup className="mt-3 mb-4">
            <label
              className="m-0"
              htmlFor="inputPassword4"
              style={{
                fontWeight: "500",
                fontFamily: "Poppins",
                fontSize: "16px",
              }}
            >
              Enter your new password
              <Tooltip
                title={
                  <Box
                    style={{
                      fontSize: "12px",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <ul style={{ paddingLeft: "20px", margin: 0 }}>
                      <li>At least one uppercase letter (A-Z).</li>
                      <li>At least one lowercase letter (a-z).</li>
                      <li>At least one number (0-9).</li>
                      <li>
                        At least one special character (e.g., @, #, $, etc.).
                      </li>
                      <li>Password must be at least 12 characters long.</li>
                      <li>
                        No continuous alphabetical characters (e.g., abcd) or
                        continuous numerical characters (e.g., 1234).
                      </li>
                      <li>
                        Avoid strictly sequential patterns (e.g., Ak12345678!).
                      </li>
                      <li>
                        Don't use birthdays, names, addresses, or other personal
                        information.
                      </li>
                    </ul>
                  </Box>
                }
                placement="bottom"
                arrow
              >
                <IconButton>
                  <Info
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "#152B51",
                      alignItems: "center",
                      position: "relative",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </label>
            <InputGroup
              className="input-group-alternative"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                border: "1px solid #ced4da",
              }}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                id="inputPassword4"
                value={newPassword}
                autoComplete="off"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <IconButton
                style={{ padding: "7px" }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputGroup>
          </FormGroup>

          {/* Confirm New Password Field */}
          <FormGroup className="mt-3 mb-3">
            <label
              htmlFor="confirmPassword"
              style={{
                fontWeight: "500",
                fontFamily: "Poppins",
                fontSize: "16px",
              }}
            >
              Confirm new password
            </label>
            <InputGroup
              className="input-group-alternative"
              style={{
                boxShadow: "0px 4px 4px 0px #00000040",
                border: "1px solid #ced4da",
                alignItems: "center",
              }}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type={showPassword1 ? "text" : "password"}
                placeholder="Confirm Password"
                id="confirmPassword"
                value={confirmPassword}
                autoComplete="off"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <IconButton
                style={{ padding: "7px" }}
                onClick={() => setShowPassword1(!showPassword1)}
              >
                {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputGroup>
          </FormGroup>

          {error && <div className="text-danger">{error}</div>}
          <br />
          <div>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isLoading}
              color="primary"
              style={{ backgroundColor: "#152B51" }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Change Password"}
            </Button>
            <Button
              variant="contained"
              size="large"
              color="grey"
              onClick={() => navigate(`/vendor/VendordashBoard`)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default VendorPassChange;
