import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faHome,
  faBriefcase,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import deleicon from "../../../assets/img/icons/common/delete.svg";
import "Styles/rentalowner.css";
import axiosInstance from "axiosInstance";
import { verifyToken } from "components/Functions/Functions";
import CardTransactionTypeSettings from "./Components/CardTransactionTypeSettings";
import moment from "moment";

const Rentals = () => {
  const { id, admin } = useParams();
  let navigate = useNavigate();

  const [submitLoader, setSubmitLoader] = useState(false);

  const [propertyData, setPropertyData] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [isFromDetails, setIsFromDetails] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const location = useLocation();

  const [accessType, setAccessType] = useState(null);

  const [cookies, setCoockie, removeCookie] = useCookies();

  useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      setIsFromDetails(true);
    }
  }, [location.state]);

  const handleCloseButtonClick = () => {
    if (isFromDetails) {
      navigate(
        admin
          ? `/${admin}/rentalownerdetail/${location.state.id}`
          : `/staff/staffrentalownerdetail/${location.state.id}`
      );
    } else {
      navigate(
        admin
          ? "/" + admin + "/RentalownerTable"
          : "/staff/staffrentalownertable"
      );
    }
  };

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (accessType?.admin_id) {
        try {
          const response = await axiosInstance.get(
            `/rentals/rentals/${accessType?.admin_id}`
          );

          setPropertyData(response.data.data);
        } catch (error) {
          console.error("Error fetching countries:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchPropertyData();
  }, []);

  function handleResponse(response) {
    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(
        id
          ? "Rental Owner Updated Successfully"
          : "Rental Owner Added Successfully",
        {
          position: "top-center",
          autoClose: 1000,
        }
      );
      setTimeout(() => {
        navigate(
          admin ? `/${admin}/RentalownerTable` : "/staff/staffrentalownertable"
        );
      }, 2000);
    } else {
      // Show an error toast
      toast.error(response.data.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  let rentalsFormik = useFormik({
    initialValues: {
      rentalOwner_name: "",
      rentalOwner_companyName: "",
      // birth_date: "",
      start_date: "",
      end_date: "",
      rentalOwner_primaryEmail: "",
      rentalOwner_alternateEmail: "",
      rentalOwner_phoneNumber: "",
      rentalOwner_homeNumber: "",
      rentalOwner_businessNumber: "",
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      rentalOwner_comments: "",
      processor_list: [
        {
          processor_id: "",
        },
      ],
      text_identityType: "",
      texpayer_id: "",
      rentalOwner_properties: [],
    },
    validationSchema: yup.object({
      rentalOwner_name: yup.string().required("Please enter name"),
      rentalOwner_primaryEmail: yup
        .string()
        .email("Invalid email address")
        .required("Please enter email address"),
      rentalOwner_alternateEmail:yup
      .string()
      .email("Invalid email address")
      .test(
        "not-same-as-primary-email",
        "Alternative email cannot be the same as the primary email",
        function (value) {
          const primaryNumber = this.parent?.rentalOwner_primaryEmail;
          if (!value && !primaryNumber) {
            return true;
          }
          return value !== primaryNumber;
        }
      ),
      rentalOwner_phoneNumber: yup
      .string()
      .required("Please enter phone number")
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "Phone number must be in the format (xxx) xxx-xxxx"
      ),
      rentalOwner_homeNumber: yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "home number must be in the format (xxx) xxx-xxxx"
      )
      .test(
        "not-same-as-phone",
        "Home number cannot be the same as the phone number",
        function (value) {
          const primaryNumber = this.parent.rentalOwner_phoneNumber;
          if (!value && !primaryNumber) {
            return true;
          }
          return value !== primaryNumber;
        }  
      ),
      rentalOwner_businessNumber: yup
      .string()
      .matches(
        /^\(\d{3}\) \d{3}-\d{4}$/,
        "business number must be in the format (xxx) xxx-xxxx"
      )
      .test(
        "not-same-as-phone",
        "Business number cannot be the same as the phone number",
        function (value) {
          const primaryNumber = this.parent.rentalOwner_phoneNumber;
          if (!value && !primaryNumber) {
            return true;
          }
          return value !== primaryNumber;
        }
      )
      .test(
        "not-same-as-home",
        "Business number cannot be the same as the home number",
        function (value) {
          const primaryNumber = this.parent.rentalOwner_homeNumber;
          if (!value && !primaryNumber) {
            return true;
          }
          return value !== primaryNumber;
        }
      ),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [processorIds, setProcessorIds] = useState([""]);
  const addProcessorIdInput = () => {
    setProcessorIds([...processorIds, ""]);
    const updatedProcessorList = [
      ...rentalsFormik.values.processor_list,
      { processor_id: "" },
    ];
    rentalsFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const removeProcessorIdInput = (index) => {
    const updatedProcessorIds = [...processorIds];
    updatedProcessorIds.splice(index, 1);
    setProcessorIds(updatedProcessorIds);
    const updatedProcessorList = [...rentalsFormik.values.processor_list];
    updatedProcessorList.splice(index, 1);
    rentalsFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const handleProcessorIdChange = (index, value) => {
    const updatedProcessorIds = [...processorIds];
    updatedProcessorIds[index] = value;
    setProcessorIds(updatedProcessorIds);
    const updatedProcessorList = [...rentalsFormik.values.processor_list];
    updatedProcessorList[index] = { processor_id: value };
    rentalsFormik.setFieldValue("processor_list", updatedProcessorList);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const [rentalOwnerData, setRentalOwnerData] = useState(null);

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/rental_owner/rentalowner_details/${id}`)
        .then((response) => {
          const rentalOwnerdata = response.data.data[0];
          setRentalOwnerData(rentalOwnerData);
          setIsLoading(false);
          setSelectedState(rentalOwnerdata.country || "Select");

          rentalsFormik.setValues({
            rentalOwner_name: rentalOwnerdata.rentalOwner_name || "",
            rentalOwner_companyName:
              rentalOwnerdata.rentalOwner_companyName || "",
            start_date: formatDate(rentalOwnerdata?.start_date),
            end_date: formatDate(rentalOwnerdata?.end_date),
            rentalOwner_primaryEmail:
              rentalOwnerdata.rentalOwner_primaryEmail || "",
            rentalOwner_alternateEmail:
              rentalOwnerdata.rentalOwner_alternateEmail || "",
            rentalOwner_phoneNumber:
              rentalOwnerdata.rentalOwner_phoneNumber || "",
            rentalOwner_homeNumber:
              rentalOwnerdata.rentalOwner_homeNumber || "",
            rentalOwner_businessNumber:
              rentalOwnerdata.rentalOwner_businessNumber || "",
            street_address: rentalOwnerdata.street_address || "",
            city: rentalOwnerdata.city || "",
            state: rentalOwnerdata.state || "",
            postal_code: rentalOwnerdata.postal_code || "",
            country: rentalOwnerdata.country || "",
            rentalOwner_comments: rentalOwnerdata.rentalOwner_comments || "",
            text_identityType: rentalOwnerdata.text_identityType || "",
            texpayer_id: rentalOwnerdata.texpayer_id || "",
            rentalOwner_properties:
              rentalOwnerdata.rentalOwner_properties || "",
            processor_list: rentalOwnerdata.processor_list || [],
          });

          const processorIds = rentalOwnerdata.processor_list
            ? rentalOwnerdata.processor_list.map((item) => item.processor_id)
            : [];
          setProcessorIds(processorIds);
        })
        .catch((error) => {
          console.error("Error fetching rental owner data:", error);
          setIsLoading(false);
        });
    }
  }, [id]);

  async function handleSubmit(values) {
    setSubmitLoader(true);
    try {
      values["admin_id"] = accessType?.admin_id;
      const processorIds = values.processor_id;

      if (processorIds) {
        // If processorIds is defined, proceed with processing it
        delete values.processor_id;
        values.processor_list = processorIds.map((processor_id) => ({
          processor_id,
        }));
      }

      if (id === undefined) {
        const res = await axiosInstance.post(
          `/rental_owner/rental_owner`,
          values
        );
        handleResponse(res);
      } else {
        const editUrl = `/rental_owner/rental_owner/${id}`;
        const res = await axiosInstance.put(editUrl, values);
        handleResponse(res);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    } finally {
      setSubmitLoader(false);
    }
  }

  const preventEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  
  const formatPhoneNumber = (value) => {
    const PhoneNumber = value.replace(/[^\d]/g, "");
    const limitedPhoneNumber = PhoneNumber.slice(0, 10);
    const match = limitedPhoneNumber.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}${match[3] ? "-" + match[3] : ""}`;
    }
    return limitedPhoneNumber;
  };

  return (
    <>
      <style>
        {`
    .custom-date-picker {
      background-color: white;
    }
  `}
      </style>
      {/* <RentalownerHeder /> */}
      <Container
        className="stylecontainer containerremovespace"
        fluid
        style={{ marginTop: "4rem" }}
      >
        <Col xs="12" lg="12" sm="12">
          <CardHeader
            className=" mt-3  headproptypebar background-colorsty"
            style={{
              borderRadius: "10px",
              boxShadow: " 0px 4px 4px 0px #00000040 ",
            }}
          >
            <h2
              className="headproptypee bgtextwhite fontfamilysty"
              style={{
                fontWeight: "500",
                fontSize: "26px",
              }}
            >
              {id ? "Edit Rental Owner" : "Add Rental Owner"}
            </h2>
          </CardHeader>
        </Col>
        <Row>
          <Col className="order-xl-1" xl="12">
            <div className="crm-card mx-3 mt-4">
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>

                  <div className="mt-2">
                    <FormGroup>
                      <label
                        className="form-control-label labelfontstyle fontfamilysty textcolorblue"
                        htmlFor="input-property"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Personal Information
                      </label>
                      <br></br>
                      {/* 6002 changing the design */}
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Contact Name *
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "60%",
                              }}
                              type="text"
                              id="rentalOwner_name"
                              placeholder="Enter name"
                              autoComplete="off"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  rentalsFormik.setFieldValue(
                                    "rentalOwner_name",
                                    e.target.value
                                  );
                                }
                              }}
                              value={rentalsFormik.values.rentalOwner_name}
                              onKeyDown={preventEnterKey}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_name &&
                          rentalsFormik.errors.rentalOwner_name ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_name}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-address"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Company Name
                            </label>

                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "60%",
                              }}
                              type="text"
                              id="rentalOwner_companyName"
                              placeholder="Enter company name"
                              autoComplete="off"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={
                                rentalsFormik.values.rentalOwner_companyName
                              }
                              onKeyDown={preventEnterKey}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_companyName &&
                          rentalsFormik.errors.rentalOwner_companyName ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_companyName}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="order-xl-1" xl="12">
            <div className="crm-card mx-3">
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>
                  <div className="">
                    <FormGroup>
                      <label
                        className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                        htmlFor="input-address"
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Merchant ID
                      </label>
                      <br />
                      <label
                        className="form-label fontstylerentr titleecolor fontfamilysty"
                        htmlFor={`input-unitadd`}
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Processor Id
                      </label>
                      <br />
                      {processorIds.length <= 1 ? (
                        <div className="d-flex flex-row justify-content-start align-items-center">
                          <FormGroup style={{ width: "20%" }}>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              placeholder="Enter processor id"
                              autoComplete="off"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={(e) =>
                                rentalsFormik.setFieldValue("processor_list", [
                                  {
                                    processor_id: e.target.value,
                                  },
                                ])
                              }
                              value={
                                rentalsFormik.values.processor_list[0]
                                  ?.processor_id || ""
                              }
                              onKeyDown={preventEnterKey}
                            />
                          </FormGroup>
                        </div>
                      ) : (
                        processorIds.map((processorId, index) => (
                          <div
                            className="d-flex flex-row justify-content-start align-items-center"
                            key={index}
                          >
                            <FormGroup
                              style={{ width: "20%", marginRight: "10px" }}
                            >
                              <Input
                                className="inputstyle fontstylerentmodal titleecolor"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                  width: "80%",
                                }}
                                id={`processor_id_${index}`}
                                placeholder="Enter processor id"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={(e) =>
                                  handleProcessorIdChange(index, e.target.value)
                                }
                                value={processorId}
                                onKeyDown={preventEnterKey}
                              />
                            </FormGroup>
                            <div
                              style={{ cursor: "pointer" }}
                              className="mx-2 mb-2"
                              onClick={() => removeProcessorIdInput(index)}
                            >
                              <img
                                className="propertyiconsrentals"
                                src={deleicon}
                                width={20}
                                height={20}
                                alt="Delete Icon"
                              />
                            </div>
                          </div>
                        ))
                      )}
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="order-xl-1" xl="12">
            <div className="crm-card mx-3">
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>
                  <div className="">
                    <FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                          htmlFor="input-address"
                          style={{
                            // marginBottom: "10px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Managment Agreement
                        </label>
                        <br></br>
                        <Row>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-unitadd"
                                style={{
                                  // marginBottom: "10px",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Start Date
                              </label>
                              <br />

                              <Input
                                className="inputstyle fontstylerentmodal titleecolor"
                                style={{
                                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                                  borderRadius: "6px",
                                  width: "80%",
                                }}
                                id="start_date"
                                placeholder="Start Date"
                                type="date"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={rentalsFormik.values.start_date}
                                onKeyDown={preventEnterKey}
                              />
                            </FormGroup>
                            {rentalsFormik.touched.start_date &&
                            rentalsFormik.errors.start_date ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {rentalsFormik.errors.start_date}
                              </div>
                            ) : null}
                          </Col>

                          <Col lg="2">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-unitadd"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              End Date
                            </label>
                            <br />
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              id="end_date"
                              placeholder="End Date"
                              type="date"
                              onBlur={rentalsFormik.handleBlur}
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.end_date}
                              min={
                                moment(
                                  rentalsFormik?.values?.start_date
                                ).format("YYYY-MM-DD") || null
                              }
                              onKeyDown={preventEnterKey}
                            />
                            {rentalsFormik.touched.end_date &&
                            rentalsFormik.errors.end_date ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {rentalsFormik.errors.end_date}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </FormGroup>
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="order-xl-1" xl="12">
            <div className="crm-card mx-3">
              <CardBody>
                <Form role="form">
                  <h6 className="heading-small text-muted mb-1"></h6>
                  <div className="">
                    <FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                          htmlFor="input-address"
                          style={{
                            // marginBottom: "10px",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Contact Information
                        </label>
                        <br></br>
                      </FormGroup>
                      <Row>
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Primary E-mail *
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </FormGroup>

                          <FormGroup
                            className="inputstyle "
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_primaryEmail"
                                placeholder="Enter primary email"
                              autoComplete="off"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values.rentalOwner_primaryEmail
                                }
                                onKeyDown={preventEnterKey}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_primaryEmail &&
                          rentalsFormik.errors.rentalOwner_primaryEmail ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_primaryEmail}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="3" className="rentalownerstyleinput">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Alternative E-mail
                            </label>
                            &nbsp;
                          </FormGroup>

                          <FormGroup
                            className="inputstyle"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_alternateEmail"
                                placeholder="Enter alternative email"
                              autoComplete="off"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={rentalsFormik.handleChange}
                                value={
                                  rentalsFormik.values
                                    .rentalOwner_alternateEmail
                                }
                                onKeyDown={preventEnterKey}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_alternateEmail &&
                          rentalsFormik.errors.rentalOwner_alternateEmail ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {rentalsFormik.errors.rentalOwner_alternateEmail}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Col></Col>
                      <Row className="mt-2">
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Phone Number *
                            </label>
                          </FormGroup>
                          <FormGroup
                            className="inputstyle"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faPhone} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_phoneNumber"
                                placeholder="Enter phone number"
                              autoComplete="off"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={(e) => {
                                  if (
                                    rentalsFormik.values.rentalOwner_phoneNumber?.length > e.target.value?.length
                                  ) {
                                    rentalsFormik.setFieldValue("rentalOwner_phoneNumber", e.target.value);
                                  } else {
                                    const formattedValue = formatPhoneNumber(e.target.value);
                                    rentalsFormik.setFieldValue("rentalOwner_phoneNumber", formattedValue);
                                  }
                                }}
                                value={
                                  rentalsFormik.values.rentalOwner_phoneNumber
                                }
                                onKeyDown={preventEnterKey}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_phoneNumber &&
                          rentalsFormik.errors.rentalOwner_phoneNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.rentalOwner_phoneNumber}
                            </div>
                          ) : null}
                        </Col>
                        {/* <br /> */}
                        <Col lg="3" className="rentalownerstyleinput">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-homenumber"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Home Number
                            </label>
                          </FormGroup>
                          <FormGroup
                            className="inputstyle"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faHome} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_homeNumber"
                                placeholder="Enter home number"
                              autoComplete="off"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={(e) => {
                                  if (
                                    rentalsFormik.values.rentalOwner_homeNumber?.length > e.target.value?.length
                                  ) {
                                    rentalsFormik.setFieldValue("rentalOwner_homeNumber", e.target.value);
                                  } else {
                                    const formattedValue = formatPhoneNumber(e.target.value);
                                    rentalsFormik.setFieldValue("rentalOwner_homeNumber", formattedValue);
                                  }
                                }}
                                value={
                                  rentalsFormik.values.rentalOwner_homeNumber
                                }
                                onKeyDown={preventEnterKey}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_homeNumber &&
                          rentalsFormik.errors.rentalOwner_homeNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.rentalOwner_homeNumber}
                            </div>
                          ) : null}
                        </Col>
                        {/* <br /> */}
                        <Col lg="3" className="rentalownerstyleinput">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Office Number
                            </label>
                          </FormGroup>
                          <FormGroup
                            className="inputstyle "
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                          >
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <FontAwesomeIcon icon={faBriefcase} />
                                </span>
                              </div>
                              <Input
                                className="fontstylerentmodal titleecolor"
                                type="text"
                                id="rentalOwner_businessNumber"
                                placeholder="Enter office number"
                              autoComplete="off"
                                onBlur={rentalsFormik.handleBlur}
                                onChange={(e) => {
                                  if (
                                    rentalsFormik.values.rentalOwner_businessNumber?.length > e.target.value?.length
                                  ) {
                                    rentalsFormik.setFieldValue("rentalOwner_businessNumber", e.target.value);
                                  } else {
                                    const formattedValue = formatPhoneNumber(e.target.value);
                                    rentalsFormik.setFieldValue("rentalOwner_businessNumber", formattedValue);
                                  }
                                }}
                                value={
                                  rentalsFormik.values
                                    .rentalOwner_businessNumber
                                }
                                onKeyDown={preventEnterKey}
                              />
                            </div>
                          </FormGroup>
                          {rentalsFormik.touched.rentalOwner_businessNumber &&
                          rentalsFormik.errors.rentalOwner_businessNumber ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.rentalOwner_businessNumber}
                            </div>
                          ) : null}
                        </Col>
                        {/* <br /> */}
                      </Row>
                      {/* </FormGroup> */}

                      <Row className="mt-2">
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-property"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Street Address
                            </label>
                          </FormGroup>
                          <Input
                            className="inputstyle fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            type="textarea"
                            id="street_address"
                            placeholder="Enter address "
                              autoComplete="off"
                            onBlur={rentalsFormik.handleBlur}
                            onChange={rentalsFormik.handleChange}
                            value={rentalsFormik.values.street_address}
                            onKeyDown={preventEnterKey}
                          />
                          {rentalsFormik.touched.street_address &&
                          rentalsFormik.errors.street_address ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.street_address}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <br></br>

                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-city"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              City
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="city"
                              placeholder="Enter city"
                              autoComplete="off"
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  rentalsFormik.setFieldValue(
                                    "city",
                                    e.target.value
                                  );
                                }
                              }}
                              value={rentalsFormik.values.city}
                              onKeyDown={preventEnterKey}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.city &&
                          rentalsFormik.errors.city ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.city}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-state"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              State
                            </label>

                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="state"
                              placeholder="Enter state"
                              autoComplete="off"
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  rentalsFormik.setFieldValue(
                                    "state",
                                    e.target.value
                                  );
                                }
                              }}
                              value={rentalsFormik.values?.state}
                              onKeyDown={preventEnterKey}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.state &&
                          rentalsFormik.errors.state ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.state}
                            </div>
                          ) : null}
                        </Col>
                        {/* </Row>
                      <Row className="mt-2"> */}
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-country"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Country
                            </label>

                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="country"
                              placeholder="Enter country"
                              autoComplete="off"
                              onChange={(e) => {
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                  rentalsFormik.setFieldValue(
                                    "country",
                                    e.target.value
                                  );
                                }
                              }}
                              value={rentalsFormik.values?.country}
                              onKeyDown={preventEnterKey}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.country &&
                          rentalsFormik.errors.country ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.country}
                            </div>
                          ) : null}
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label fontstylerentr titleecolor fontfamilysty"
                              htmlFor="input-zip"
                              style={{
                                // marginBottom: "10px",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              Postal Code
                            </label>
                            <Input
                              className="inputstyle fontstylerentmodal titleecolor"
                              style={{
                                boxShadow: " 0px 4px 4px 0px #00000040 ",
                                borderRadius: "6px",
                                width: "80%",
                              }}
                              type="text"
                              id="postal_code"
                              placeholder="Enter postal code"
                              autoComplete="off"
                              onChange={rentalsFormik.handleChange}
                              value={rentalsFormik.values.postal_code}
                              onInput={(e) => {
                                const inputValue = e.target.value;
                                const numericValue = inputValue.replace(
                                  /[^A-Za-z0-9-]/g,
                                  ""
                                );
                                e.target.value = numericValue.toUpperCase();
                              }}
                              onKeyDown={preventEnterKey}
                              // style={{ width: "235px" }}
                            />
                          </FormGroup>
                          {rentalsFormik.touched.postal_code &&
                          rentalsFormik.errors.postal_code ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.postal_code}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      {/* 6002 end */}
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <div className="crm-card mx-3">
              <CardBody>
                <Form role="form">
                  <div className="">
                    <FormGroup>
                      <label
                        className="form-control-label labelfontstyle textcolorblue fontfamilysty"
                        htmlFor="input-address"
                        style={{
                          // marginBottom: "10px",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Tax Payer Information
                      </label>
                      <br></br>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col lg="3">
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-tax"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Tax Identity Type
                          </label>

                          <Input
                            className="inputstyle fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            type="text"
                            id="text_identityType"
                            placeholder="Enter tax identify type"
                              autoComplete="off"
                            onChange={rentalsFormik.handleChange}
                            value={rentalsFormik.values.text_identityType}
                            onKeyDown={preventEnterKey}
                          />
                          {rentalsFormik.touched.text_identityType &&
                          rentalsFormik.errors.text_identityType ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.text_identityType}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg="3">
                          <label
                            className="form-control-label spacetop fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-taxpayer_id"
                            style={{
                              // marginBottom: "10px",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Taxpayer Id
                          </label>

                          <Input
                            className="inputstyle fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "80%",
                            }}
                            type="text"
                            id="texpayer_id"
                            placeholder="Enter SSN or EIN"
                              autoComplete="off"
                            onChange={rentalsFormik.handleChange}
                            value={rentalsFormik.values.texpayer_id}
                            onKeyDown={preventEnterKey}
                          />
                          {rentalsFormik.touched.texpayer_id &&
                          rentalsFormik.errors.texpayer_id ? (
                            <div
                              className="requiredstylefont"
                              style={{
                                color: "red",
                              }}
                            >
                              {rentalsFormik.errors.texpayer_id}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                  <br />
                </Form>
              </CardBody>
            </div>
          </Col>
        </Row>
        <Row className="ml-3 mt-3 mb-3">
          <Col>
            <Row>
              {submitLoader ? (
                <Button
                  type="submit"
                  className="btn bottomspace fontstylerentr background-colorsty bgtextwhite"
                  disabled
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  className="btn bottomspace fontstylerentr background-colorsty fontfamilysty bgtextwhite"
                  onClick={(e) => {
                    e.preventDefault();
                    rentalsFormik.handleSubmit();
                  }}
                  disabled={!rentalsFormik.isValid}
                >
                  {id ? "Update Rental Owner" : "Add Rental Owner"}
                </Button>
              )}
              <Button
                className="btn fontstylerentr backgroundwhitesty textcolorblue"
                onClick={() => {
                  handleCloseButtonClick();
                }}
                size="small"
              >
                Cancel
              </Button>
            </Row>
          </Col>
        </Row>
        <div className="crm-card mx-3">
          <CardTransactionTypeSettings rentalOwnerId={id} />
        </div>
        <ToastContainer />
      </Container>
    </>
  );
};

export default Rentals;
