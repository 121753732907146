import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import TenantHeader from "components/Headers/TenantsHeader";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@mui/icons-material/Clear";
import { OpenImageDialog } from "components/OpenImageDialog";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "axiosInstance";
import SearchDropdown from "components/inputfields/SearchDropdown";
import { verifyToken } from "components/Functions/Functions";
import swal from "sweetalert";
import moment from "moment";

const TAddWork = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const [accessType, setAccessType] = useState(null);
  const [cookies, setCoockie, removeCookie] = useCookies();
  useEffect(() => {
    const fetchData = async () => {
      const data = await verifyToken(navigate, location, removeCookie);
      setAccessType(data);
    };
    fetchData();
  }, []);
  const [propdropdownOpen, setpropdropdownOpen] = useState(false);
  const [categorydropdownOpen, setcategorydropdownOpen] = useState(false);
  const [entrydropdownOpen, setentrydropdownOpen] = useState(false);
  const [unitData, setUnitData] = useState([]);
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);
  const toggle11 = () => {
    setUnitDropdownOpen((prevState) => !prevState);
  };
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedProp, setSelectedProp] = useState("Select Property");
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const [selectedEntry, setSelectedEntry] = useState("Select");

  const toggle1 = () => setpropdropdownOpen((prevState) => !prevState);
  const toggle2 = () => setcategorydropdownOpen((prevState) => !prevState);
  const toggle4 = () => setentrydropdownOpen((prevState) => !prevState);

  const [propertyData, setPropertyData] = useState([]);
  const [isMultiUnit, SetIsMultiUnit] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const handlePropertyTypeSelect = async (property) => {
    if (!property) {
      setSelectedProp("");
      WorkFormik.setFieldValue("rental_adress", "");
      WorkFormik.setFieldValue("rental_id", "");

      setSelectedUnit("");
      WorkFormik.setFieldValue("rental_unit", "");
      setUnitData([]);
      SetIsMultiUnit(false);
      return;
    }

    if (property?.status === "Expired") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }

    // property.is_multiUnit ? SetIsMultiUnit(true) : SetIsMultiUnit(false);

    const { rental_adress, rental_id } = property;

    setSelectedProp(rental_adress);
    WorkFormik.setFieldValue("rental_adress", rental_adress);
    WorkFormik.setFieldValue("rental_id", rental_id);

    setSelectedUnit("");
    WorkFormik.setFieldValue("rental_unit", "");
    setUnitData([]);

    try {
      const units = await fetchUnitsByProperty(rental_id);
      units?.length > 0 ? SetIsMultiUnit(true) : SetIsMultiUnit(false);
      setUnitData(units || []);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };

  const handleUnitSelect = (selectedUnit, unitId) => {
    setSelectedUnit(selectedUnit);
    WorkFormik.values.rental_unit = selectedUnit;
    WorkFormik.setFieldValue("unit_id", unitId);

    // entrySchema.values.unit_id = unitId;
  };

  const handleCategorySelection = (value) => {
    setSelectedCategory(value);
    setcategorydropdownOpen(true);
    if (value === "Other") {
      WorkFormik.values.work_category = "";
    } else {
      WorkFormik.setFieldValue("work_category", value);
    }
  };

  const handleEntrySelect = (value) => {
    setSelectedEntry(value);
  };

  let navigate = useNavigate();
  const handleCloseButtonClick = () => {
    navigate("/tenant/tenantwork");
  };

  // Use URLSearchParams to extract parameters from the query string
  const [getData, setGetData] = useState();
  const [vid, setVid] = useState("");
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const id1 = queryParams.get("id");

  const fetchUnitsByProperty = async (propertyType) => {
    try {
      const response = await axiosInstance.get(
        `/unit/rental_unit_dropdown/${propertyType}`,
        { params: { tenant_id: accessType.tenant_id } }
      );
      const data = response.data;
      const units = data?.data || [];
      return units;
    } catch (error) {
      console.error("Error fetching units:", error);
      return [];
    }
  };
  const [tenantDetails, setTenantDetails] = useState({});
  const getTenantData = async () => {
    if (accessType?.tenant_id) {
      try {
        const apiUrl = `/tenant/tenant_details/${accessType?.tenant_id}`;
        const response = await axiosInstance.get(apiUrl);
        setTenantDetails(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching tenant details:", error);
      }
    }
  };

  useEffect(() => {
    getTenantData();
  }, [id]);
  useEffect(() => {
    const fetchData = async () => {
      if (id1) {
        try {
          const response = await axiosInstance.get(
            `/workorder/findworkorderbyId/${id1}`
          );
          if (response.data.statusCode === 200) {
            let getWorkData = response.data.data;
            setGetData(getWorkData);
            setVid(getWorkData[0]._id);
            WorkFormik.setValues({
              work_subject: getWorkData[0].work_subject || "",
              rental_adress: getWorkData[0].rental_adress || "",
              rental_unit: getWorkData[0].rental_unit || "",
              unit_no: getWorkData[0].unit_no || "",
              work_category: getWorkData[0].work_category || "",
              entry_allowed: getWorkData[0].entry_allowed ? "Yes" : "No",
              work_performed: getWorkData[0].work_performed || "",
            });
            setSelectedUnit(getWorkData[0].rental_unit || "Select");
            setSelectedProp(getWorkData[0].rental_adress);
            setSelectedEntry(getWorkData[0].entry_allowed);
            setSelectedCategory(getWorkData[0].work_category);
            setSelectedFiles(getWorkData[0].workOrderImage || []);
          } else {
            swal({
              text: "Work Order data not found!",
              icon: "warning",
            }).then(() => {
              navigate(`/tenant/tenantwork`);
            });
          }
        } catch (error) {
          console.log(error, "Error:");
        }
      }
    };
    fetchData();
    getTenantData();
  }, [baseUrl, id]);

  const { v4: uuidv4 } = require("uuid");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (values) => {
    setLoader(true);

    try {
      // Construct form data to be submitted
      const formData = {
        ...values,
        status: "New",
        admin_id: accessType?.admin_id,
        rental_adress: selectedProp,
        rental_unit: selectedUnit,
        work_category: values.work_category || selectedCategory,
        entry_allowed: selectedEntry === "Yes" ? true : false,
        statusUpdatedBy: `${accessType.tenant_firstName} ${accessType.tenant_lastName}(Tenant)`,
      };

      // Make POST request to save workorder
      const workOrderRes = await axiosInstance.post(`/work-order/work-order`, {
        workOrder: formData,
        notificationTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      if (workOrderRes.status === 200 && workOrderRes.data.statusCode === 200) {
        toast.success("Work Order Added Successfully.", {
          position: "top-center",
          autoClose: 1000,
          onClose: () => navigate("/tenant/tenantwork"),
          // Navigate to tenantwork page on success
        });
      } else {
        console.error(workOrderRes.data.message);
        toast.error(workOrderRes.data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Response Data:", error.response.data);
      }
    }

    setLoader(false);
  };

  function handleResponse(response) {
    const successMessage = id
      ? "Workorder updated successfully"
      : "Workorder added successfully";
    const errorMessage = response.data.message;

    if (response.data.statusCode === 200) {
      // Show success toast
      toast.success(successMessage, {
        position: "top-center",
        autoClose: 1000,
        onClose: () => navigate("/tenant/tenantwork"),
      });
    } else {
      // Show an error toast
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  }

  const WorkFormik = useFormik({
    initialValues: {
      admin_id: accessType?.admin_id,
      work_subject: getData?.work_subject ? getData.work_subject : "",
      rental_adress: "",
      rental_unit: "",
      work_category: "",
      entry_allowed: "",
      staffmember_name: "",
      work_performed: "",
      workOrderImage: [],
    },

    validationSchema: yup.object({
      work_subject: yup.string().required("Please enter work order subject"),
      rental_adress: yup.string().required("Please select property"),
      rental_unit: yup
        .string()
        .nullable()
        .test("rental_unit-required", "Please select Unit", function () {
          const { rental_unit } = this.parent;
          return isMultiUnit ? !!rental_unit : true;
        }),
      work_category: yup.string().required("Please select category"),
    }),

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const fetchRentals = () => {
    if (accessType?.tenant_id) {
      axiosInstance
        .get(`/tenant/tenant_property/${accessType?.tenant_id}`)
        .then((response) => {
          const responseData = response.data.data;
          if (responseData.length > 0) {
            setPropertyData(responseData);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    fetchRentals();
  }, [accessType]);

  const [selectedImage, setSelectedImage] = useState([]);

  const clearSelectedPhoto = (index, name) => {
    const filteredImage2 = selectedFiles.filter((item, i) => i !== index);
    setSelectedFiles(filteredImage2);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const fileData = (e) => {
    const newFiles = Array.from(e.target.files);

    // Check if the total file count exceeds the limit
    if (selectedFiles.length + newFiles.length > 10) {
      swal({
        text: "You can only upload 10 files",
        icon: "warning",
      });
      return;
    }

    // Update the state with the new files
    setSelectedFiles((prevSelectedFiles) => [
      ...prevSelectedFiles,
      ...newFiles,
    ]);
  };

  const editworkorder = async (vid) => {
    try {
      const response = await axiosInstance.put(
        `/workorder/updateworkorder/${vid}`,
        {
          work_subject: WorkFormik.values.work_subject,
          rental_adress: selectedProp,
          rental_unit: WorkFormik.values.rental_unit,
          work_category: WorkFormik.values.work_category,
          entry_allowed: selectedEntry,
          work_performed: WorkFormik.values.work_performed,
          workOrderImage: WorkFormik.values.workOrderImage,
          statusUpdatedBy:
            accessType.tenant_firstName + " " + accessType.tenant_lastName,

          // Add other fields as needed
        }
      );
      handleResponse(response);
    } catch (error) {
      console.error("Error updating workorder:", error);
    }
  };

  return (
    <>
      <TenantHeader />
      {/* Page content */}
      <Container className="stylecontainer" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card
              className=" mb-3"
              style={{
                borderRadius: "20px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <Form onSubmit={WorkFormik.handleSubmit}>
                <CardHeader
                  className="mx-5 mt-5  headproptypebar addtenantworkstyle background-colorsty"
                  style={{
                    borderRadius: "6px",
                    height: "45px",
                    boxShadow: " 0px 4px 4px 0px #00000040 ",
                    padding: "6px 7px 1px 21px",
                  }}
                >
                  <span
                    className=" align-items-center headproptypee bgtextwhite fontfamilysty"
                    style={{
                      fontWeight: "500",
                      fontSize: "22px",
                    }}
                  >
                    New Work Order
                  </span>
                </CardHeader>
                <CardBody className="bg-white">
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Subject *
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-name"
                            placeholder="Add Subject"
                            type="text"
                            name="work_subject"
                            autoComplete="off"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.work_subject}
                          />
                          {WorkFormik.errors &&
                          WorkFormik.errors?.work_subject &&
                          WorkFormik.touched &&
                          WorkFormik.touched?.work_subject &&
                          WorkFormik.values.work_subject === "" ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.work_subject}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label
                            className="form-control-label mt-3 fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-unitadd"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Photos (Maximum of 10)
                          </label>
                          <span
                            style={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontFamily: "monospace",
                              color: "blue",
                            }}
                          >
                            <input
                              type="file"
                              className="form-control-file d-none"
                              accept="image/*"
                              multiple
                              id={`workOrderImage`}
                              name={`workOrderImage`}
                              onChange={(e) => fileData(e)}
                            />
                            <label
                              htmlFor={`workOrderImage`}
                              className="fontstylerentmodal taddbtnupload background-colorsty bgtextwhite fontfamilysty"
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: "6px",
                                padding: "15px",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                              }}
                            >
                              Upload
                            </label>
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        paddingLeft: "10px",
                      }}
                    >
                      <div
                        className="mt-2 d-flex"
                        style={{
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <div className="d-flex">
                          {selectedFiles &&
                            selectedFiles.length > 0 &&
                            selectedFiles.map((unitImg, index) => (
                              <div
                                key={index}
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                  margin: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <img
                                  src={
                                    typeof unitImg === "string"
                                      ? `${imageGetUrl}/${unitImg}`
                                      : URL.createObjectURL(unitImg)
                                  }
                                  alt=""
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    borderRadius: "10px",
                                  }}
                                  onClick={() => {
                                    setSelectedImage(unitImg);
                                    setOpen(true);
                                  }}
                                />
                                <ClearIcon
                                  style={{
                                    cursor: "pointer",
                                    alignSelf: "flex-start",
                                    position: "absolute",
                                    top: "-12px",
                                    right: "-12px",
                                  }}
                                  onClick={() => clearSelectedPhoto(index)}
                                />
                              </div>
                            ))}
                        </div>
                        {open && (
                          <OpenImageDialog
                            open={open}
                            setOpen={setOpen}
                            selectedImage={selectedImage}
                          />
                        )}
                      </div>
                    </FormGroup>
                    {/* <br /> */}
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Property *
                          </label>
                          <br />
                          <SearchDropdown
                            options={propertyData}
                            value={
                              propertyData.find(
                                (p) => p.rental_adress === selectedProp
                              ) || null
                            }
                            onChange={(event, value) => {
                              handlePropertyTypeSelect(value);
                            }}
                            placeholder="Select Property"
                            getOptionLabel={(option) =>
                              option.rental_adress
                                ? `${option.rental_adress} (${option.status})`
                                : ""
                            }
                            filterOptions={(options, state) => {
                              return options.filter((option) =>
                                option.rental_adress
                                  .toLowerCase()
                                  .includes(state.inputValue.toLowerCase())
                              );
                            }}
                          />
                          {WorkFormik.errors &&
                          WorkFormik.errors?.rental_adress &&
                          WorkFormik.touched &&
                          WorkFormik.touched?.rental_adress &&
                          WorkFormik.values.rental_adress === "" ? (
                            <div
                              className="requiredstylefont"
                              style={{ color: "red" }}
                            >
                              {WorkFormik.errors.rental_adress}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>

                      <Col lg="4">
                        {selectedProp &&
                          unitData &&
                          unitData[0] &&
                          unitData[0].rental_unit && (
                            <FormGroup>
                              <label
                                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                                htmlFor="input-unit"
                                style={{
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                Unit *
                              </label>
                              <br />
                              <FormGroup>
                                <Dropdown
                                  className="fontstylerentmodal"
                                  isOpen={unitDropdownOpen}
                                  toggle={toggle11}
                                >
                                  <DropdownToggle
                                    className="fontstylerentmodal titleecolor"
                                    caret
                                    style={{
                                      width: "100%",
                                      boxShadow: " 0px 4px 4px 0px #00000040",
                                      border: "1px solid #ced4da",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    {selectedUnit
                                      ? selectedUnit
                                      : "Select Unit"}
                                  </DropdownToggle>
                                  <DropdownMenu className="fontstylerentmodal">
                                    {unitData.length > 0 ? (
                                      unitData.map((unit) => (
                                        <DropdownItem
                                          className="fontstylerentmodal"
                                          key={unit._id}
                                          onClick={() =>
                                            handleUnitSelect(
                                              unit.rental_unit,
                                              unit.unit_id
                                            )
                                          }
                                        >
                                          {unit.rental_unit}
                                        </DropdownItem>
                                      ))
                                    ) : (
                                      <DropdownItem
                                        className="fontstylerentmodal"
                                        disabled
                                      >
                                        No units available
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                  {WorkFormik.errors &&
                                  WorkFormik.errors?.rental_unit &&
                                  WorkFormik.touched &&
                                  WorkFormik.touched?.rental_unit &&
                                  WorkFormik.values.rental_unit === "" ? (
                                    <div
                                      className="requiredstylefont"
                                      style={{ color: "red" }}
                                    >
                                      {WorkFormik.errors.rental_unit}
                                    </div>
                                  ) : null}
                                </Dropdown>
                              </FormGroup>
                            </FormGroup>
                          )}
                      </Col>
                    </Row>
                    <br />
                  </div>

                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Category *
                          </label>
                          <br />
                          <Dropdown
                            className="fontstylerentmodal"
                            isOpen={categorydropdownOpen}
                            toggle={toggle2}
                          >
                            <DropdownToggle
                              className="fontstylerentmodal titleecolor"
                              caret
                              style={{
                                width: "100%",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                                backgroundColor: "transparent",
                              }}
                            >
                              {selectedCategory} &nbsp;&nbsp;&nbsp;&nbsp;
                            </DropdownToggle>
                            <DropdownMenu
                              className="fontstylerentmodal"
                              style={{ width: "100%" }}
                            >
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("Complaint")
                                }
                              >
                                Complaint
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection(
                                    "Contribution Request"
                                  )
                                }
                              >
                                Contribution Request
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("Feedback/Suggestion")
                                }
                              >
                                Feedback/Suggestion
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("General Inquiry")
                                }
                              >
                                General Inquiry
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() =>
                                  handleCategorySelection("Maintenance Request")
                                }
                              >
                                Maintenance Request
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => handleCategorySelection("Other")}
                              >
                                Other
                              </DropdownItem>
                            </DropdownMenu>
                            {WorkFormik.errors &&
                            WorkFormik.errors?.work_category &&
                            WorkFormik.touched &&
                            WorkFormik.touched?.work_category &&
                            WorkFormik.values.work_category === "" ? (
                              <div
                                className="requiredstylefont"
                                style={{ color: "red" }}
                              >
                                {WorkFormik.errors.work_category}
                              </div>
                            ) : null}
                          </Dropdown>
                        </FormGroup>
                      </Col>
                      <Col
                        lg="3"
                        style={
                          selectedCategory === "Other"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Other Category
                          </label>
                          <br />
                          <Input
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                              borderRadius: "6px",
                              width: "60%",
                            }}
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            id="input-work-subject"
                            placeholder="Enter Other Category"
                            type="text"
                            name="work_category"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              WorkFormik.setFieldValue(
                                "work_category",
                                e.target.value
                              );
                            }}
                            value={WorkFormik.values.work_category}
                            // required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label mt-3 fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-desg"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Entry Allowed
                          </label>
                          <br />
                          <Dropdown
                            className="fontstylerentmodal"
                            isOpen={entrydropdownOpen}
                            toggle={toggle4}
                          >
                            <DropdownToggle
                              className="fontstylerentmodal titleecolor"
                              caret
                              style={{
                                width: "100%",
                                boxShadow: " 0px 4px 4px 0px #00000040",
                                border: "1px solid #ced4da",
                                backgroundColor: "transparent",
                              }}
                            >
                              {selectedEntry} &nbsp;&nbsp;&nbsp;&nbsp;
                            </DropdownToggle>
                            <DropdownMenu
                              className="fontstylerentmodal"
                              style={{ width: "100%" }}
                            >
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => handleEntrySelect("Yes")}
                              >
                                Yes
                              </DropdownItem>
                              <DropdownItem
                                className="fontstylerentmodal"
                                onClick={() => handleEntrySelect("No")}
                              >
                                No
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label fontstylerentr titleecolor fontfamilysty"
                            htmlFor="input-member"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Work To Be Performed
                          </label>
                          <br />
                          <Input
                            className="form-control-alternative fontstylerentmodal titleecolor"
                            style={{
                              boxShadow: " 0px 4px 4px 0px #00000040 ",
                            }}
                            id="input-name"
                            placeholder="Enter here..."
                            type="textarea"
                            name="work_performed"
                            onBlur={WorkFormik.handleBlur}
                            onChange={(e) => {
                              // Update the state or Formik values with the new input value
                              WorkFormik.handleChange(e);
                            }}
                            value={WorkFormik.values.work_performed}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                  </div>
                  {loader ? (
                    <button
                      type="submit"
                      className="btn btnwidthworkorder fontstylerentr background-colorsty bgtextwhite"
                      style={{
                        cursor: "not-allowed",
                      }}
                      disabled
                    >
                      Loading...
                    </button>
                  ) : id1 ? (
                    <button
                      type="submit"
                      className="btn btn-primary ml-4 marginlefttaddwork fontstylerentr background-colorsty bgtextwhite"
                      style={{
                        cursor: "pointer",
                      }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        editworkorder(vid);
                      }}
                    >
                      Update Lease
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary ml-4 marginlefttaddwork fontstylerentr background-colorsty bgtextwhite"
                      // disabled={!WorkFormik.isValid}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   handleSubmit(WorkFormik.values);
                      // }}
                    >
                      Add Work Order
                    </button>
                  )}
                  <button
                    className="btn fontstylerentr backgroundwhitesty textcolorblue"
                    onClick={handleCloseButtonClick}
                    size="sm"
                  >
                    Cancel
                  </button>
                  <br />
                  {showWarning && (
                    <div
                      className="warningstylefont pl-4"
                      style={{ color: "red", paddingTop: "10px" }}
                    >
                      Your lease for this property has expired. The work order
                      will appear only on the admin/staff dashboard.
                    </div>
                  )}
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default TAddWork;
