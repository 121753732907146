import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState, useEffect } from "react";
import { Button, label, Form, Input, Row, Col, FormGroup } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import axiosInstance from "axiosInstance";
import moment from "moment";

const getChangedFields = (initialValues, values) => {
  let status = false;
  Object.keys(values).forEach((key) => {
    if (values[key] != initialValues[key]) {
      status = true;
    }
  });
  return status;
};

function PolicyDialog(props) {
  const imageUrl = process.env.REACT_APP_IMAGE_POST_URL;
  const imageGetUrl = process.env.REACT_APP_IMAGE_GET_URL;
  const { id, closeModal, TenantInsuranceId, admin_id } = props;
  const [newFile, setNewFile] = useState(null);
  const [initialValues, setInitialValues] = useState("");

  const validationSchema = yup.object({
    Provider: yup.string().required("Please enter provider name"),
    policy_id: yup.string().required("Please enter policy id"),
    EffectiveDate: yup.date().required("Please select effective date"),
    ExpirationDate: yup.date().required("Please select expiration date"),
    LiabilityCoverage: yup
      .number()
      .required("Please enter liability coverage")
      .min(0, "Liability Coverage cannot be negative"),
  });

  const formik = useFormik({
    initialValues: {
      Provider: "",
      policy_id: "",
      EffectiveDate: "",
      ExpirationDate: "",
      LiabilityCoverage: "",
      Policy: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const updatedFields = getChangedFields(initialValues, {
        ...values,
        Policy: newFile,
      });
      if (updatedFields) {
        await handleSubmit(values);
      }
    },
  });

  const getPolicyData = async () => {
    if (TenantInsuranceId) {
    try {
      const apiUrl = `/tenantinsurance/tenantinsurance/${TenantInsuranceId}`;
      const response = await axiosInstance.get(apiUrl);
      formik.setValues(response.data.data);
      setInitialValues(response.data.data);
      setNewFile(response.data.data.Policy);
    } catch (error) {
      console.error("Error fetching policy details:", error);
    }
    }
  };
  const deleteFile = () => {
    setNewFile(null);
  };

  useEffect(() => {
    getPolicyData();
  }, []);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitLoader(true);

    if (newFile) {
      try {
        const form = new FormData();
        form.append("files", newFile);

        const res = await axios.post(`${imageUrl}/images/upload`, form);

        if (res && res.data && res.data.files && res.data.files.length > 0) {
          values.Policy = res.data.files[0].filename;
        } else {
          console.error("Unexpected response format:", res);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Error uploading file", {
          position: "top-center",
        });
        return;
      }
    }

    if (TenantInsuranceId) {
      try {
        const response = await axiosInstance.put(
          `/tenantinsurance/tenantinsurance/${TenantInsuranceId}`,
          values
        );
        if (response.data.statusCode === 200) {
          toast.success("Insurance Updated successfully!", {
            position: "top-center",
            autoClose: 900,
            onClose: closeModal,
          });
        } else {
          toast.error(response.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
        });
        console.error("Error:", error.message);
      }
    } else {
      try {
        const response = await axiosInstance.post(
          `/tenantinsurance/tenantinsurance/${id}`,
          {
            ...values,
            admin_id: admin_id,
          }
        );
        if (response.data.statusCode === 200) {
          toast.success("Insurance Added successfully!", {
            position: "top-center",
            autoClose: 900,
            onClose: closeModal,
          });
        } else {
          toast.error(response.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
        });
        console.error("Error:", error.message);
      } finally {
        setSubmitLoader(false);
      }
    }
  };

  const openFileInBrowser = (fileURL) => {
    if (typeof fileURL === "string") {
      window.open(`${imageGetUrl}/${fileURL}`, "_blank");
    } else {
      const url = URL.createObjectURL(fileURL);
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Row className="d-flex justify-content-between mb-3">
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label titleecolor fontstylerentr fontfamilysty"
                htmlFor="Provider"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Provider *
              </label>
              <Input
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  borderRadius: "6px",
                  marginRight: "10px",
                  flex: 1,
                  width: "80%",
                }}
                id="Provider"
                className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                variant="standard"
                type="text"
                placeholder="Enter Provider Name"
                name="Provider"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Provider}
              />
              {formik.touched.Provider && formik.errors.Provider ? (
                <div className="requiredstylefont" style={{ color: "red" }}>
                  {formik.errors.Provider}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="policy_id"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Policy Id *
              </label>
              <Input
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  borderRadius: "6px",
                  marginRight: "10px",
                  flex: 1,
                  width: "80%",
                }}
                id="policy_id"
                className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                variant="standard"
                type="text"
                placeholder="Enter Policy Id"
                name="policy_id"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.policy_id}
              />
              {formik.touched.policy_id && formik.errors.policy_id ? (
                <div className="requiredstylefont" style={{ color: "red" }}>
                  {formik.errors.policy_id}
                </div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <Row className="d-flex justify-content-between mb-3">
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="EffectiveDate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Effective Date *
              </label>
              <Input
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  borderRadius: "6px",
                  marginRight: "10px",
                  flex: 1,
                  width: "80%",
                }}
                id="EffectiveDate"
                className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                variant="standard"
                type="date"
                name="EffectiveDate"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.EffectiveDate}
              />
              {formik.touched.EffectiveDate && formik.errors.EffectiveDate ? (
                <div className="requiredstylefont" style={{ color: "red" }}>
                  {formik.errors.EffectiveDate}
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="ExpirationDate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Expiration Date *
              </label>
              <Input
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  borderRadius: "6px",
                  marginRight: "10px",
                  flex: 1,
                  width: "80%",
                }}
                id="ExpirationDate"
                className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                variant="standard"
                type="date"
                name="ExpirationDate"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.ExpirationDate}
                min={
                  moment(formik.values.EffectiveDate).format("YYYY-MM-DD") ||
                  null
                }
              />
              {formik.touched.ExpirationDate && formik.errors.ExpirationDate ? (
                <div className="requiredstylefont" style={{ color: "red" }}>
                  {formik.errors.ExpirationDate}
                </div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <Row className="d-flex justify-content-between mb-3">
          <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="LiabilityCoverage"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Liability Coverage *
              </label>
              <Input
                style={{
                  boxShadow: " 0px 4px 4px 0px #00000040 ",
                  borderRadius: "6px",
                  marginRight: "10px",
                  flex: 1,
                  width: "80%",
                }}
                id="LiabilityCoverage"
                className="form-control-alternative inputwidth fontstylerentmodal titleecolor"
                variant="standard"
                type="text"
                placeholder="$0.00"
                name="LiabilityCoverage"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.LiabilityCoverage}
              />
              {formik.touched.LiabilityCoverage &&
              formik.errors.LiabilityCoverage ? (
                <div className="requiredstylefont" style={{ color: "red" }}>
                  {formik.errors.LiabilityCoverage}
                </div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <Row className="d-flex justify-content-between mb-3">
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="LiabilityCoverage"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Upload Insurance Document{" "}
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="file"
                  className="form-control-file d-none"
                  accept="file/*"
                  name="upload_file"
                  id="upload_file"
                  multiple={false}
                  onChange={(e) => {
                    setNewFile(e.target.files[0]);
                  }}
                />
                <label
                  htmlFor="upload_file"
                  className=" mr-3 fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  style={{
                    borderRadius: "5px",
                    padding: "8px",
                  }}
                >
                  Choose Files
                </label>
                {newFile && (
                  <>
                    <p
                      style={{
                        cursor: "pointer",
                        color: "blue",
                      }}
                      onClick={() => openFileInBrowser(newFile)}
                    >
                      {newFile?.name || newFile}
                      <CloseIcon
                        className="titleecolor"
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteFile();
                        }}
                      />
                    </p>
                  </>
                )}
              </div>
              {formik.touched.Policy && formik.errors.Policy ? (
                <div className="requiredstylefont" style={{ color: "red" }}>
                  {formik.errors.Policy}
                </div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        {submitLoader ? (
          <Button
            type="submit"
            className="btn fontstylerentr background-colorsty bgtextwhite fontfamilysty"
            style={{
              fontSize: "14px",
              fontWeight: "500",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
            disabled
          >
            Loading...
          </Button>
        ) : (
          <Button
            type="submit"
            className="btn bottomspacee fontstylerentr background-colorsty bgtextwhite fontfamilysty"
            style={{
              fontWeight: "400",
              fontSize: "14px",
              cursor: "pointer",
              boxShadow: "0px 4px 4px 0px #00000040",
              border: 0,
            }}
            // disabled={!formik.isValid}
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {TenantInsuranceId != null ? "Edit" : "Save"}
          </Button>
        )}
        <Button
          type="reset"
          onClick={() => {
            closeModal();
            formik.resetForm();
            setNewFile(null);
          }}
          className="btn fontstylerentr textcolorblue fontfamilysty backgroundwhitesty"
          style={{
            fontWeight: "400",
            fontSize: "14px",
            cursor: "pointer",
            boxShadow: "0px 4px 4px 0px #00000040",
          }}
        >
          Cancel
        </Button>
      </Form>
      <ToastContainer />
    </>
  );
}

export default PolicyDialog;
