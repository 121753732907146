import * as React from "react";
import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import {
  Col, Container, Row, DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
} from "reactstrap";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from 'react-cookie';
import { Circles } from "react-loader-spinner";
import deleterecord from "../assets/img/delete.png";
import SuperAdminHeader from "../Headers/SuperAdminHeader";
import { Button, } from "@mui/material";
import leftarrow from "../../../assets/icons/left.svg";
import rightarrow from "../../../assets/icons/right.svg";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "axiosInstance";
import moment from "moment";

const label = { inputProps: { "aria-label": "Switch demo" } };

const headCells = [
  {
    label: "Main Type",
  },
  {
    label: "Sub Type",
  },
  {
    label: "Updated At",
  },
  {
    label: "Created At",
  },
];

function Rows(props) {
  const { row, handleClick, isItemSelected, labelId, seletedEditData } = props;
  const navigate = useNavigate();
  const { admin_id } = useParams();

  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, row._id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
      >
        {/* <TableCell align="center">{ row + 1}</TableCell> */}
        <TableCell align="left">{row?.property_type}</TableCell>
        <TableCell align="left">{row?.propertysub_type}</TableCell>
        <TableCell align="left">{row?.updatedAt}</TableCell>
        <TableCell align="left">{moment(row?.createdAt).format("YYYY-MM-DD")}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PropertyType() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState();
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const { admin_id } = useParams();
  React.useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axiosInstance.post(`/admin/superadmin_token_check`, {
          token: cookies.token
        });
        setAccessType(response.data);

        if ("Admin Not Found" === response.data.message) {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error checking token:", error);
        // Handle error here, maybe redirect to login page or show an error message
      }
    };

    if (cookies.token) {
      checkToken();
    } else {
      navigate("/auth/login");
    }
  }, [cookies.token, navigate]);

  const [propertyType, setPropertyType] = useState([]);
  let [loader, setLoader] = React.useState(true);
  let [countData, setCountData] = useState(0);
  const [adminName, setAdminName] = useState();
  const [propertyTotalData, setPropertyTotalData] = useState([]);

  // pagination
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;
  const getData = async () => {
    if (admin_id) {
      try {
        const res = await axiosInstance.get(
          `/propertytype/property_type/${admin_id}`,
          {
            params: {
              pageSize: rowsPerPage,
              pageNumber: page,
            },
          }
        );
        setLoader(false);
        setPropertyTotalData(res.data.data);
        setPropertyType(res.data.data.slice(startIndex, endIndex));
        setTotalPages(Math.ceil(res.data.data.length / rowsPerPage));

        setAdminName(res.data.data[0].admin);
        setCountData(res.data.count); // Make sure to adjust the key here
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    getData();
  }, [rowsPerPage, page]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = propertyType?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Delete selected
  var handleDelete = () => {
    swal("Are You Sure You Want TO Delete ?", {
      buttons: ["No", "Yes"],
    }).then(async (buttons) => {
      if (buttons === true) {
        axiosInstance
          .delete(`/admin/admin`, {
            data: selected,
          })
          .then((response) => {
            if (response.data.statusCode === 200) {
              getData();
              setSelected([]);
              toast.success(response.data.message, {
                position: "top-center",
                autoClose: 1000,
              });
            }
          });
      }
    });
  };


  const [leasedropdownOpen, setLeaseDropdownOpen] = React.useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  const [totalPages, setTotalPages] = React.useState(1);
  const [pageItem, setPageItem] = React.useState(10);

  // Searchbar
  const [searchLoader, setSearchLoader] = useState(false);
  let handleSearchData = async (values) => {
    if (values !== "") {
      const data = propertyTotalData;
      const filteredData = data.filter((property) => {
        const propertytype = property.property_type
          .toLowerCase()
          .includes(values.toLowerCase());
        const propertysubtype = property.propertysub_type
          .toLowerCase()
          .includes(values.toLowerCase());
        const propertycreate = property.createdAt
          .toLowerCase()
          .includes(values.toLowerCase());
          const propertyupdate = property.updatedAt
          .toLowerCase()
          .includes(values.toLowerCase());
        return propertytype || propertysubtype || propertycreate || propertyupdate;
      })
      setPropertyType(filteredData);
    } else {
      setPropertyType(propertyTotalData);
    }
  };

  //   edit machine-type here
  let [modalShowForPopupForm, setModalShowForPopupForm] = React.useState(false);
  let [id, setId] = React.useState();

  var handleSubmit;

  if (!id) {
    handleSubmit = async (values) => {
      try {
        const res = await axiosInstance.post(`/plans/plans`, values);
        if (res.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(res.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-center",
          });
        }
      } catch (error) {
        toast.error(error, {
          position: "top-center",
        });
      }
    };
  } else {
    handleSubmit = async (values) => {
      try {
        const response = await axiosInstance.put(
          `/plans/plans/${id}`, // Use template literals to include the id
          values
        );

        if (response.data.statusCode === 200) {
          setModalShowForPopupForm(false);
          getData();
          toast.success(response.data?.message, {
            position: "top-center",
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error, {
          position: "top-center",
        });
      }
    };
  }

  //    // "add fom logic"
  let [editData, setEditData] = React.useState({});

  //   auto form fill up in edit
  let seletedEditData = async (datas) => {
    setModalShowForPopupForm(true);
    setId(datas._id);
    setEditData(datas);
  };

  const [adminDataCount, setAdminDataCount] = useState()
  const adminCount = async () => {
    if (admin_id) {
      try {
        // Make an HTTP request to your API endpoint with the adminId
        const res = await axiosInstance.get(`/admin/admin_count/${admin_id}`);
        setAdminDataCount(res.data)
      } catch (error) {
        console.error('Error occurred while calling API:', error);
      }
    }
  };

  React.useEffect(() => {
    adminCount()
  }, [admin_id])

  // Formik
  //   let [ProductDetailsFormik, setProductDetailsFormik] = useState({});
  //   const FormikValues = () => {
  //     const formik = useFormikContext();
  //     React.useEffect(() => {
  //       setProductDetailsFormik(formik.values);
  //     }, [formik.values]);
  //     return null;
  //   };


  return (
    <>
      {/* <SuperAdminHeader /> */}
      {/* <Container className="mt--8 ml--10" fluid> */}
      <Container className="stylecontainer" fluid>

        <Row>
          <Col className="pt-7">
            <nav
              className="navbar navbar-expand-lg navbar-light  mb-1 main-nav"
              style={{ cursor: "pointer", borderRadius: "15px", boxShadow: "0px 4px 4px 0px #00000040", border: " 0.5px solid #324567" }}

            >
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/staffmember/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                      style={{
                        borderBottom: "2px solid transparent",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      Staff Member({adminDataCount?.staff_member})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/propertytype/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                      style={{
                        borderBottom: "2px solid transparent",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      Property Type({adminDataCount?.property_type})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/properties/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                      style={{
                        borderBottom: "2px solid transparent",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      Properties({adminDataCount?.rentals_properties})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/rental-owner/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                      style={{
                        borderBottom: "2px solid transparent",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      Rental Owner({adminDataCount?.rental_owner})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/tenant/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                      style={{
                        borderBottom: "2px solid transparent",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      Tenant({adminDataCount?.tenant})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/unit/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                    >
                      Unit({adminDataCount?.unit})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/lease/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"
                    >
                      Lease({adminDataCount?.lease})
                    </NavLink>
                  </li>
                  <li className="nav-item d-flex align-item-center">
                    <NavLink
                      to={`/superadmin/vendor/${admin_id}`}
                      className="navtt-link"
                      activeClassName="active"ko
                    >
                      Vendor({adminDataCount?.vendor})
                    </NavLink>
                  </li>
                  {/* Add more links as needed */}
                </ul>
              </div>
            </nav>
            <div className="input-group mb-3 nav-drop">
              <select
                className="form-select p-2 fontstylerentr"
                aria-label="Disabled select example"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (selectedValue === "1") {
                    navigate(`/superadmin/staffmember/${admin_id}`);
                  } else if (selectedValue === "2") {
                    navigate(`/superadmin/propertytype/${admin_id}`);
                  } else if (selectedValue === "3") {
                    navigate(`/superadmin/properties/${admin_id}`);
                  } else if (selectedValue === "4") {
                    navigate(`/superadmin/rental-owner/${admin_id}`);
                  } else if (selectedValue === "5") {
                    navigate(`/superadmin/tenant/${admin_id}`);
                  } else if (selectedValue === "6") {
                    navigate(`/superadmin/unit/${admin_id}`);
                  } else if (selectedValue === "7") {
                    navigate(`/superadmin/lease/${admin_id}`);
                  } else if (selectedValue === "8") {
                    navigate(`/superadmin/vendor/${admin_id}`);
                  }
                }}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  background:
                  " linear-gradient(87deg, #2f61b6 0, #152B51 100%)",
                  border: "none",
                  borderRadius: "20px",
                  color:"white",
                }}
              >
                <option className="fontstylerentr" style={{color:"#152B51"}} selected>Open this select menu</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="1">Staff Member</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="2">Property Type</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="3">Properties</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="4">Rental Owner</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="5">Tenant</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="6">Unit</option>
                <option className="fontstylerentr" style={{color:"#152B51"}} value="7">Lease</option>
              </select>
            </div>
            <div className="mt-3">
              <Paper
                sx={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <Toolbar
                  className="border-top border-bottom"
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    bgcolor: "#fff", // Set the background color here
                    color: "white", // Set the font color to white
                  }}
                >
                  {/* <Typography
                    sx={{ flex: "1 1 100%", color: "black" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Property Type: {adminName?.first_name}{" "}
                    {adminName?.last_name}
                  </Typography> */}

                  <form className="form-inline">
                    <input
                      id="serchbar-size"
                      className="form-control mr-sm-2"
                      type="search"
                      onChange={(e) => handleSearchData(e.target.value)}
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </form>

                  <>
                    {selected.length > 0 ? (
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete()}>
                          <img
                            src={deleterecord}
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "10px",
                              alignItems: "center",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                </Toolbar>

                {loader || searchLoader ? (
                  <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
                    <Circles
                      height="50"
                      width="50"
                      color="#1171ef"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperclassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <TableContainer  style={{overflowX: "hidden"}} className="superAdminOverflow">
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell align="center"></TableCell> */}

                          {headCells.map((headCell, id) => {
                            return (
                              <TableCell
                                style={{ fontWeight: "600", fontFamily: "Poppins", fontSize: "14px", color: "#152B51" }}

                                key={id}
                                className="fw-bold fontstylerentr"
                                align="left"
                              >
                                {headCell.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {propertyType?.map((row, index) => {
                          const isItemSelected = isSelected(row._id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <Rows
                              row={row}
                              isItemSelected={isItemSelected}
                              labelId={labelId}
                              handleClick={handleClick}
                              selected={selected}
                              index={index}
                              seletedEditData={seletedEditData}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                    {/* <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={countData}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                    <Row>
                      <Col className="text-right m-3">
                        <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
                          <DropdownToggle className="paginationbtnstyle" caret>{rowsPerPage}</DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setRowsPerPage(10);
                                setPage(1);
                              }}
                            >
                              10
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setRowsPerPage(25);
                                setPage(1);
                              }}
                            >
                              25
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setRowsPerPage(50);
                                setPage(1);
                              }}
                            >
                              50
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setRowsPerPage(100);
                                setPage(1);
                              }}
                            >
                              100
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <Button
                          className="p-0"
                          style={{}}
                          onClick={() => handleChangePage(page - 1)}
                          disabled={page === 1}
                        >
                          <img src={leftarrow} alt="leftarrow"></img>
                        </Button>
                        <span className="fontstylerentr">
                          Page {page} of {totalPages}
                        </span>{" "}
                        <Button
                          className="p-0"
                          style={{}}
                          onClick={() => handleChangePage(page + 1)}
                          disabled={page === totalPages}
                        >
                          <img src={rightarrow} alt="rightarrow"></img>
                        </Button>{" "}
                      </Col>
                    </Row>
                  </TableContainer>
                )}
              </Paper>
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}
