import {
  CardHeader,
  Button,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "components/Headers/Header";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import "jspdf-autotable";
import { useState } from "react";
import "./tenantstyle.css";
import Img from "../../assets/img/theme/site-logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axiosInstance from "axiosInstance";
import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import moment from "moment";
import { verifyToken } from "components/Functions/Functions";

const Rentalownerreport = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [accessType, setAccessType] = useState(null);
  let [transactionData, setTransactionData] = useState([]);
  let [reportData, setReportData] = useState([]);
  const [leasedropdownOpen, setLeaseDropdownOpen] = useState(false);
  const toggle2 = () => setLeaseDropdownOpen((prevState) => !prevState);
  let [loader, setLoader] = useState(true);
  const { admin } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [rentalsData, setRentalsData] = useState([]);
  const [getGrandTotal, setGrandTotal] = useState(0.0);

  let navigate = useNavigate();
  const location = useLocation();

  const [cookies, setCoockie, removeCookie] = useCookies();

  React.useEffect(() => {
    const handleCheckToken = async () => {
      const accessData = await verifyToken(
        navigate,
        location,
        removeCookie,
        admin
      );
      setAccessType(accessData);
    };

    handleCheckToken();
  }, []);

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  React.useEffect(() => {
    const handleDateFormat = () => {
      if (accessType?.themes?.format) {
        setDateFormat(accessType?.themes?.format);
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    };

    handleDateFormat();
  }, [accessType]);

  const [selectedStartDate, setSelectedStartDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
    setCurrentPage(1);
  };
  const [selectedEndDate, setSelectedEndDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const [isCustomDate, setIsCustomDate] = useState(false);

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    if (prevPage >= 1) {
      setCurrentPage(prevPage);
    }
  };

  const [selectedChargeType, setSelectedChargeType] =
    useState("Select charge type");

  const fetchRentalsData = async () => {
    if (accessType?.admin_id) {
      try {
        const response = await axiosInstance.get(
          `/rentals/rental-owners/${accessType?.admin_id}`
        );
        if (response.status === 200) {
          const slicedData = response.data;
          setRentalsData(slicedData);
        } else {
          console.error("Invalid API response structure: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching rentals data: ", error);
      }
    }
  };
  const [selectedRentalOwnerId, setSelectedRentalOwnerId] = useState(null);
  const [chargetypedropdownopen, setChargetypeDropdownOpen] = useState(false);
  const toggle5 = () => setChargetypeDropdownOpen((prevState) => !prevState);

  const getPaymentsData = async () => {
    if (accessType?.admin_id) {
      setLoader(true);
      try {
        const params = {
          selectedStartDate: selectedStartDate,
          selectedEndDate: selectedEndDate,
          page: currentPage,
          limit: itemsPerPage,
          rentalowner_id: selectedRentalOwnerId,
        };

        if (selectedRentalOwnerId) {
          params.rentalowner_id = selectedRentalOwnerId;
        }
        if (
          selectedChargeType !== "All" &&
          selectedChargeType !== "Select charge type"
        ) {
          params.selectedChargeType = selectedChargeType;
        }

        const response = await axiosInstance.get(
          `/rental_owner/todayspaymentrentalowner/${accessType?.admin_id}`,
          { params }
        );
        if (response.data?.statusCode === 200) {
          const data = response.data?.data;
          setGrandTotal(response?.data?.grandTotal);
          setTransactionData(data);
          setTotalPages(response.data?.totalPages);
        } else {
          setTransactionData([]);
          console.error(response.data?.message);
        }
      } catch (error) {
        console.error("Error fetching payments data:", error);
      } finally {
        setLoader(false);
      }
    }
  };

  React.useEffect(() => {
    fetchRentalsData();
  }, [accessType]);

  React.useEffect(() => {
    getPaymentsData();
  }, [accessType, currentPage, itemsPerPage, totalPages]);

  const handleChargeTypeSelect = (type) => {
    setSelectedChargeType(type);
  };

  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();

  const getReportData = async () => {
    if (accessType?.admin_id) {
      try {
        const params = {
          selectedStartDate: selectedStartDate,
          selectedEndDate: selectedEndDate,
          rentalowner_id: selectedRentalOwnerId,
        };

        if (
          selectedChargeType !== "All" &&
          selectedChargeType !== "Select charge type"
        ) {
          params.selectedChargeType = selectedChargeType;
        }

        const response = await axiosInstance.get(
          `/rental_owner/todayspaymentrentalownerpdf/${accessType?.admin_id}`,
          { params }
        );
        const data = response.data?.data;
        setReportData(data);
        setReportStartDate(response?.data?.startDate);
        setReportEndDate(response?.data?.endDate);
      } catch (error) {
        console.error("Error fetching payments data:", error);
      }
    }
  };
  React.useEffect(() => {
    getReportData();
  }, [
    accessType,
    transactionData,
    // selectedStartDate,
    // selectedEndDate,
    // selectedRentalOwnerId,
    // selectedChargeType,
  ]);

  const [userProfile, setUserProfile] = useState();

  const getUserProfile = async () => {
    if (accessType?.admin_id) {
      // setLoader(true);
      try {
        const res = await axiosInstance.get(
          `/admin/admin_profile/${accessType?.admin_id}`
        );
        setUserProfile(res.data?.data);
      } catch (error) {
        console.error("Error occurred while calling API:", error);
      }
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, [accessType]);

  //pdf generate
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the handleOptionClick function
  const handleOptionClick = (option) => {
    if (option === "PDF") {
      generatePDF();
    } else if (option === "XLSX") {
      generateExcel();
    } else if (option === "CSV") {
      generateCSV();
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("l");

    const img1Width = 15;
    const img1Height = 13;
    doc.addImage(Img, "JPEG", 10, 15, img1Width, img1Height);

    const titleYPosition = 20;
    doc.setFontSize(20);
    doc.text(
      "Rental Owner Report",
      doc.internal.pageSize.getWidth() / 2,
      titleYPosition,
      null,
      null,
      "center"
    );

    const subtitleYPosition = titleYPosition + 10;
    doc.setFontSize(12);
    doc.text(
      reportStartDate === reportEndDate
      ? `Date: ${String(reportStartDate)}`
      : `Date: ${String(reportStartDate)} to ${String(reportEndDate)}`,
      doc.internal.pageSize.getWidth() / 2,
      subtitleYPosition,
      null,
      null,
      "center"
    );

    const contactInfo = [
      userProfile?.company_name || "",
      userProfile?.company_address || "",
      [userProfile?.city, userProfile?.state, userProfile?.country]
        .filter(Boolean)
        .join(", "),
      userProfile?.postal_code || "",
    ]
      .filter(Boolean)
      .join("\n");

    doc.setFontSize(10);
    const contactInfoLines = contactInfo.split("\n");
    const topMargin = 20;
    const lineHeight = 4;

    contactInfoLines.forEach((line, index) => {
      doc.text(
        line,
        doc.internal.pageSize.getWidth() - doc.getTextWidth(line) - 10,
        topMargin + index * lineHeight
      );
    });

    const tableYPosition = subtitleYPosition + 20;

    const tableColumns = [
      { header: "Property", dataKey: "Property" },
      { header: "Tenant", dataKey: "tenant" },
      { header: "Date", dataKey: "date" },
      { header: "Pmt Type", dataKey: "type" },
      { header: "Txn ID", dataKey: "transaction_id" },
      { header: "Reference", dataKey: "reference" },
      { header: "Crd Type", dataKey: "cc_type" },
      { header: "Crd No", dataKey: "cc_number" },
      { header: "Total", dataKey: "total" },
    ];
    let grandTotal = 0;

    const tableRows = reportData?.flatMap((transaction) => {
      const rentalOwnerRow = [
        {
          content: transaction?.rentalOwner_name || "N/A",
          styles: { fontStyle: "bold", fontSize: 10 },
        },
        "",
        "",
        "",
        "",
        "",
        "",
      ];

      const paymentRows = transaction.payments?.flatMap((payment) => {
        const entryaccount = payment.entry
          ?.map((entry) => `${entry?.account}`)
          .join("\n");
        const entryamount = payment.entry
          ?.map(
            (entry) =>
              `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry?.amount)}`
          )
          .join("\n");

        const paymentRow = [
          {
            content:
              (payment.rental_data?.rental_adress || "") +
              (payment.unit_data?.rental_unit
                ? " - " + payment.unit_data?.rental_unit
                : ""),
            styles: {
              halign: "left",
              cellPadding: { top: 2, bottom: 0, left: 5, right: 0 },
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content: payment.tenant_data?.tenant_firstName
              ? `${payment.tenant_data?.tenant_firstName} ${payment.tenant_data?.tenant_lastName}`
              : "N/A",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content: payment?.entry[0]?.date
              ? moment(payment?.entry[0]?.date).format(
                `${dateFormat}`
              )
              : "-",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            }
          },
          {
            content: payment?.payment_type || "N/A",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content: payment?.transaction_id || "N/A",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content: payment?.payment_id || "N/A",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content:
              payment?.cc_type && Object.keys(payment.cc_type).length > 0
                ? payment.cc_type
                : "N/A",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content:
              payment?.cc_number && Object.keys(payment.cc_number).length > 0
                ? payment.cc_number
                : "N/A",
            styles: {
              fontSize: 9,
              fontStyle: "bold",
            },
          },
          {
            content: `$${
              new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(payment?.total_amount) || 0.0
            }`,
            styles: {
              halign: "right",
              fontSize: 9,
              fontStyle: "bold",
            },
          },
        ];

        grandTotal += Number(payment?.total_amount?.toFixed(2)) || 0.0;

        // Entry detail row
        const entryRow = [
          {
            content: entryaccount || "N/A",
            styles: {
              halign: "left",
              cellPadding: { top: 1, bottom: 0, left: 5, right: 0 },
              fontSize: 8,
            },
          },
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          {
            content: entryamount || "N/A",
            styles: {
              halign: "right",
              fontSize: 8,
            },
          },
        ];

        return [paymentRow, entryRow];
      });

      const total = [
        {
          content: `Subtotal - ${transaction?.rentalOwner_name || "N/A"}`,
          styles: {
            halign: "left",
            cellPadding: { top: 1, bottom: 3, left: 5, right: 0 },
            fontSize: 10,
            fontStyle: "bold",
          },
        },

        "",
        "",
        "",
        "",
        "",
        "",
        "",
        {
          content: `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(transaction?.sub_total || 0.0)}`,
          styles: {
            halign: "right",
            fontSize: 10,

            fontStyle: "bold",
          },
        },
      ];
      return [rentalOwnerRow, ...paymentRows, total];
    });
    // setGrandTotal(grandTotal?.toFixed(2))
    const grandTotalRow = [
      {
        content: "Grand Total",
        styles: {
          fontStyle: "bold",
          fontSize: 10,
        },
      },
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      {
        content: `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(grandTotal)}`,
        styles: {
          halign: "right",
          fontStyle: "bold",
          fontSize: 10,
        },
      },
    ];

    tableRows?.push(grandTotalRow);

    doc.autoTable({
      startY: tableYPosition,
      head: [tableColumns?.map((col) => col.header)],
      body: tableRows,
      theme: "plain",
      headStyles: {
        fillColor: [90, 134, 213],
        textColor: [255, 255, 255],
        fontSize: 10,
        fontStyle: "bold",
      },
      styles: {
        fontSize: 10,
        cellPadding: 2,
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 35 },
        2: { cellWidth: 35 },
        3: { cellWidth: 25 },
        4: { cellWidth: 30 },
        5: { cellWidth: 35 },
        6: { cellWidth: 20 },
        7: { cellWidth: 25 },
        8: { cellWidth: 25 },
      },
      didDrawCell: (data) => {
        if (data?.row?.index === tableRows?.length - 1) {
          const x = data?.cell?.x;
          const y = data?.cell?.y;
          const width = data?.cell?.width;
          const height = data?.cell?.height;

          doc.setDrawColor(0, 0, 0);
          doc.setLineWidth(1);
          doc.line(x, y, x + width, y);
        }
      },
    });

    doc.save("Rental_owner_transaction_report.pdf");
  };

  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];
    let grandTotal = 0;

    const headers = [
      "Property",
      "Tenant",
      "Date",
      "Payment Type",
      "Transaction Id",
      "Reference",
      "Card Type",
      "Card No",
      "Total",
    ];

    wsData?.push(headers);

    const headerStyle = {
      font: { bold: true, color: { rgb: "FFFFFF" }, sz: "14" },

      border: {
        top: { style: "thin", color: "000000" },
        bottom: { style: "thin", color: "000000" },
        left: { style: "thin", color: "000000" },
        right: { style: "thin", color: "000000" },
      },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "5A86D7" } },
    };

    reportData?.forEach((transaction) => {
      wsData?.push([
        {
          v: transaction?.rentalOwner_name || "N/A",
          s: { font: { bold: true, sz: 12 } },
        },
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      // Rows for each payment
      transaction.payments?.forEach((payment) => {
        // Payment row
        wsData?.push([
          (payment.rental_data?.rental_adress || "") +
            (payment.unit_data?.rental_unit
              ? " - " + payment.unit_data?.rental_unit
              : ""),
          payment.tenant_data?.tenant_firstName
            ? `${payment.tenant_data?.tenant_firstName} ${payment.tenant_data?.tenant_lastName}`
            : "N/A",
          payment?.createdAt
            ? moment(payment?.createdAt).format(`${dateFormat} HH:mm:ss`)
            : "-",
          payment?.payment_type || "N/A",
          payment?.transaction_id || "N/A",
          payment?.payment_id || "N/A",
          payment?.cc_type || "N/A",
          payment?.cc_number || "N/A",
          {
            v: `$${new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(payment?.total_amount || 0.0)}`,
            s: {
              alignment: { horizontal: "right" },
            },
          },
        ]);

        grandTotal += Number(payment?.total_amount) || 0;

        payment.entry?.forEach((entry) => {
          wsData?.push([
            entry.account || "N/A",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            {
              v: `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry?.amount || 0.0)}`,
              s: {
                alignment: { horizontal: "right" },
              },
            },
          ]);
        });
      });
      wsData?.push([
        {
          v: `Subtotal - ${transaction?.rentalOwner_name || "N/A"}`,
          s: { font: { bold: true } },
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        {
          v: `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(transaction?.sub_total || 0.0)}`,
          s: {
            alignment: { horizontal: "right" },
            font: { bold: true },
          },
        },
      ]);
    });

    wsData?.push([
      {
        v: "Grand Total",
        s: { font: { bold: true } },
      },
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      {
        v: `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(grandTotal)}`,
        s: {
          alignment: { horizontal: "right" },
          font: { bold: true },
        },
      },
    ]);

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    for (let i = 0; i < headers?.length; i++) {
      ws[XLSX.utils.encode_cell({ r: 0, c: i })].s = headerStyle;
    }

    ws["!cols"] = headers.map(() => ({ width: 20 }));

    for (let rowIndex = 0; rowIndex < wsData?.length; rowIndex++) {
      const row = wsData[rowIndex];
      row?.forEach((cell, colIndex) => {
        if (cell?.s) {
          ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })].s = cell?.s;
        }
        if (rowIndex > 0) {
          ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })].s = {
            ...ws[XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })]?.s,
          };
        }
      });
    }

    XLSX.utils.book_append_sheet(wb, ws, "Rental Owner Report");

    XLSX.writeFile(wb, "Rental_owner_transaction_report.xlsx");
  };

  const generateCSV = () => {
    let csvData = [];
    let grandTotal = 0;

    const headers = [
      "Property",
      "Tenant",
      "Date",
      "Payment Type",
      "Transaction Id",
      "Reference",
      "Card Type",
      "Card No",
      "Total",
    ];

    csvData?.push(headers.join(","));

    reportData?.forEach((transaction) => {
      csvData?.push(
        [
          transaction.rentalOwner_name || "N/A",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ].join(",")
      );

      transaction.payments?.forEach((payment) => {
        const propertyAddress = (
          (payment.rental_data?.rental_adress.split(",").join(" ") || "") +
          (payment.unit_data?.rental_unit
            ? " - " + payment.unit_data?.rental_unit
            : "")
        ).padEnd(50, " ");

        csvData?.push(
          [
            propertyAddress,
            payment.tenant_data?.tenant_firstName
              ? `${payment.tenant_data?.tenant_firstName} ${payment.tenant_data?.tenant_lastName}`
              : "N/A",
            payment?.createdAt
              ? moment(payment?.createdAt).format(`${dateFormat} HH:mm:ss`)
              : "-",
            payment?.payment_type || "N/A",
            payment?.transaction_id || "N/A",
            payment?.payment_id || "N/A",
            payment?.cc_type || "N/A",
            payment?.cc_number || "N/A",
            `$${new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(payment?.total_amount || 0.0)}`,
          ].join(",")
        );

        grandTotal += Number(payment?.total_amount) || 0;

        payment.entry?.forEach((entry) => {
          csvData?.push(
            [
              entry.account || "N/A",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              `$${new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(entry.amount || "0.00")}`,
            ].join(",")
          );
        });
      });
      csvData?.push(
        [
          `Subtotal - ${transaction?.rentalOwner_name || "N/A"}`,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          `$${new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(transaction?.sub_total || 0.0)}`,
        ].join(",")
      );
    });

    csvData?.push(
      [
        "Grand Total",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        `$${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(grandTotal)}`,
      ].join(",")
    );

    const csvString = csvData?.join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `Rental_owner_transaction_report.csv`);
  };

  const [rentalownerdropdownopen, setRentalownerDropdownOpen] = useState(false);
  const toggle3 = () => setRentalownerDropdownOpen((prevState) => !prevState);
  const [setrentalowner, setRentalowner] = useState("Select Rental Owner");

  const handleRentalOwnerSelect = (owner) => {
    setRentalowner(owner?.rentalOwner_name);
    setSelectedRentalOwnerId(owner?.rentalowner_id || "");
  };

  const [rentalownerdatedropdownopen, setRentalownerdateDropdownOpen] =
    useState(false);

  const toggle4 = () =>
    setRentalownerdateDropdownOpen((prevState) => !prevState);

  const [setrentalownerdate, setRentalownerdate] = useState("Today");

  const handlerentalownerdate = (type) => {
    setRentalownerdate(type);
    setIsCustomDate(type === "Custom Date");
    const today = new Date();
    let start, end;

    switch (type) {
      case "Today":
        start = today.toISOString().split("T")[0];
        end = today.toISOString().split("T")[0];
        break;
      case "This Week":
        start = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 1))
        ).format("YYYY-MM-DD"); // Monday of the current week
        end = moment(
          new Date(today.setDate(today.getDate() - today.getDay() + 7))
        ).format("YYYY-MM-DD"); // Sunday of the current week
        break;
      case "This Month":
        start = moment(
          new Date(today.getFullYear(), today.getMonth(), 1)
        ).format("YYYY-MM-DD"); // First day of the month
        end = moment(
          new Date(today.getFullYear(), today.getMonth() + 1, 0)
        ).format("YYYY-MM-DD"); // Last day of the month
        break;
      case "This Year":
        start = moment(new Date(today.getFullYear(), 0, 1)).format(
          "YYYY-MM-DD"
        ); // January 1st of the current year
        end = moment(new Date(today.getFullYear(), 11, 31)).format(
          "YYYY-MM-DD"
        ); // December 31st of the current year
        break;
      case "Custom Date":
        start = "";
        end = "";
        break;
      default:
        break;
    }

    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };
  return (
    <>
      <Header />
      <Container className="stylecontainer" fluid style={{ marginTop: "1rem" }}>
        <Row>
          <Col xs="12" lg="12" sm="12">
            <CardHeader
              className=" mt-3 headproptypebar background-colorsty"
              style={{
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px 0px #00000040 ",
              }}
            >
              <h2
                className="headproptypee bgtextwhite fontfamilysty"
                style={{
                  fontWeight: "500",
                  fontSize: "26px",
                }}
              >
                Rental Owner Transaction
              </h2>
            </CardHeader>
          </Col>
        </Row>
        <br />
        <Row className="mb-2 align-items-center">
          <Col lg="4" md="12" sm="12" xl="3">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Rental Owner
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle rentalownerdrops"
                isOpen={rentalownerdropdownopen}
                toggle={toggle3}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {setrentalowner}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() =>
                      handleRentalOwnerSelect({
                        rentalOwner_name: "All",
                        rentalowner_id: null,
                      })
                    }
                  >
                    All
                  </DropdownItem>

                  {rentalsData?.map((owner) => (
                    <DropdownItem
                      key={owner?.rentalowner_id}
                      className="dropdownfontsyle"
                      onClick={() => handleRentalOwnerSelect(owner)}
                    >
                      {owner?.rentalOwner_name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col lg="5" md="5" sm="12" xl="3">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Charge Type
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={chargetypedropdownopen}
                toggle={toggle5}
                style={{ width: "200px" }}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                  }}
                >
                  {selectedChargeType}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Card")}
                  >
                    Card
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("ACH")}
                  >
                    ACH
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Check")}
                  >
                    Check
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Cash")}
                  >
                    Cash
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Manual")}
                  >
                    Manual
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Money Order")}
                  >
                    Money Order
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("Cashier's Check")}
                  >
                    Cashier 's Check
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handleChargeTypeSelect("All")}
                  >
                    All
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xl="3" lg="3" md="3" sm="6" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="input-property"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                Date Range
              </label>
              <br />
              <Dropdown
                className="dropdownfontsyle"
                isOpen={rentalownerdatedropdownopen}
                toggle={toggle4}
                style={{ width: "100%" }}
              >
                <DropdownToggle
                  caret
                  className="leasetypee dropdownfontsyle titleecolor"
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    border: "1px solid #ced4da",
                    backgroundColor: "transparent",
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center", 
                    whiteSpace: "nowrap", 
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {setrentalownerdate}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu
                  className="dropdownfontsyle"
                  style={{ width: "100%" }}
                >
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("Today")}
                  >
                    Today
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Week")}
                  >
                    This Week
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Month")}
                  >
                    This Month
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("This Year")}
                  >
                    This Year
                  </DropdownItem>
                  <DropdownItem
                    className="dropdownfontsyle"
                    onClick={() => handlerentalownerdate("Custom Date")}
                  >
                    Custom Date
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </FormGroup>
          </Col>
          <Col xl="3" lg="3" md="3" sm="6" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-startdate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                From
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-startdate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                disabled={!isCustomDate}
                max={moment(selectedEndDate).format("YYYY-MM-DD") || null}
              />
            </FormGroup>
          </Col>
          <Col xl="3" lg="3" md="3" sm="6" xs="6">
            <FormGroup>
              <label
                className="form-control-label fontstylerentr titleecolor fontfamilysty"
                htmlFor="select-enddate"
                style={{
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                To
              </label>
              <Input
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "6px",
                  width: "100%",
                }}
                className="form-control-alternative fontstylerentr titleecolor"
                id="select-enddate"
                placeholder="3000"
                type="date"
                name="date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                disabled={!isCustomDate}
                min={moment(selectedStartDate).format("YYYY-MM-DD") || null}
              />
            </FormGroup>
          </Col>

          <Col className="d-flex justify-content-end addtotnstyle mt-4">
            <Button
              className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
              onClick={getPaymentsData}
              style={{
                height: "46px",
                fontSize: "16px",
                border: "1px solid #152B5180",
                boxShadow: "0px 4px 4px 0px #00000040",
                fontFamily: "Poppins",
                fontWeight: "500",
              }}
            >
              Run
            </Button>
            {transactionData && transactionData?.length > 0 && (
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  className="fontstylerentr background-colorsty bgtextwhite fontfamilysty"
                  caret
                  style={{
                    marginBottom: "12px",
                    fontSize: "16px",
                    border: "1px solid #152B5180",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  Exports
                </DropdownToggle>
                <DropdownMenu style={{ minWidth: "120px" }}>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("PDF")}
                  >
                    PDF
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("XLSX")}
                  >
                    XLSX
                  </DropdownItem>
                  <DropdownItem
                    className="textcolorblue fontfamilysty"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleOptionClick("CSV")}
                  >
                    CSV
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </Col>
        </Row>
        <Table
          className="align-items-center table-flush"
          responsive
          style={{ borderCollapse: "collapse" }}
        >
          {loader ? (
            <div className="d-flex flex-direction-row justify-content-center align-items-center p-5 m-5">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={loader}
              />
            </div>
          ) : (
            <>
              <thead
                className="fontfamilysty"
                style={{
                  height: "45px",
                  fontSize: "14px",
                  fontWeight: "600",
                  border: 0,
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <tr className="" style={{ border: 0 }}>
                  <td
                    className="tabletextcolor"
                    colSpan={6}
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      padding: "0px",
                    }}
                  >
                    Grand total
                  </td>
                  <td
                    className="tabletextcolor text-right"
                    style={{
                      border: 0,
                      fontWeight: "Bold",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                    }}
                  >
                    {`$${new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(getGrandTotal || "0.00")}`}
                  </td>
                </tr>
                <tr
                  style={{
                    borderBottom: "2px solid rgba(50, 69, 103, 1)",
                  }}
                >
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      border: 0,
                    }}
                  >
                    Property & Tenant
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      border: 0,
                    }}
                  >
                    Txn Id
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      border: 0,
                    }}
                  >
                    Txn Date
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{
                      borderTopLeftRadius: "15px",
                      border: 0,
                    }}
                  >
                    Txn Mode
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Pay Detail
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Detail line
                  </th>
                  <th
                    className="tablefontstyle textcolorblue"
                    scope="col"
                    style={{ border: 0 }}
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody>
                {transactionData && transactionData?.length > 0 ? (
                  transactionData?.map((tenant) => (
                    <>
                      <tr
                        key={tenant?.transaction_id}
                        style={{
                          cursor: "pointer",
                          fontSize: "12px",
                          height: "40px",
                          fontFamily: "poppins",
                          fontWeight: "600",
                        }}
                      >
                        <td
                          colSpan="9"
                          className=" tablebodyfont rentrollcolorsty"
                          style={{
                            fontWeight: "600",
                            border: "0",
                          }}
                        >
                          Rental Owner : {tenant?.rentalOwner_name}
                        </td>
                      </tr>
                      {tenant.payments && tenant.payments?.length > 0 ? (
                        tenant.payments.map((payment) => (
                          <tr
                            className="fontfamilysty"
                            key={`payment-${payment?.transaction_id}`}
                            style={{
                              fontSize: "12px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontWeight: "600",
                              lineHeight: "1.2",
                              border: 0,
                            }}
                          >
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{
                                width: "210px",
                                border: 0,
                              }}
                            >
                              <span>
                                {payment.tenant_data?.tenant_firstName}{" "}
                                {payment.tenant_data?.tenant_lastName}
                              </span>
                              <br />
                              <span>
                                {(payment.rental_data?.rental_adress || "") +
                                  (payment.unit_data?.rental_unit
                                    ? ` - ${payment.unit_data?.rental_unit}`
                                    : " ")}
                              </span>
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0 }}
                            >
                              {payment?.transaction_id || "N/A"}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0 }}
                            >
                              {payment?.entry[0]?.date
                                ? moment(payment?.entry[0]?.date).format(
                                    `${dateFormat}`
                                  )
                                : "N/A"}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0 }}
                            >
                              {payment?.payment_type}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0" }}
                            >
                              {!payment.cc_type ||
                              Object.keys(payment.cc_type)?.length !== 0 ? (
                                <>
                                  {payment?.cc_number || "N/A"} <br />
                                  {payment?.cc_type}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: 0, width: "20%" }}
                            >
                              {payment.entry?.length > 0 ? (
                                payment.entry.map((item) => (
                                  <div
                                    key={item.entry_id}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      padding: "4px 0",
                                    }}
                                  >
                                    <span className="tablebodyfont rentrollcolorsty">
                                      {item.account}:
                                    </span>
                                    <span style={{ textAlign: "right" }}>
                                      $
                                      {new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(item.amount)}
                                    </span>
                                  </div>
                                ))
                              ) : (
                                <div>No entries</div>
                              )}
                            </td>
                            <td
                              className=" tablebodyfont tabletextcolor"
                              style={{ border: "0" }}
                            >
                              <span
                                className="d-flex justify-content-center tablebodyfont tabletextcolor"
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                {`$${new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(payment?.total_amount || "$0.00")}`}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="9"
                            className="text-center"
                            style={{ fontSize: "15px" }}
                          >
                            No Payment Details Found
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="9" style={{ fontSize: "15px" }}>
                      No Transaction Found
                    </td>
                  </tr>
                )}
              </tbody>
            </>
          )}
        </Table>
        <Row>
          <Col className="text-right mt-3 mb-3">
            <Dropdown isOpen={leasedropdownOpen} toggle={toggle2}>
              <DropdownToggle className="paginationbtnstyle" caret>
                {itemsPerPage}
              </DropdownToggle>
              <DropdownMenu>
                {[10, 25, 50, 100].map((perPage) => (
                  <DropdownItem
                    key={perPage}
                    onClick={() => setItemsPerPage(perPage)}
                  >
                    {perPage}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-left"
                viewBox="0 0 16 16"
              >
                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
              </svg>
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>{" "}
            <Button
              className="p-0"
              style={{ backgroundColor: "#d0d0d0" }}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-caret-right"
                viewBox="0 0 16 16"
              >
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            </Button>
          </Col>
        </Row>

        <ToastContainer />
      </Container>
    </>
  );
};

export default Rentalownerreport;
